@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
:root {
    --color-gray-100: #f4f5f8;
--color-gray-200: #e3e7ed;
--color-gray-300: #cdd4e0;
--color-gray-400: #b4bdce;
--color-gray-500: #97a3b9;
--color-gray-600: #677793;
--color-gray-700: #596882;
--color-gray-800: #3b4863;
--color-gray-900: #1c273c;
--spaced-section: 5rem;
--font-body-family: Rubik, sans-serif;
--font-body-style: normal;
--font-body-weight: 400;
--font-body-size: 16px;
--font-body-line-height: calc(145 / 100);
--font-heading-family: Oswald, sans-serif;
--font-heading-style: normal;
--font-heading-weight: 500;
--font-heading-letter-spacing: 0em;
--font-heading-line-height: 1.2;
--font-heading-text-transform: uppercase;
--font-subtitle-family: Poppins, sans-serif;
--font-subtitle-style: normal;
--font-subtitle-weight: 600;
--font-subtitle-text-transform: uppercase;
--font-subtitle-size: 12px;
--font-heading-h1-size: 80px;
--font-heading-h2-size: 56px;
--font-heading-h3-size: 32px;
--font-heading-h4-size: 20px;
--font-heading-h5-size: 20px;
--font-heading-h6-size: 16px;
--font-heading-card-family: Oswald, sans-serif;
--font-heading-card-style: normal;
--font-heading-card-weight: 500;
--font-text-card-family: Rubik, sans-serif;
--font-text-card-style: normal;
--font-text-card-weight: 400;
--font-heading-card-size: 20px;
--font-heading-card-size-big: 26px;
--font-text-card-size: 16px;
--font-button-family: Oswald, sans-serif;
--font-button-style: normal;
--font-button-weight: 500;
--font-button-text-transform: uppercase;
--font-button-family: Oswald, sans-serif;
--font-button-style: normal;
--font-button-weight: 500;
--font-button-text-transform: uppercase;
--font-button-letter-spacing: -0.01em;
--font-subtitle-letter-spacing: 0.06em;
--font-header-menu-family: Oswald, sans-serif;
--font-header-menu-style: normal;
--font-header-menu-weight: 500;
--font-header-menu-text-transform: uppercase;
--font-header-menu-link-size: 14px;
--font-aside-menu-link-size: 18px;
--font-footer-menu-family: Rubik, sans-serif;
--font-footer-menu-style: normal;
--font-footer-menu-weight: 400;
--font-footer-link-size: 14px;
--font-footer-bottom-link-size: 14px;
--color-foreground: 69, 69, 68;
--color-foreground-secondary: 143, 143, 142;
--color-foreground-title: 35, 35, 34;
--color-foreground-sub-title: 115, 115, 114;
--color-base-background-1: 255, 255, 255;
--color-base-background-2: 247, 247, 247;
--color-base-background-3: 245, 245, 241;
--color-base-background-4: 35, 35, 34;
--color-base-background-5: 40, 40, 40;
--color-announcement-bar-background-1: 12, 12, 11;
--color-announcement-bar-background-2: 238, 238, 238;
--color-button-text: 255, 255, 255;
--color-button: 46, 46, 46;
--color-button-hover-text: 255, 255, 255;
--color-button-hover: 0, 0, 0;
--color-button-secondary-text: 35, 35, 34;
--color-button-secondary-hover-text: 115, 115, 114;
--border-radius-button: 0px;
--payment-terms-background-color: #FFFFFF;
--color-overlay: 255, 255, 255;
--color-accent: 35, 35, 34;
--color-background-input: 248, 248, 248;
--color-border-input: 235, 235, 235;
--color-border-input-hover: 213, 213, 213;
--color-badge-bg: #232322;
--color-badge-text: #FFFFFF;
--color-border: 213, 213, 213;
--color-border-light: 213, 213, 213;
--color-background-card: 255, 255, 255;
--font-popups-heading-family: Oswald, sans-serif;
--font-popups-heading-style: normal;
--font-popups-heading-weight: 500;
--font-popups-text-family: Rubik, sans-serif;
--font-popups-text-style: normal;
--font-popups-text-weight: 400;
--font-popup-heading-size: 32px;
--font-popup-text-size: 16px;
--font-notification-heading-size: 20px;
--font-notification-text-size: 13px;
}
/*============================================================================*/
:root {
--color-error: 234, 74, 74;
--color-inventory: 92, 92, 91;
--duration-short: 100ms;
--duration-default: 250ms;
--duration-long: 500ms;
--announcement-height: 0px;
--overlay-gradient: linear-gradient(
180deg,
rgba(255, 255, 255, 0) 0%,
rgba(255, 255, 255, 0.79) 100%
);
--color-background: var(--color-base-background-1);
--color-link: var(--color-foreground);
--alpha-link: 0.5;
--alpha-button-background: 1;
--alpha-button-border: 1;
--color-social: 0, 0, 0;
--color-placeholder-input: 0, 0, 0;
--alpha-placeholder-input: 0.5;
--color-orders-hover: 248, 248, 248;
--color-subtitle-alpha: 1;
--swiper-pagination-bullet-inactive-opacity: 0.4 ;
}
.color-background-1 {
--color-background: var(--color-base-background-1);
}
.color-background-2 {
--color-background: var(--color-base-background-2);
}
.color-background-3 {
--color-background: var(--color-base-background-3);
}
.color-background-4 {
--color-background: var(--color-base-background-4);
}
.color-background-5 {
--color-background: var(--color-base-background-5);
}
.color-background-4,
.color-background-5,
.color-inverse {
--color-button: 46, 46, 46;
--color-button-text: 255, 255, 255;
--color-button-hover: 0, 0, 0;
--color-button-hover-text: 255, 255, 255;
--color-button-secondary-text: 213, 213, 213;
--color-button-secondary-hover-text: 199, 199, 199;
--color-overlay: 35, 35, 34;
--color-inventory: 171, 171, 170;
--color-subtitle-alpha: 0.6;
}
.color-background-4,
.color-background-5 {
--color-foreground: 235, 235, 235;
--color-link: 255, 255, 255;
--color-foreground-title: 255, 255, 255;
--color-foreground-secondary: 248, 248, 248;
--color-foreground-sub-title: 248, 248, 248;
--color-card-hover: var(--color-base-background-1);
--color-social: 255, 255, 255;
--color-border-input: 46, 46, 46;
--color-border: 92, 92, 91;
--color-orders-hover: 48, 48, 48;
--overlay-gradient: linear-gradient(
180deg,
rgba(0, 0, 0, 0) 0%,
rgba(0, 0, 0, 0.79) 100%
);
}
.color-border-1 {
--color-border: var(--color-border-light);
}
.color-inverse .color-border-1, .color-background-4 .color-border-1, .color-background-5 .color-border-1 {
--color-border-light: 69,69,68;
}
.color-inverse {
--color-foreground: 248, 248, 248;
--color-link: 255, 255, 255;
--color-foreground-title: 255, 255, 255;
--color-foreground-sub-title: 248, 248, 248;
--color-foreground-secondary: 248, 248, 248;
--color-background: 5, 5, 5;
--color-card-hover: var(--color-base-background-1);
--alpha-button-border: 0.3;
--color-social: 255, 255, 255;
--color-border-input: 46, 46, 46;
--color-border: 69,69,68;
--color-border-input-hover: 60, 60, 60;
--alpha-link: 0.5;
--color-button-secondary-text: 31, 31, 31;
--alpha-button-background: 1;
--color-background-card: 31, 31, 31;
--color-accent: 255, 255, 255;
--color-base-border-input: 255, 255, 255;
--color-placeholder-input: 255, 255, 255;
--alpha-placeholder-input: 0.5;
--color-orders-hover: 48, 48, 48;
--overlay-gradient: linear-gradient(
180deg,
rgba(0, 0, 0, 0) 0%,
rgba(0, 0, 0, 0.79) 100%
);
}
/* Dark Mode */
[data-scheme=dark]{
--color-background: 23, 23, 23;
--color-background-card: var(--color-background);
--color-base-background-2: 23, 23, 23;
--color-base-background-4: 33, 33, 33;
--color-foreground: 255, 255, 255;
--color-foreground-secondary: 248, 248, 248;
--color-foreground-title: 255, 255, 255;
--color-foreground-sub-title: 248, 248, 248;
--color-background-input: 35, 35, 34;
--color-border-input: 46, 46, 46;
--color-border-input-hover: 60, 60, 60;
--color-border: 69,69,68;
--color-announcement-bar: 0, 0, 0;
--color-announcement-bar-background-1: 0, 0, 0;
--color-announcement-bar-background-2: 0, 0, 0;
--color-link: 255, 255, 255;
--alpha-link: 0.5;
--color-button: 46, 46, 46;
--color-button-text: 255, 255, 255;
--color-button-hover: 0, 0, 0;
--color-button-hover-text: 255, 255, 255;
--color-button-secondary-text: 213, 213, 213;
--color-button-secondary-hover-text: 199, 199, 199;
--alpha-button-background: 1;
--alpha-button-border: 1;
--color-social: 255, 255, 255;
--color-accent: 191, 191, 191;
--color-base-border-input: 255, 255, 255;
--color-placeholder-input: 255, 255, 255;
--color-orders-hover: 48, 48, 48;
--color-overlay: 35, 35, 34;
--color-inventory: 171, 171, 170;
--color-subtitle-alpha: 0.6;
}
[data-scheme=dark] .color-background-1,
[data-scheme=dark] .color-background-2,
[data-scheme=dark] .color-background-3,
[data-scheme=dark] .color-background-4 {
--color-background: 23, 23, 23;
--color-foreground: 255, 255, 255;
--color-foreground-title: 255, 255, 255;
--color-social: 255, 255, 255;
--color-link: 255, 255, 255;
--color-button: 46, 46, 46;
--color-button-text: 255, 255, 255;
--color-button-hover: 0, 0, 0;
--color-button-hover-text: 255, 255, 255;
--color-button-secondary-text: 213, 213, 213;
--color-button-secondary-hover-text: 199, 199, 199;
--color-border-input: 46, 46, 46;
--color-border-input-hover: 60, 60, 60;
--color-background-input: 35, 35, 34;
}
[data-scheme=dark] .color-background-2,
[data-scheme=dark] .color-background-5,
[data-scheme=dark] .color-inverse {
--color-background: 23, 23, 23;
--color-base-background-2: 23, 23, 23;
--color-button-secondary: 255, 255, 255;
--color-background-input: 35, 35, 34;
--color-link: 255, 255, 255;
}
[data-scheme=dark] .color-border-1 {
--color-border: 67, 67, 67;
}
[data-scheme=dark] .color-background-4 {
--color-background: 35, 35, 34;
}
[data-scheme=dark] .color-background-5 {
--color-background: 40, 40, 40;
}
[data-scheme=dark] .image-parallax.color-background-3 {
--color-button: 0, 0, 0;
--color-button-text: 255, 255, 255;
}
[data-scheme=dark] .swiper-buttons.swiper-buttons--secondary .icon {
color: rgb(var(--color-background));
}
[data-scheme=dark] .image-parallax__button.button--secondary .icon-button-arrow {
color: rgb(var(--color-background));
}
[data-scheme=dark] .steps-section + .testimonials-section > .color-background-2,
[data-scheme=dark] .about-section.color-background-2 {
--color-background: 28, 28, 28;
}
[data-scheme=dark] .featured-products__products-item .card__inner,
[data-scheme=dark] .card__link::after,
[data-scheme=dark] .card-horizontal__link,
[data-scheme=dark] .card-horizontal__link::after,
[data-scheme=dark] .collection-product-list .collection-product-card--extended .card__link::after {
background-color: rgb(var(--color-base-background-1));
}
[data-scheme=dark] .collection-popular-card .card-information__wrapper > *,
[data-scheme=dark] .collection-product-list .card-information__wrapper > *,
[data-scheme=dark] .collection-results--products .card-information__wrapper > *,
[data-scheme=dark] .product-recommendations__product .card-information__wrapper > *,
[data-scheme=dark] .search-modal .card-horizontal__information > *,
[data-scheme=dark] .search-modal .card-horizontal-wrapper .price--on-sale .price-item--regular,
[data-scheme=dark] .search-modal .card-horizontal-wrapper .price__regular {
color: #fff;
}
[data-scheme=dark] .search-modal .card-horizontal-wrapper .price--on-sale .price-item--regular {
text-decoration-color: #fff;
}
[data-scheme=dark] .collection-popular-card .card-wrapper,
[data-scheme=dark] .collection-product-list .card-wrapper:not(.card-wrapper-page),
[data-scheme=dark] .collection-results--products .card-wrapper,
[data-scheme=dark] .product-recommendations__product .card-wrapper,
[data-scheme=dark] .search-modal .search__items-item,
[data-scheme=dark] .search-modal .card-horizontal-wrapper {
background-color: rgb(var(--color-background));
}
[data-scheme=dark] .product-slider .swiper-buttons:before,
[data-scheme=dark] .featured-products .swiper-buttons:before {
background-color: rgb(var(--color-base-button-background), 0.7);
}
[data-scheme=dark] .product-slider .swiper-button-next,
[data-scheme=dark] .product-slider .swiper-button-prev,
[data-scheme=dark] .product-slider .swiper-pagination,
[data-scheme=dark] .featured-products .swiper-button-next,
[data-scheme=dark] .featured-products .swiper-button-prev,
[data-scheme=dark] .featured-products .swiper-pagination {
color: rgb(var(--color-base-solid-button-labels));
}
[data-scheme=dark] .featured-products__products-list--horizontal.featured-products__products-list--placeholder .card-wrapper,
[data-scheme=dark] .featured-products__products-list .featured-products__products-item--horizontal .card-horizontal-wrapper {
border-color: rgb(223, 223, 223);
}
[data-scheme=dark] .featured-products__button .button--primary {
background-color: rgb(var(--color-base-button-background));
color: rgb(var(--color-base-solid-button-labels));
}
[data-scheme=dark] .featured-products__button .button--secondary {
background-color: rgb(var(--color-base-outline-button-labels));
color: rgb(var(--color-secondary-button-labels));
}
[data-scheme=dark] .filters__item:not(.filters__item_active) {
opacity: 0.5;
}
[data-scheme=dark] .filters__item:not(.filters__item_active) span {
color: #000;
}
[data-scheme=dark] .swiper-buttons.swiper-buttons--secondary .swiper-button-next,
[data-scheme=dark] .swiper-buttons.swiper-buttons--secondary .swiper-button-prev,
[data-scheme=dark] .swiper-buttons.swiper-buttons--secondary .swiper-pagination {
color: rgb(var(--color-button-text));
}
[data-scheme=dark] .cart__blocks-content {
background-color: transparent;
}
[data-scheme=dark] .default-address {
background-color: var(--color-badge-text);
color: var(--color-badge-bg);
}
[data-scheme=dark] .select__sort_by option {
background-color: rgb(var(--color-background));
}
[data-scheme=dark] .hide__checkbox .facets-checkbox__label {
background-color: rgba(var(--color-background-input), 0.8);
}
[data-scheme=dark] .hide__checkbox .facets-checkbox__hide:not(:disabled) ~ .facets-checkbox__label:hover,
[data-scheme=dark] .hide__checkbox .facets-checkbox__hide:checked ~ .facets-checkbox__label {
background: rgba(var(--color-foreground-title), 1);
}
@media (prefers-color-scheme: dark) {
[data-scheme=auto] {
--color-background: 23, 23, 23;
--color-background-card: var(--color-background);
--color-base-background-2: 23, 23, 23;
--color-base-background-4: 33, 33, 33;
--color-foreground: 255, 255, 255;
--color-foreground-title: 255, 255, 255;
--color-foreground-sub-title: 248, 248, 248;
--color-background-input: 35, 35, 34;
--color-border-input: 46, 46, 46;
--color-border-input-hover: 60, 60, 60;
--color-border: 67, 67, 67;
--color-announcement-bar: 0, 0, 0;
--color-announcement-bar-background-1: 0, 0, 0;
--color-announcement-bar-background-2: 0, 0, 0;
--color-link: 255, 255, 255;
--alpha-link: 0.5;
--color-button: 46, 46, 46;
--color-button-text: 255, 255, 255;
--color-button-hover: 0, 0, 0;
--color-button-secondary-text: 213, 213, 213;
--color-button-secondary-hover-text: 199, 199, 199;
--alpha-button-background: 1;
--alpha-button-border: 1;
--color-social: 255, 255, 255;
--color-accent: 191, 191, 191;
--color-base-border-input: 255, 255, 255;
--color-overlay: 35, 35, 34;
--color-placeholder-input: 255, 255, 255;
--color-inventory: 171, 171, 170;
--color-subtitle-alpha: 0.6;
}
[data-scheme=auto] .color-background-1,
[data-scheme=auto] .color-background-2,
[data-scheme=auto] .color-background-3,
[data-scheme=auto] .color-background-4 {
--color-background: 23, 23, 23;
--color-foreground: 255, 255, 255;
--color-foreground-title: 255, 255, 255;
--color-social: 255, 255, 255;
--color-link: 255, 255, 255;
--color-button: 46, 46, 46;
--color-button-text: 255, 255, 255;
--color-button-hover: 0, 0, 0;
--color-button-hover-text: 255, 255, 255;
--color-button-secondary-text: 213, 213, 213;
--color-button-secondary-hover-text: 199, 199, 199;
--color-border-input: 46, 46, 46;
--color-border-input-hover: 60, 60, 60;
--color-background-input: 35, 35, 34;
}
[data-scheme=auto] .color-background-2,
[data-scheme=auto] .color-background-5,
[data-scheme=auto] .color-inverse {
--color-background: 23, 23, 23;
--color-base-background-2: 23, 23, 23;
--color-button-secondary: 255, 255, 255;
--color-background-input: 35, 35, 34;
--color-link: 255, 255, 255;
}
[data-scheme=auto] .color-border-1 {
--color-border: 67, 67, 67;
}
[data-scheme=auto] .color-background-4 {
--color-background: 35, 35, 34;
}
[data-scheme=auto] .color-background-5 {
--color-background: 40, 40, 40;
}
[data-scheme=auto] .image-parallax.color-background-3 {
--color-button: 0, 0, 0;
--color-button-text: 255, 255, 255;
}
[data-scheme=auto] .swiper-buttons.swiper-buttons--secondary .icon {
color: rgb(var(--color-background));
}
[data-scheme=auto] .image-parallax__button.button--secondary .icon-button-arrow {
color: rgb(var(--color-background));
}
[data-scheme=auto] .featured-products__products-item .card__inner,
[data-scheme=auto] .card__link::after,
[data-scheme=auto] .card-horizontal__link,
[data-scheme=auto] .card-horizontal__link::after,
[data-scheme=auto] .collection-product-list .collection-product-card--extended .card__link::after {
background-color: rgb(var(--color-base-background-1));
}
[data-scheme=auto] .collection-popular-card .card-information__wrapper > *,
[data-scheme=auto] .collection-product-list .card-information__wrapper > *,
[data-scheme=auto] .collection-results--products .card-information__wrapper > *,
[data-scheme=auto] .product-recommendations__product .card-information__wrapper > *,
[data-scheme=auto] .search-modal .card-horizontal__information > *,
[data-scheme=auto] .search-modal .card-horizontal-wrapper .price--on-sale .price-item--regular,
[data-scheme=auto] .search-modal .card-horizontal-wrapper .price__regular {
color: #fff;
}
[data-scheme=auto] .search-modal .card-horizontal-wrapper .price--on-sale .price-item--regular {
text-decoration-color: #fff;
}
[data-scheme=auto] .collection-popular-card .card-wrapper,
[data-scheme=auto] .collection-product-list .card-wrapper:not(.card-wrapper-page),
[data-scheme=auto] .collection-results--products .card-wrapper,
[data-scheme=auto] .product-recommendations__product .card-wrapper,
[data-scheme=auto] .search-modal .search__items-item,
[data-scheme=auto] .search-modal .card-horizontal-wrapper {
background-color: rgb(var(--color-background));
}
[data-scheme=auto] .product-slider .swiper-buttons:before,
[data-scheme=auto] .featured-products .swiper-buttons:before {
background-color: rgb(var(--color-base-button-background), 0.7);
}
[data-scheme=auto] .product-slider .swiper-button-next,
[data-scheme=auto] .product-slider .swiper-button-prev,
[data-scheme=auto] .product-slider .swiper-pagination,
[data-scheme=auto] .featured-products .swiper-button-next,
[data-scheme=auto] .featured-products .swiper-button-prev,
[data-scheme=auto] .featured-products .swiper-pagination {
color: rgb(var(--color-base-solid-button-labels));
}
[data-scheme=auto] .featured-products__products-list--horizontal.featured-products__products-list--placeholder .card-wrapper,
[data-scheme=auto] .featured-products__products-list .featured-products__products-item--horizontal .card-horizontal-wrapper {
border-color: rgb(223, 223, 223);
}
[data-scheme=auto] .featured-products__button .button--primary {
background-color: rgb(var(--color-base-button-background));
color: rgb(var(--color-base-solid-button-labels));
}
[data-scheme=auto] .featured-products__button .button--secondary {
background-color: rgb(var(--color-base-outline-button-labels));
color: rgb(var(--color-secondary-button-labels));
}
[data-scheme=auto] .footer.color-background-1 .newsletter__form .field__input, [data-scheme=auto] .footer.color-background-2 .newsletter__form .field__input, [data-scheme=auto] .footer.color-background-3 .newsletter__form .field__input, [data-scheme=auto] .footer.color-background-4 .newsletter__form .field__input, [data-scheme=auto] .footer.color-background-5 .newsletter__form .field__input, [data-scheme=auto] .footer.color-inverse .newsletter__form .field__input {
border-color: rgb(0, 0, 0);
}
[data-scheme=auto] .footer.color-background-1 .disclosure__button, [data-scheme=auto] .footer.color-background-2 .disclosure__button, [data-scheme=auto] .footer.color-background-3 .disclosure__button, [data-scheme=auto] .footer.color-background-4 .disclosure__button, [data-scheme=auto] .footer.color-background-5 .disclosure__button, [data-scheme=auto] .footer.color-inverse .disclosure__button {
box-shadow: 0 0 0 0.1rem rgb(0, 0, 0);
}
[data-scheme=auto] .filters__item:not(.filters__item_active) {
opacity: 0.5;
}
[data-scheme=auto] .filters__item:not(.filters__item_active) span {
color: #000;
}
[data-scheme=auto] .cart__blocks-content {
background-color: transparent;
}
[data-scheme=auto] .default-address {
background-color: var(--color-badge-text);
color: var(--color-badge-bg);
}
[data-scheme=auto] .select__sort_by option {
background-color: rgb(var(--color-background));
}
.swiper-buttons.swiper-buttons--secondary .icon {
color: rgb(var(--color-background));
}
.image-parallax__button.button--secondary .icon-button-arrow {
color: rgb(var(--color-background));
}
.swiper-buttons.swiper-buttons--secondary .swiper-button-next,
.swiper-buttons.swiper-buttons--secondary .swiper-button-prev,
.swiper-buttons.swiper-buttons--secondary .swiper-pagination {
color: rgb(var(--color-button-text));
}
.hide__checkbox .facets-checkbox__label {
background-color: rgba(var(--color-background-input), 0.8);
}
.hide__checkbox .facets-checkbox__hide:not(:disabled) ~ .facets-checkbox__label:hover,
.hide__checkbox .facets-checkbox__hide:checked ~ .facets-checkbox__label {
background: rgba(var(--color-foreground-title), 1);
}
}
@media (max-width: 1199px) {
:root {
--header-height: var(--header-mobile-height);
}
}
.container,
.container-fluid,
.shopify-policy__container {
max-width: 1840px !important;
padding-left: 2rem !important;
padding-right: 2rem !important;
width: 100%;
margin: 0 auto;
}

/* Base */
html {
-moz-text-size-adjust: none;
-webkit-text-size-adjust: none;
text-size-adjust: none;
}

input[type=radio]:focus-visible + label,
input[type=checkbox]:focus-visible + label,
*:focus-visible {
outline: 0.2rem solid rgba(var(--color-foreground), 0.5);
outline-offset: -0.2rem;
box-shadow: 0 0 0.2rem 0 rgba(var(--color-foreground), 0.3);
}

input[type=radio]:focus-visible + label,
input[type=checkbox]:focus-visible + label {
box-shadow: 0 0 0 0.4rem rgba(var(--color-foreground), 0.3);
}

.no-js:not(html) {
display: none !important;
}

html.no-js .no-js:not(html) {
display: block !important;
}

.no-js-inline {
display: none !important;
}

html.no-js .no-js-inline {
display: inline-block !important;
}

html.js .js-hidden {
display: none !important;
}

html.no-js .no-js-block {
display: block !important;
}

html.no-js .no-js-hidden {
display: none !important;
}

.page-width {
max-width: 139.6rem;
margin: 0 auto;
padding: 0 2.5rem;
}

@media screen and (min-width: 750px) {
.page-width {
padding: 0 5rem;
}
.page-width--narrow {
padding: 0 9rem;
}
}
@media screen and (min-width: 990px) {
.page-width--narrow {
max-width: 72.6rem;
padding: 0;
}
}
.spaced-section {
position: relative;
background-color: rgb(var(--color-background));
}

.section-border-top {
padding-top: 5rem;
}
.section-border-top__inner {
position: relative;
}
.section-border-top__inner:before {
content: "";
position: absolute;
width: calc(100% - 4rem);
height: 1px;
left: 50%;
transform: translateX(-50%);
top: -5rem;
background-color: rgba(var(--color-foreground), 0.2);
}

@media screen and (min-width: 990px) {
.section-border-top {
padding-top: 10rem;
}
}
body {
-webkit-font-smoothing: antialiased;
}

body,
.color-background-1,
.color-background-2,
.color-background-3,
.color-background-4,
.color-background-5,
.color-inverse {
color: rgb(var(--color-foreground)) !important;
background-color: rgb(var(--color-background));
line-height: var(--font-body-line-height);
}

.background-secondary {
background-color: rgba(var(--color-foreground), 0.04);
padding: 4rem 0 5rem;
}

@media screen and (min-width: 750px) {
.background-secondary {
padding: 6rem 0 5rem;
}
}
.page-margin,
.shopify-challenge__container {
margin: 7rem auto;
}

.rte-width {
max-width: 82rem;
margin: 0 auto 2rem;
}

.list-unstyled {
margin: 0;
padding: 0;
list-style: none;
}

.hidden {
display: none !important;
}

.visually-hidden {
position: absolute !important;
overflow: hidden;
width: 1px;
height: 1px;
margin: -1px;
padding: 0;
border: 0;
clip: rect(0 0 0 0);
word-wrap: normal !important;
}

.visually-hidden--inline {
margin: 0;
height: 1em;
}

.visibility-hidden {
visibility: hidden;
}

.skip-to-content-link:focus {
z-index: 9999;
position: inherit;
overflow: auto;
width: auto;
height: auto;
clip: auto;
}

.overflow-hidden {
overflow: hidden;
}
@media screen and (min-width: 1200px) {
.overflow-hidden {
padding-right: 17px;
}
}

/* base-details-summary */
summary {
cursor: pointer;
list-style: none;
position: relative;
}

summary .icon-caret {
position: absolute;
height: 2rem;
right: 0;
top: calc(50% - 1rem);
}

summary::-webkit-details-marker {
display: none;
}

.disclosure-has-popup {
position: relative;
}

.disclosure-has-popup[open] > summary::before {
position: fixed;
top: 0;
right: 0;
bottom: 0;
left: 0;
z-index: 2;
display: block;
cursor: default;
content: " ";
background: transparent;
}

.disclosure-has-popup > summary::before {
display: none;
}

.disclosure-has-popup[open] > summary + * {
z-index: 100;
}

/* Swiper styles */
.swiper-btn {
display: none;
align-items: center;
justify-content: center;
width: 6.4rem;
height: 6.4rem;
border-radius: 50%;
opacity: 0.5;
background-color: rgb(var(--color-button-secondary));
background-repeat: no-repeat;
background-position: center center;
position: absolute;
top: 50%;
transform: translateY(-50%);
z-index: 1;
cursor: pointer;
transition: var(--duration-default);
}
@media (min-width: 990px) {
.swiper-btn {
display: flex;
}
}
.swiper-btn.swiper-button-disabled {
cursor: default;
}
.swiper-btn.swiper-button-disabled:hover svg {
transform: none;
}
.swiper-btn.swiper-button-disabled {
opacity: 0.2;
}
.swiper-btn:not(.swiper-button-disabled):hover {
opacity: 1;
box-shadow: 0 4rem 6rem rgba(0, 0, 0, 0.05);
}
.swiper-btn svg {
transition: var(--duration-default);
color: rgba(var(--color-foreground-title));
}
.swiper-btn--prev {
left: 4rem;
}
.swiper-btn--prev svg {
transform-origin: right;
}
.swiper-btn--prev:hover svg {
transform: scale(1.3);
}
.swiper-btn--next {
right: 4rem;
}
.swiper-btn--next svg {
transform-origin: left;
}
.swiper-btn--next:hover svg {
transform: scale(1.3);
}

/* Animation */
@keyframes translate {
0% {
transform: translate3d(0, 0, 0);
}
100% {
transform: translate3d(-40rem, 0, 0);
}
}
@keyframes fade {
0% {
opacity: 0;
}
100% {
opacity: 1;
}
}
@keyframes link-hover {
0% {
width: 100%;
}
50% {
width: 0;
}
100% {
width: 100%;
}
}
[data-scheme=dark] .icon-account-order-none circle + path {
stroke: rgb(var(--color-background));
}

/* Light header on dark bg */
.header-wrapper--overlay .header__menu-item {
transition: none;
}

body:not(.body--hidden) .header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .header__offcanvas-toggle .header__offcanvas-toggle-link {
color: #fff;
}
body:not(.body--hidden) .header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .header__heading-link > * {
color: #fff;
}

@media (prefers-color-scheme: light) {
.header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .header__heading .header__heading-logo--overlay,
.header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .header__heading-link .header__heading-logo--overlay {
display: block;
}
.header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .header__heading .header__heading-logo:not(.header__heading-logo--overlay),
.header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .header__heading-link .header__heading-logo:not(.header__heading-logo--overlay) {
display: none;
}
.header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .header__heading .header__heading-logo:only-child,
.header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .header__heading-link .header__heading-logo:only-child {
display: block !important;
}
.header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .header__offcanvas .header__heading-logo--overlay {
display: none;
}
.header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .header__offcanvas .header__heading-logo:not(.header__heading-logo--overlay) {
display: block;
}
.header__heading-logo:first-child:nth-last-child(2) {
display: block;
}
.header__heading-logo:first-child:nth-last-child(2) ~ .header__heading-logo {
display: none;
}
}
@media (prefers-color-scheme: dark) {
.header__heading-logo:first-child:nth-last-child(2) {
display: none;
}
.header__heading-logo:first-child:nth-last-child(2) ~ .header__heading-logo {
display: block;
}
.header__heading-logo:first-child:nth-last-child(1) {
display: none;
}
.header__heading-logo:only-child {
display: block !important;
}
}
[data-scheme=light] .header__heading-logo:first-child:nth-last-child(2) {
display: block;
}
[data-scheme=light] .header__heading-logo:first-child:nth-last-child(2) ~ .header__heading-logo {
display: none;
}
[data-scheme=light] .header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .header__heading .header__heading-logo--overlay,
[data-scheme=light] .header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .header__heading-link .header__heading-logo--overlay {
display: block;
}
[data-scheme=light] .header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .header__heading .header__heading-logo:not(.header__heading-logo--overlay),
[data-scheme=light] .header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .header__heading-link .header__heading-logo:not(.header__heading-logo--overlay) {
display: none;
}
[data-scheme=light] .header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .header__heading .header__heading-logo:only-child,
[data-scheme=light] .header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .header__heading-link .header__heading-logo:only-child {
display: block !important;
}
[data-scheme=light] .header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .header__offcanvas .header__heading-logo--overlay {
display: none;
}
[data-scheme=light] .header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .header__offcanvas .header__heading-logo:not(.header__heading-logo--overlay) {
display: block;
}

[data-scheme=dark] .header__heading-logo:first-child:nth-last-child(2) {
display: none;
}
[data-scheme=dark] .header__heading-logo:first-child:nth-last-child(2) ~ .header__heading-logo {
display: block;
}
[data-scheme=dark] .header__heading-logo:first-child:nth-last-child(1) {
display: none;
}
[data-scheme=dark] .header__heading-logo:only-child {
display: block !important;
}
[data-scheme=dark] .header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .header__offcanvas .header__heading-logo--overlay {
display: block;
}
[data-scheme=dark] .header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .header__offcanvas .header__heading-logo:not(.header__heading-logo--overlay) {
display: none;
}

.header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay {
background-color: transparent;
}
.header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay > *,
.header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .disclosure__button {
background-color: transparent;
transition: none;
}
.header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .header__menu-item:not(.header__submenu .header__menu-item),
.header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .header__icon .header__icon-name,
.header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .currency-country,
.header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .header__icon .icon-hamburger {
color: #fff;
}
.header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .header__menu-item .header__active-menu-item {
color: #fff;
}
.header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .header__menu-item:not(.header__submenu .header__menu-item) > span:not(.header__active-menu-item)::after,
.header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .header__icon .header__icon-name::before,
.header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .currency-country::after,
.header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .link-hover-line-outer:not(.header__submenu .header__menu-item):not(.disclosure__link) span::after {
background-color: #fff;
}
.header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .header__menu-item span:not(.header__active-menu-item) > span:after,
.header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .header__icon-name span:after,
.header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .header__localization .localization-selector.link:hover .icon-caret,
.header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .link--text.header__icon:hover .icon,
.header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .header__search:hover .modal__toggle-open,
.header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .header__modal-close-button .icon {
color: #fff;
}
.header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .header__toggle-scheme-item {
color: white;
}
.header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .header__toggle-scheme-item:hover .icon {
color: white;
}
.header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .icon-light-mode,
.header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .icon-dark-mode,
.header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .icon-search,
.header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .header__icon--account > .icon-account,
.header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .icon-cart {
color: white;
transition: none;
}
.header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .icon.icon-close:not(.header__modal-close-button .icon):not(.header__offcanvas-toggle-link .icon) {
color: rgb(var(--color-button));
}
.header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .header__menu-item .icon-caret {
color: white;
transition: none;
}
.header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .header__submenu .icon.icon-caret {
color: rgb(var(--color-accent));
transition: transform var(--duration-default);
}
.header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .list-menu--inline span.header__active-menu-item--border,
.header-main-section:not(.shopify-section-header-sticky.animate) .header-wrapper--overlay .list-menu--inline .header__menu-item--border:hover span:not(.header__active-menu-item) {
border-color: #fff;
}

[data-hover-opacity] > * {
transition: all var(--duration-default) ease;
}
[data-hover-opacity].opacity > * {
color: rgba(var(--color-foreground-title), 0.6);
}

/* Typography */
h1,
h2,
h3,
h4,
h5,
h6,
.h0,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
font-family: var(--font-heading-family);
font-style: var(--font-heading-style);
font-weight: var(--font-heading-weight);
letter-spacing: calc(var(--font-heading-letter-spacing) * 0.1);
color: rgb(var(--color-foreground-title)) !important;
line-height: var(--font-heading-line-height);
text-transform: var(--font-heading-text-transform);
word-wrap: break-word;
max-width: 100%;
}

.h0 {
font-size: 3rem;
}
@media only screen and (min-width: 750px) {
.h0 {
font-size: calc(var(--font-heading-h1-size) * 0.7);
}
}
@media only screen and (min-width: 1200px) {
.h0 {
font-size: calc(var(--font-heading-h1-size) * 1.1);
}
}
@media only screen and (min-width: 1540px) {
.h0 {
font-size: calc(var(--font-heading-h1-size) * 1.3);
}
}

h1,
.h1 {
font-size: 3rem !important;
}
@media only screen and (min-width: 750px) {
h1,
.h1 {
font-size: calc(var(--font-heading-h1-size) * 0.7);
}
}
@media only screen and (min-width: 1200px) {
h1,
.h1 {
font-size: calc(var(--font-heading-h1-size) * 0.8) !important;
}
}
@media only screen and (min-width: 1540px) {
h1,
.h1 {
font-size: var(--font-heading-h1-size);
}
}

h2,
.h2 {
font-size: 2.4rem !important;
}
@media only screen and (min-width: 750px) {
h2,
.h2 {
font-size: calc(var(--font-heading-h2-size) * 0.7);
}
}
@media only screen and (min-width: 1200px) {
h2,
.h2 {
font-size: calc(var(--font-heading-h2-size) * 0.8) !important;
}
}
@media only screen and (min-width: 1540px) {
h2,
.h2 {
font-size: var(--font-heading-h2-size);
}
}

h3,
.h3 {
font-size: 2rem !important;
}
@media only screen and (min-width: 750px) {
h3,
.h3 {
font-size: calc(var(--font-heading-h3-size) * 0.7) !important;
}
}
@media only screen and (min-width: 1200px) {
h3,
.h3 {
font-size: calc(var(--font-heading-h3-size) * 0.8) !important;
}
}
@media only screen and (min-width: 1540px) {
h3,
.h3 {
font-size: var(--font-heading-h3-size);
}
}

h4,
.h4 {
font-size: clamp(1.6rem, var(--font-heading-h4-size) * 0.7, var(--font-heading-h4-size)) !important;
}
@media only screen and (min-width: 1200px) {
h4,
.h4 {
font-size: calc(var(--font-heading-h4-size) * 0.8) !important;
}
}
@media only screen and (min-width: 1540px) {
h4,
.h4 {
font-size: var(--font-heading-h4-size);
}
}

h5,
.h5 {
font-size: clamp(1.6rem, var(--font-heading-h5-size) * 0.7, var(--font-heading-h5-size));
}
@media only screen and (min-width: 1200px) {
h5,
.h5 {
font-size: var(--font-heading-h5-size) !important;
}
}

h6,
.h6 {
font-size: clamp(1.6rem, var(--font-heading-h6-size) * 0.7, var(--font-heading-h6-size)) !important;
}
@media only screen and (min-width: 1200px) {
h6,
.h6 {
font-size: var(--font-heading-h6-size) !important;
}
}

a {
color: rgba(var(--color-foreground)) !important;
text-decoration-color: rgb(var(--color-accent));
text-underline-offset: 0.6rem;
}

blockquote {
padding: 2rem 3rem;
font-size: 1.6rem;
color: rgba(var(--color-foreground));
line-height: 1.3;
border-top: 0.3rem solid rgba(var(--color-foreground));
border-bottom: 0.1rem solid rgba(var(--color-foreground), 0.3);
}

@media screen and (min-width: 750px) {
blockquote {
padding: 2.4rem 5rem;
font-size: 2rem;
}
}
table:not([class]) {
table-layout: fixed;
border-collapse: collapse;
font-size: 1.4rem;
border-style: hidden;
box-shadow: 0 0 0 0.1rem rgba(var(--color-foreground), 0.2);
/* draws the table border  */
}

table:not([class]) td,
table:not([class]) th {
padding: 1em;
border: 0.1rem solid rgba(var(--color-foreground), 0.2);
}

a:empty,
ul:empty,
dl:empty,
section:empty,
article:empty,
p:empty,
h1:empty,
h2:empty,
h3:empty,
h4:empty,
h5:empty,
h6:empty {
display: none;
}

hr {
display: block;
margin-block: 5rem;
width: 100%;
height: 0.1rem;
background-color: rgb(var(--color-border));
border: none;
}
@media screen and (min-width: 750px) {
hr {
margin-block: 16rem;
}
}

.caption {
font-size: 1rem;
letter-spacing: 0.07rem;
line-height: 1.7;
}

@media screen and (min-width: 750px) {
.caption {
font-size: 1.2rem;
}
}
.caption-with-letter-spacing {
margin-top: 1rem;
font-size: 1.2rem;
}

.caption-large,
.form__label {
text-transform: uppercase;
font-weight: 600;
color: rgba(var(--color-foreground));
}

@media screen and (max-width: 749px) {
.small-hide {
display: none;
}
}
@media screen and (max-width: 749px) {
.small-hide {
display: none !important;
}
}
@media screen and (min-width: 750px) and (max-width: 989px) {
.medium-hide {
display: none !important;
}
}
@media screen and (min-width: 990px) {
.large-up-hide {
display: none !important;
}
}
.center {
text-align: center;
}

.right {
text-align: right;
}

.uppercase {
text-transform: uppercase;
}

.light {
opacity: 0.7;
}

.link {
cursor: pointer;
display: inline-block;
border: none;
box-shadow: none;
text-decoration: underline;
text-underline-offset: 0.3rem;
color: rgb(var(--color-link));
background-color: transparent;
font-size: 1.5rem;
transition: var(--duration-default);
}

.link--text {
color: rgb(var(--color-foreground));
transition: var(--duration-default);
}

.circle-divider::after {
content: "•";
margin: 0 8px 0 8px;
}

.circle-divider:last-of-type::after {
display: none;
}

.line-divider {
align-items: center;
justify-content: center;
}

.line-divider::after {
content: "";
display: inline-flex;
width: 15px;
height: 1px;
margin: 0 8px 0 8px;
background: rgba(var(--color-foreground), 0.6);
}

.line-divider:last-of-type::after {
display: none;
}

.full-unstyled-link,
.unstyled-link {
text-decoration: none;
color: currentColor;
}

.full-unstyled-link {
display: block;
}

.placeholder {
background-color: rgba(var(--color-foreground), 0.04);
color: rgba(var(--color-foreground), 0.55);
fill: rgba(var(--color-foreground), 0.55);
}

details > * {
box-sizing: border-box;
}

.break {
word-break: break-word;
}

@media (prefers-reduced-motion) {
.motion-reduce {
transition: none !important;
}
}
.underlined-link {
color: rgba(var(--color-link), var(--alpha-link));
text-underline-offset: 0.3rem;
text-decoration-thickness: 0.1rem;
transition: text-decoration-thickness ease 100ms;
}

.underlined-link:hover {
color: rgb(var(--color-link));
text-decoration-thickness: 0.2rem;
}

.link-hover-line {
position: relative;
}
.link-hover-line::after {
position: absolute;
bottom: 0;
left: 0;
right: 0;
content: "";
width: 0;
height: 0.1rem;
background-color: currentColor;
transition: width var(--duration-default);
}
.link-hover-line:hover::after {
width: 100%;
}

.link-hover-line-outer > span {
position: relative;
}
.link-hover-line-outer > span::after {
position: absolute;
bottom: -0.3rem;
left: 0;
right: 0;
content: "";
width: 0;
height: 0.1rem;
background-color: rgb(var(--color-accent));
transition: width var(--duration-default);
}
.link-hover-line-outer:hover > span::after {
width: 100%;
}

.link-hover-arrow svg {
opacity: 0;
transition: opacity var(--duration-default);
}
.link-hover-arrow:hover svg {
opacity: 1;
}

.richtext__content p {
margin: 0 0 2rem;
}
.richtext__content p:last-child {
margin: 0;
}

/* Component-title */
.title {
margin: 3rem 0 2rem;
}

.title-wrapper {
margin-bottom: 3rem;
}

.title--primary {
margin: 4rem 0;
}

.title--page {
text-align: left;
}

.title--section > * {
margin: 0;
}

.title--section-mt-none {
margin: 0 0 3rem 0;
}

.title--section-m-none {
margin: 0;
}

@media screen and (min-width: 990px) {
.title {
margin: 5rem 0 3rem;
}
.title--primary {
margin: 2rem 0;
}
}
.page-header {
margin-bottom: 1.5rem;
padding-top: 1.5rem;
padding-bottom: 2rem;
text-align: center;
}

.page-header .title--page {
margin-bottom: 0;
margin-top: 0;
}

.page-header .title--page:not(:first-child) {
margin-bottom: 1.5rem;
}

@media screen and (min-width: 750px) {
.page-header {
margin-bottom: 2rem;
padding-top: 2.6rem;
}
}
.subtitle,
.small-text {
font-family: var(--font-subtitle-family);
font-weight: var(--font-subtitle-weight);
font-style: var(--font-subtitle-style);
letter-spacing: var(--font-subtitle-letter-spacing);
text-transform: var(--font-subtitle-text-transform);
color: rgba(var(--color-foreground-sub-title), var(--color-subtitle-alpha));
font-size: var(--font-subtitle-size);
margin-bottom: 1.6rem;
}

.small-text {
font-family: var(--font-body-family);
margin-bottom: 0rem;
text-transform: none;
}

@media screen and (min-width: 750px) and (max-width: 989px) {
.slider--tablet.grid--peek .grid__item {
width: calc(25% - 3rem);
}
.slider--tablet.grid--peek.grid--3-col-tablet .grid__item {
width: calc(33.33% - 2.6666666667rem);
}
.slider--tablet.grid--peek.grid--2-col-tablet .grid__item {
width: calc(50% - 2rem);
}
.slider--tablet.grid--peek .grid__item:first-of-type {
padding-left: 1.5rem;
}
.slider--tablet.grid--peek .grid__item:last-of-type {
padding-right: 1.5rem;
}
}
@media screen and (max-width: 989px) {
.slider--tablet.grid--peek {
margin: 0;
width: 100%;
}
.slider--tablet.grid--peek .grid__item {
box-sizing: content-box;
margin: 0;
}
}
.slider-btns {
display: flex;
align-items: center;
justify-content: flex-end;
gap: 3rem;
}

.slider-btn {
flex: none;
padding: 0;
width: 6.2rem;
height: 3rem;
color: rgba(var(--color-foreground-title), 1);
background: none;
border: none;
border-radius: none;
transition: var(--duration-default);
transform: scale(1);
cursor: pointer;
}

@media screen and (min-width: 1330px) {
.slider-btn--next:hover {
transform: translateX(0.5rem);
}
.slider-btn--prev:hover {
transform: translateX(-0.5rem);
}
}
@media screen and (max-width: 768px) {
.slider-btn {
width: 3rem;
}
}
/* Media */
.media {
display: block;
background-color: rgba(var(--color-foreground), 0.3);
position: relative;
overflow: hidden;
}

.media--transparent {
background-color: transparent;
}

.media > *:not(.zoom, .deferred-media__poster-button, .card__link),
.media model-viewer,
.card-wrapper__link--overlay:empty {
display: block;
max-width: 100%;
position: absolute;
top: 0;
left: 0;
height: 100%;
width: 100%;
z-index: 2;
}

.media > img {
object-fit: cover;
object-position: center center;
transition: opacity var(--duration-long), transform var(--duration-long);
}

.media--square {
padding-bottom: 100%;
}

.media--portrait {
padding-bottom: 114.2%;
}

.media--landscape {
padding-bottom: 66.6%;
}

.media--cropped {
padding-bottom: 56%;
}

.media--16-9 {
padding-bottom: 56.25%;
}

.media--circle {
padding-bottom: 100%;
border-radius: 50%;
}

.media.media--hover-effect > img + img {
opacity: 0;
}

@media screen and (min-width: 990px) {
.media--cropped {
padding-bottom: 63%;
}
}
deferred-media {
display: block;
}

/* Button */
/* Button - default */
.button,
.customer .button {
font-family: var(--font-button-family);
font-weight: var(--font-button-weight);
font-style: var(--font-button-style);
text-transform: var(--font-button-text-transform);
letter-spacing: var(--font-button-letter-spacing);
position: relative;
box-sizing: border-box;
text-decoration: none;
border: 0;
border-radius: var(--border-radius-button);
transition: var(--duration-default);
font-size: 1.5rem;
line-height: 1.2;
-webkit-appearance: none;
appearance: none;
cursor: pointer;
z-index: 1;
}
.button--primary, .button--secondary,
.customer .button--primary,
.customer .button--secondary {
display: inline-flex;
justify-content: center;
align-items: center;
min-height: 6rem;
padding: 1rem 2.8rem;
transition: all var(--duration-default) ease;
}
.button--primary span, .button--secondary span,
.customer .button--primary span,
.customer .button--secondary span {
transition: inherit;
}
.button--primary span svg, .button--secondary span svg,
.customer .button--primary span svg,
.customer .button--secondary span svg {
margin-left: 1rem;
}
.button--primary:not([disabled]):not([aria-disabled=true]):hover span, .button--secondary:not([disabled]):not([aria-disabled=true]):hover span,
.customer .button--primary:not([disabled]):not([aria-disabled=true]):hover span,
.customer .button--secondary:not([disabled]):not([aria-disabled=true]):hover span {
transform: rotate(45deg) translateY(-4px);
}
.button--primary,
.customer .button--primary {
background-color: rgba(var(--color-button), var(--alpha-button-background));
color: rgb(var(--color-button-text)) !important;
}
.button--primary:not([disabled]):not([aria-disabled=true]):hover,
.customer .button--primary:not([disabled]):not([aria-disabled=true]):hover {
background-color: rgba(var(--color-button-hover), var(--alpha-button-background));
color: rgb(var(--color-button-hover-text));
}
.button--secondary,
.customer .button--secondary {
background-color: transparent;
color: rgb(var(--color-button-secondary-text));
border: 2px solid;
}
.button--secondary:not([disabled]):not([aria-disabled=true]):hover,
.customer .button--secondary:not([disabled]):not([aria-disabled=true]):hover {
color: rgb(var(--color-button-secondary-hover-text));
}
.button--simple-move,
.customer .button--simple-move {
--alpha-button-background: 0;
padding: 0;
min-width: auto;
min-height: auto;
background: none;
box-shadow: none;
color: rgba(var(--color-foreground));
font-size: 1.5rem;
font-weight: 700;
overflow: visible;
}
.button--simple-move:after,
.customer .button--simple-move:after {
display: none;
}
.button--simple-move span,
.customer .button--simple-move span {
word-break: break-word;
text-decoration: underline;
text-decoration-color: rgb(var(--color-accent));
text-underline-offset: 0.6rem;
text-decoration-thickness: 0.1rem;
}
.button--simple-move svg,
.customer .button--simple-move svg {
opacity: 0;
margin-left: -1.2rem;
transition: margin-left var(--duration-default), opacity var(--duration-default) !important;
color: rgba(var(--color-button));
}
.button--simple-move:hover svg,
.customer .button--simple-move:hover svg {
margin-left: 0.8rem;
opacity: 1;
}
.button--arrow,
.customer .button--arrow {
position: relative;
gap: 0.8rem;
}
.button--simple,
.customer .button--simple {
display: inline-flex;
align-items: center;
padding: 0 0 0.2rem;
min-width: auto;
min-height: auto;
background: none;
box-shadow: none;
color: rgba(var(--color-foreground-title));
--alpha-button-background: 0;
line-height: 1.5;
overflow: visible;
flex: none;
max-width: 300px;
word-break: break-word;
position: relative;
}
.button--simple span,
.customer .button--simple span {
display: flex;
}
.button--simple svg,
.customer .button--simple svg {
margin-right: 0.8rem;
min-width: 17px;
width: 17px;
min-height: 17px;
}
.button--simple .button-simpl__label,
.customer .button--simple .button-simpl__label {
position: relative;
}
.button--simple .button-simpl__label::after,
.customer .button--simple .button-simpl__label::after {
content: "";
position: absolute;
right: 0;
bottom: 0;
width: 100%;
height: 2px;
transition: all var(--duration-default) linear;
background-color: rgba(var(--color-foreground));
transform: rotate(180deg);
}
.button--simple:not([disabled]):not([aria-disabled=true]):hover .button-simpl__label::after,
.customer .button--simple:not([disabled]):not([aria-disabled=true]):hover .button-simpl__label::after {
width: 0;
}
.button--simple .icon-button-arrow,
.customer .button--simple .icon-button-arrow {
margin-left: 0.8rem;
width: 1.2rem;
height: 1.1rem;
color: rgb(var(--color-button));
transition: opacity var(--duration-default);
opacity: 0;
}
.button--simple:not([disabled]):not([aria-disabled=true]):hover .icon-button-arrow,
.customer .button--simple:not([disabled]):not([aria-disabled=true]):hover .icon-button-arrow {
opacity: 1;
color: rgba(var(--color-button));
}
.button--primary-size,
.customer .button--primary-size {
min-height: 6rem;
padding: 0 2.8rem;
}
.button--small,
.customer .button--small {
padding: 1rem 2.6rem;
min-height: 4rem;
min-width: 13rem;
}
.button--full-width,
.customer .button--full-width {
width: 100%;
}
.button .spinner,
.customer .button .spinner {
display: none;
}
.button.loading span,
.customer .button.loading span {
opacity: 0;
}
.button.loading .spinner,
.customer .button.loading .spinner {
position: absolute;
display: inline-block;
}
.button.loading .spinner .path,
.customer .button.loading .spinner .path {
stroke: currentColor;
}

/* Button - other */
.button:disabled,
.button[aria-disabled=true],
.button.disabled,
.customer button:disabled,
.customer button[aria-disabled=true],
.customer button.disabled {
cursor: not-allowed;
opacity: 0.5;
}
.button:disabled.button--simple:hover::after,
.button[aria-disabled=true].button--simple:hover::after,
.button.disabled.button--simple:hover::after,
.customer button:disabled.button--simple:hover::after,
.customer button[aria-disabled=true].button--simple:hover::after,
.customer button.disabled.button--simple:hover::after {
width: 100%;
}

@keyframes loading {
0% {
transform: rotate(0deg);
}
100% {
transform: rotate(360deg);
}
}
/* Button - social share */
.share-button {
display: block;
position: relative;
margin-top: 1rem;
}

.share-button__button {
padding: 0;
box-shadow: none;
background: none;
text-transform: none;
font-weight: 500;
justify-content: flex-start;
}

.share-button .share-button__button:hover {
box-shadow: none;
}

.share-button .share-button__button:focus {
box-shadow: none;
border: none;
}

.share-button__button .icon-share {
margin-right: 1rem;
}

.share-button__fallback {
border: 0.1rem solid rgba(var(--color-foreground), 0.1);
background: rgb(var(--color-background));
padding: 2rem;
position: absolute;
top: 4rem;
left: -0.1rem;
z-index: 3;
width: 100%;
display: flex;
flex-direction: column;
}

.share-button__fallback .button {
margin-top: 1rem;
}

.share-button__fallback .icon-clipboard {
height: 1.4rem;
width: 1.8rem;
}

.share-button__message:not(:empty) {
font-size: 1.2rem;
text-align: right;
display: block;
margin-top: 0.5rem;
}

/* Button - social share new */
.share-buttons {
display: inline-flex;
position: relative;
margin-top: 0;
}

.share-buttons:hover .share-buttons__list {
opacity: 1;
visibility: visible;
top: 100%;
}

.share-buttons__label {
display: inline-flex;
align-items: center;
cursor: pointer;
}
.share-buttons__label:hover span::after {
bottom: 0;
}

.share-buttons__label-name {
font-size: 1.2rem;
}

.share-buttons__label .icon-share {
width: 1.6rem;
}
@media (min-width: 576px) {
.share-buttons__label .icon-share {
width: 2.4rem;
}
}

.quick-add-modal .share-buttons__label .icon-share {
width: 2rem;
}

.share-buttons__label .icon-share path {
fill: rgba(var(--color-foreground));
}

.share-buttons__list {
position: absolute;
width: 17rem;
top: 50%;
display: flex;
flex-direction: column;
padding: 1.3rem 2.2rem;
margin: 0;
list-style: none;
opacity: 0;
visibility: hidden;
transition: var(--duration-default);
background-color: rgba(var(--color-base-background-2));
border: 0.1rem solid rgba(var(--color-foreground), 0.2);
z-index: 10;
}

.share-buttons__list:before {
content: "";
position: absolute;
width: 1rem;
height: 1rem;
background-color: rgba(var(--color-base-background-2));
border: 0.1rem solid rgba(var(--color-foreground), 0.2);
top: -0.5rem;
left: 2rem;
transform: rotate(53deg) skew(15deg);
}

.share-buttons__list:after {
content: "";
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 0.7rem;
background-color: rgba(var(--color-base-background-2));
}

.share-buttons__item a {
color: rgba(var(--color-foreground-title), 1);
transition: all var(--duration-default) ease;
}
.share-buttons__item a:hover {
opacity: 0.5;
}

.share-buttons__item a:hover > span::after {
bottom: 0;
}

.share-buttons__item .icon {
width: 1.5rem;
height: 1.5rem;
margin-right: 1rem;
}

.share-buttons__item .icon-copy {
transform: rotate(-45deg);
}

.share-buttons__button {
display: flex;
align-items: center;
width: 100%;
text-decoration: none;
padding: 0.5rem 0;
}

.share-button__name {
font-size: 1.4rem;
transition: var(--duration-default);
}

.share-button__icon {
display: flex;
align-items: center;
color: rgb(var(--color-foreground));
padding: 0.8rem;
background-color: rgb(var(--color-scondary-element-background));
border-radius: 100%;
transition: var(--duration-default);
}

.share-buttons__button:hover .share-button__icon {
background-color: rgb(var(--color-scondary-element-background), 0.5);
}

.share-buttons-simple {
list-style: none;
padding: 0;
display: flex;
justify-content: center;
align-items: center;
margin: 0;
}

.share-buttons-simple .icon {
width: 1.8rem;
height: 1.8rem;
color: rgb(var(--color-foreground-title));
}

.share-buttons-simple .icon-copy {
transform: rotate(-45deg);
}

.share-buttons-simple__button {
display: block;
padding: 1.3rem;
transition: var(--duration-default);
}

.share-buttons-simple__button:hover {
color: rgba(var(--color-foreground-title), 0.2);
}

.modal-close-button {
box-sizing: border-box;
display: flex;
align-items: center;
justify-content: center;
min-width: 4.4rem;
max-width: 4.4rem;
height: 4.4rem;
padding: 0;
color: rgb(var(--color-foreground));
background-color: transparent;
border: none;
transform: translateX(1.6rem) translateY(-1.6rem);
cursor: pointer;
}
.modal-close-button .icon-close {
width: 1.4rem;
height: 1.4rem;
transition: var(--duration-default) ease;
}
.modal-close-button:hover .icon-close {
transform: rotate(90deg);
}

.header__offcanvas-toggle-link .icon-close {
color: rgb(var(--color-foreground));
}

.fancybox-close-button {
position: absolute;
top: 2rem;
right: 2rem;
}

.swiper-buttons {
position: relative;
display: flex;
align-items: center;
justify-content: space-between;
margin-top: 5.4rem;
padding: 1rem;
width: 14rem;
height: 6.4rem;
font-family: var(--font-button-family);
font-weight: var(--font-button-weight);
font-style: var(--font-button-style);
text-transform: var(--font-button-text-transform);
letter-spacing: var(--font-button-letter-spacing);
color: rgb(var(--color-button-text));
text-decoration: none;
background-color: rgba(var(--color-button));
border-radius: var(--border-radius-button);
border: 0;
font-size: 1.2rem;
line-height: 1;
z-index: 1;
}
.swiper-buttons .swiper-button-next .icon, .swiper-buttons .swiper-button-prev .icon {
transition: transform var(--duration-default);
}
.swiper-buttons .swiper-button-next:hover .icon, .swiper-buttons .swiper-button-prev:hover .icon {
transform: scale(1.3);
}
.swiper-buttons .swiper-button-next .icon {
transform-origin: left;
}
.swiper-buttons .swiper-button-prev .icon {
transform-origin: right;
}
.swiper-buttons.swiper-buttons--secondary {
background-color: rgb(var(--color-button-secondary));
}
.swiper-buttons.swiper-buttons--secondary .icon {
color: rgb(var(--color-button-secondary-text));
}

[data-scheme=dark] .swiper-buttons--secondary {
color: #000;
}

.swiper-button-next,
.swiper-button-prev,
.swiper-pagination {
position: static;
margin: 0;
padding: 0;
background-color: transparent;
border: none;
}

.swiper-pagination {
display: flex;
align-items: center;
justify-content: center;
gap: 0.1rem;
}

.swiper-button-next,
.swiper-button-prev {
position: static;
flex: none;
width: 4.4rem;
height: 4.4rem;
color: rgb(var(--color-foreground));
display: flex;
cursor: pointer;
}
.swiper-button-next .icon,
.swiper-button-prev .icon {
flex: none;
width: 1.6rem;
height: 1.6rem;
}

.swiper-button-next:after,
.swiper-button-prev:after {
display: none;
}

.swiper-bullets {
margin-top: 2.4rem;
}
.swiper-bullets .swiper-pagination-bullet {
background-color: rgb(var(--color-button-secondary));
opacity: 0.5;
}
.swiper-bullets .swiper-pagination-bullet-active {
opacity: 1;
}

.button-wrapper {
display: flex;
align-items: center;
justify-content: center;
margin-top: 4rem;
}
@media (min-width: 990px) {
.button-wrapper {
margin-top: 6.4rem;
}
}
.button-wrapper .button svg {
width: 2rem;
height: 2rem;
}

.swiper-buttons__wrapper {
position: absolute;
z-index: 2;
top: 50%;
transform: translateY(-50%);
left: 0;
width: 100%;
pointer-events: none;
box-sizing: border-box;
}
.swiper-buttons__wrapper .swiper-buttons__box {
position: relative;
display: flex;
align-items: center;
justify-content: space-between;
}
.swiper-buttons__wrapper .swiper-button span {
opacity: 1;
}
@media screen and (min-width: 1200px) {
.swiper-buttons__wrapper .swiper-button span {
opacity: 1;
}
}
.swiper-buttons__wrapper .container {
box-sizing: border-box;
}
@media screen and (min-width: 1200px) {
.swiper-buttons__wrapper {
box-sizing: content-box;
}
}

.swiper-pagination {
position: absolute;
bottom: 2rem !important;
}
@media screen and (min-width: 990px) {
.swiper-pagination {
bottom: 4rem !important;
}
}
.swiper-pagination .swiper-pagination-bullet {
background: transparent;
width: unset;
height: unset;
border-radius: unset;
opacity: 1;
margin: 0 !important;
padding: var(--swiper-pagination-bullet-horizontal-gap, 4px);
}
.swiper-pagination .swiper-pagination-bullet::before {
content: "";
display: block;
transition: all var(--duration-long) ease;
width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 8px));
height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 8px));
border-radius: 50%;
background: rgb(var(--color-foreground-title));
opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);
}
.swiper-pagination .swiper-pagination-bullet-active::before {
background: rgb(var(--color-foreground));
opacity: var(--swiper-pagination-bullet-opacity, 1);
}
.swiper-pagination .swiper-pagination-bullet:hover::before {
transform: scale(1.4);
opacity: 0.8;
}

.swiper-flip {
overflow: hidden !important;
}

.swiper-button-absolute {
position: absolute;
top: 50%;
transform: translateY(-50%);
}
.swiper-button-absolute span {
display: flex;
max-width: 20px;
}
.swiper-button-absolute.swiper-button-prev {
transform: translateY(-50%) rotate(-180deg);
right: 100%;
left: unset;
}
.swiper-button-absolute.swiper-button-next {
left: 100%;
}

.swiper-button {
transition: all var(--duration-long) ease;
pointer-events: all;
}
.swiper-button span {
display: flex;
transition: inherit;
max-width: 20px;
}
.swiper-button span svg {
transition: inherit;
}
@media screen and (min-width: 1200px) {
.swiper-button span {
max-width: unset;
}
}
@media screen and (min-width: 1200px) {
.swiper-button:hover svg {
transform: translateX(5px);
}
}

.swiper-button-prev {
justify-content: flex-start;
}
.swiper-button-prev span {
transform: rotate(-180deg);
}

.swiper-button-next {
justify-content: flex-end;
}

.swiper:hover .swiper-button span {
opacity: 1;
}

.btn-remove {
display: inline-block;
color: rgb(var(--color-foreground-secondary));
width: 2rem;
height: 2rem;
background: transparent;
border: 0;
outline: 0;
padding: 0;
cursor: pointer;
}
.btn-remove svg {
width: 100%;
height: 100%;
}
.btn-remove:hover svg {
animation: remove_animation var(--duration-default) linear;
}

@keyframes remove_animation {
0% {
transform: rotate(0deg);
}
25% {
transform: rotate(-25deg);
}
50% {
transform: rotate(0deg);
}
100% {
transform: rotate(25deg);
}
}
.no-js .swiper-pagination,
.no-js .swiper-buttons__wrapper,
.no-js .swiper-button {
display: none;
}

.scroll-top {
cursor: pointer;
position: fixed;
bottom: 1rem;
right: 1rem;
width: 3.2rem;
height: 3.2rem;
display: flex;
align-items: center;
justify-content: center;
background-color: rgba(var(--color-button), 0.5);
border: none;
border-radius: var(--border-radius-button);
opacity: 0;
z-index: 9999999;
transition: var(--duration-default);
}
.scroll-top svg {
width: 1.6rem;
height: 1.6rem;
fill: rgb(var(--color-button-text));
}
.scroll-top.show {
opacity: 1;
}
.scroll-top:hover {
background-color: rgba(var(--color-button), 1);
}

.infinite-scroll__loading {
display: flex;
justify-content: center;
margin-top: 4rem;
color: rgba(var(--color-button-secondary-text), var(--alpha-button-background));
}
.infinite-scroll__loading .spinner {
display: none;
width: 2rem;
height: 2rem;
}
.infinite-scroll__loading.loading .spinner {
display: block;
}

/* Form */
.field {
position: relative;
width: 100%;
min-height: 5.5rem;
display: flex;
}
.field label {
position: relative;
transition: var(--duration-short);
}
.field input[type=text],
.field input[type=email],
.field input[type=tel],
.field input[type=search],
.field input[type=password],
.field select,
.field .field__input {
font-family: var(--font-body-family);
font-style: var(--font-body-style);
font-weight: var(--font-body-weight);
-webkit-appearance: none;
appearance: none;
background-color: rgb(var(--color-background-input));
color: rgb(var(--color-placeholder-input));
font-size: 1.5rem;
width: 100%;
border: 0.1rem solid rgb(var(--color-border-input));
height: 5.5rem;
box-sizing: border-box;
transition: border var(--duration-default);
}
.field input[type=text]:-internal-autofill-selected + label,
.field input[type=email]:-internal-autofill-selected + label,
.field input[type=tel]:-internal-autofill-selected + label,
.field input[type=search]:-internal-autofill-selected + label,
.field input[type=password]:-internal-autofill-selected + label,
.field select:-internal-autofill-selected + label,
.field .field__input:-internal-autofill-selected + label {
display: none;
}
.field input[type=text]:hover,
.field input[type=email]:hover,
.field input[type=tel]:hover,
.field input[type=search]:hover,
.field input[type=password]:hover,
.field select:hover,
.field .field__input:hover {
border-color: rgb(var(--color-border-input-hover));
}
.field input[type=text]:focus,
.field input[type=email]:focus,
.field input[type=tel]:focus,
.field input[type=search]:focus,
.field input[type=password]:focus,
.field select:focus,
.field .field__input:focus {
border-color: rgb(var(--color-border-input));
}
.field input[type=search]::-webkit-search-cancel-button {
display: none;
}
.field input[type=search]::placeholder {
color: rgba(var(--color-placeholder-input), var(--alpha-placeholder-input));
}
.field input[type=text],
.field input[type=email],
.field input[type=tel],
.field input[type=password],
.field .field__input {
flex-grow: 1;
padding: 0.7rem 1.8rem;
text-align: left;
}
.field input[type=text]::-webkit-search-cancel-button,
.field input[type=email]::-webkit-search-cancel-button,
.field input[type=tel]::-webkit-search-cancel-button,
.field input[type=password]::-webkit-search-cancel-button,
.field .field__input::-webkit-search-cancel-button {
display: none;
}
.field input[type=text][required] + label:after,
.field input[type=email][required] + label:after,
.field input[type=tel][required] + label:after,
.field input[type=password][required] + label:after,
.field .field__input[required] + label:after {
content: "*";
display: none;
position: relative;
top: -0.3rem;
color: rgb(235, 87, 87);
padding-left: 0.3rem;
}
.field input[type=text] + label,
.field input[type=email] + label,
.field input[type=tel] + label,
.field input[type=password] + label,
.field .field__input + label {
display: none;
position: absolute;
left: 1.8rem;
top: 50%;
transform: translateY(-50%);
visibility: visible;
opacity: 1;
pointer-events: none;
font-weight: 400;
letter-spacing: -0.02em;
text-transform: none;
color: rgba(var(--color-placeholder-input), var(--alpha-placeholder-input));
}
.field input[type=text]:placeholder-shown + label,
.field input[type=email]:placeholder-shown + label,
.field input[type=tel]:placeholder-shown + label,
.field input[type=password]:placeholder-shown + label,
.field .field__input:placeholder-shown + label {
display: inline-block;
}
.field input[type=text]:focus + label,
.field input[type=email]:focus + label,
.field input[type=tel]:focus + label,
.field input[type=password]:focus + label,
.field .field__input:focus + label {
display: none;
}
.field textarea.field__input {
padding: 1.5rem 1.8rem;
}
.field .select {
width: 100%;
}
.field .select .placeholder-select {
color: rgba(var(--color-placeholder-input), var(--alpha-placeholder-input));
position: absolute;
top: 50%;
left: 1.8rem;
transform: translateY(-50%);
}
.field .select .placeholder-select span {
color: var(--color-badge-icon);
}
.field select {
cursor: pointer;
padding: 0 4rem 0 1.8rem;
}
.field select + svg {
width: 1.2rem;
height: 1.5rem;
pointer-events: none;
position: absolute;
top: 50%;
transform: translateY(-50%);
right: 2rem;
color: rgb(var(--color-button));
}
.field select option {
background-color: rgb(var(--color-background-input));
}
.field input[type=checkbox] {
display: none;
}
.field input[type=checkbox]:checked + label b {
background: rgba(var(--color-foreground)) url("data:image/svg+xml;charset=UTF-8,%3csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M4.66649 6.61451L10.7945 0.48584L11.7378 1.42851L4.66649 8.49984L0.423828 4.25717L1.36649 3.31451L4.66649 6.61451Z' fill='white'/%3e%3c/svg%3e ") no-repeat center center;
border-color: rgba(var(--color-foreground));
background-color: rgb(var(--color-button));
}
.field input[type=checkbox]:checked + label b svg {
opacity: 1;
}
.field input[type=checkbox]:focus + label b {
outline: 0.1rem solid rgba(var(--color-border-input)) !important;
outline-offset: 0;
}
.field input[type=checkbox] + label {
display: inline-flex;
align-items: center;
font-size: 1.5rem;
color: rgb(var(--color-foreground));
cursor: pointer;
}
.field input[type=checkbox] + label:hover b {
border-color: #bfbfbf;
}
.field input[type=checkbox] + label b {
display: flex;
align-items: center;
justify-content: center;
width: 1.7rem;
height: 1.7rem;
margin: 0 1rem 0 0;
border: 0.1rem solid rgba(var(--color-border-input));
background-color: rgb(var(--color-background-input));
appearance: none;
transition: var(--duration-default);
}
.field input[type=checkbox] + label b svg {
opacity: 0;
color: rgb(var(--color-button-text));
}
.field--dark input[type=text],
.field--dark input[type=email],
.field--dark input[type=tel],
.field--dark input[type=password],
.field--dark input[type=search] {
border-color: rgba(255, 255, 255, 0.4);
color: rgb(255, 255, 255);
background-color: transparent;
}
.field--dark input[type=text]:hover, .field--dark input[type=text]:focus,
.field--dark input[type=email]:hover,
.field--dark input[type=email]:focus,
.field--dark input[type=tel]:hover,
.field--dark input[type=tel]:focus,
.field--dark input[type=password]:hover,
.field--dark input[type=password]:focus,
.field--dark input[type=search]:hover,
.field--dark input[type=search]:focus {
border-color: rgb(255, 255, 255);
}
.field--dark input[type=text] + label,
.field--dark input[type=email] + label,
.field--dark input[type=tel] + label,
.field--dark input[type=password] + label,
.field--dark input[type=search] + label {
color: rgba(255, 255, 255, 0.5);
}

/* Text area */
.text-area {
font-family: var(--font-body-family);
font-style: var(--font-body-style);
font-weight: var(--font-body-weight);
padding: 1.2rem;
min-height: 10rem;
resize: none;
}

.text-area--resize-vertical {
resize: vertical;
}

.field .text-area + label {
top: 2.5rem;
}

/* Form global */
.form__label {
display: block;
margin-bottom: 0.6rem;
}

.form__message {
align-items: center;
display: flex;
font-size: 1.4rem;
line-height: 1;
margin-top: 1rem;
}

.form__message--large {
font-size: 1.4rem;
}

.form__message .icon {
flex-shrink: 0;
height: 1.3rem;
margin-right: 0.5rem;
width: 1.3rem;
}

.form__message--large .icon {
height: 1.5rem;
width: 1.5rem;
margin-right: 1rem;
}

.form-status {
margin: 0;
font-size: 1.4rem;
}

.form-status-list {
padding: 0;
margin: 2rem 0 4rem;
}

.form-status-list li {
list-style-position: inside;
}

.form-status-list .link::first-letter {
text-transform: capitalize;
}

/* Quantity */
.quantity {
position: relative;
width: 10rem;
display: flex;
background: rgb(var(--color-background-input));
border: 1px solid rgb(var(--color-border-input));
color: rgb(var(--color-placeholder-input));
}

.quantity__input {
color: rgb(var(--color-accent));
font-size: 1.6rem;
text-align: center;
background-color: transparent;
border: 0;
padding: 0 0.5rem;
height: 4rem;
width: 100%;
flex-grow: 1;
-webkit-appearance: none;
appearance: none;
font-family: inherit;
}

.quantity__button {
width: 3rem;
min-height: 3rem;
min-width: 3rem;
flex-shrink: 0;
font-size: 1.8rem;
border: 0;
background-color: transparent;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
transition: border-color var(--duration-default);
color: rgb(var(--color-foreground));
}
.quantity__button svg {
transition: opacity var(--duration-default);
}
.quantity__button .icon-minus {
height: 0.2rem;
}
.quantity__button .icon-plus {
height: 1rem;
}
.quantity__button:hover svg {
opacity: 0.5;
}

.quantity__button svg {
width: 1rem;
min-width: 1rem;
pointer-events: none;
}

.quantity__button svg path {
transition: var(--duration-default);
}

.quantity__button:hover svg path {
color: rgba(var(--color-foreground));
}

.quantity__input:-webkit-autofill,
.quantity__input:-webkit-autofill:hover,
.quantity__input:-webkit-autofill:active {
box-shadow: 0 0 0 10rem rgb(var(--color-background)) inset !important;
-webkit-box-shadow: 0 0 0 10rem rgb(var(--color-background)) inset !important;
}

.quantity__input::-webkit-outer-spin-button,
.quantity__input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

.quantity__input[type=number] {
-moz-appearance: textfield;
}

/* Negate the fallback side-effect for browsers that support :focus-visible */
*:focus:not(:focus-visible):not(.button--secondary):not(.localization-selector.link) {
outline: 0;
box-shadow: none;
}

/*
Focus ring - inset
*/
.focus-inset:focus-visible {
outline: 0.2rem solid rgba(var(--color-foreground), 0.5);
outline-offset: -0.2rem;
box-shadow: 0 0 0.2rem 0 rgba(var(--color-foreground), 0.3);
}

/* Fallback */
.focus-inset:focus {
outline: 0.2rem solid rgba(var(--color-foreground), 0.5);
outline-offset: -0.2rem;
box-shadow: 0 0 0.2rem 0 rgba(var(--color-foreground), 0.3);
}

.focus-inset:focus:not(:focus-visible) {
outline: 0;
box-shadow: none;
}

/*
Focus ring - none
*/
/* Dangerous for a11y - Use with care */
.focus-none {
box-shadow: none !important;
outline: 0 !important;
}

/* Modal */
.modal__toggle {
list-style-type: none;
}

.no-js details[open] .modal__toggle {
z-index: 2;
}

.modal__toggle-close {
display: none;
}

.no-js details[open] svg.modal__toggle-close {
display: flex;
z-index: 1;
height: 1.4rem;
width: 1.4rem;
}

.modal__toggle-open {
display: flex;
}

.no-js details[open] .modal__toggle-open {
display: none;
}

.no-js .modal__close-button.link {
display: none;
}

.modal__close-button.link {
display: flex;
justify-content: center;
align-items: center;
padding: 0rem;
background-color: transparent;
}

.modal__close-button .icon {
width: 2rem;
height: 2rem;
}

.modal__content {
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
background: rgb(var(--color-background));
z-index: 1;
display: flex;
justify-content: center;
align-items: center;
}

.fancybox-skin {
padding: 0 !important;
}

/* Announcement-bar */
.section-announcement {
transition: transform var(--duration-default);
z-index: 20;
}
.section-announcement .announcement-bar-light {
background-color: rgb(var(--color-announcement-bar-background-2));
}
.section-announcement .announcement-bar-dark {
background-color: rgb(var(--color-announcement-bar-background-1));
}

.body--hidden .section-announcement {
transform: translateY(-100%);
}

.announcement-bar {
display: flex;
justify-content: center;
flex-direction: column;
text-transform: uppercase;
height: var(--announcement-height);
}
@media screen and (min-width: 750px) {
.announcement-bar {
overflow-y: auto;
}
}
.announcement-bar__message {
display: flex;
align-items: center;
justify-content: center;
flex-wrap: wrap;
gap: 0.8rem;
text-align: center;
}
.announcement-bar__text {
font-family: var(--font-heading-family);
font-style: var(--font-heading-style);
font-weight: var(--font-heading-weight);
line-height: var(--font-heading-line-height);
font-size: 1.5rem !important;
}
.announcement-bar__text h6 {
margin: 0;
font-size: 1.5rem !important;
}
.announcement-bar .announcement-bar__slide {
display: none;
}

.announcement__slide-show .announcement-bar__box {
display: none;
}
.announcement__slide-show .announcement-bar__slide {
display: block;
}

.announcement-bar .container {
position: relative;
overflow: hidden;
max-width: 100%;
}
.announcement-bar .swiper-wrapper {
transition-timing-function: linear !important;
width: 100vw;
}
.announcement-bar .swiper-slide {
width: auto !important;
flex-shrink: 1;
display: inline-flex;
}
.announcement__title {
display: inline-flex;
text-transform: uppercase;
text-align: center;
align-items: center;
white-space: nowrap;
margin-left: 2.4rem;
}
.announcement__title .announcement__item {
margin: 0;
display: flex;
align-items: center;
margin-right: 20px;
}
.announcement__title .announcement__item::after {
content: "";
margin-left: 2rem;
display: inline-block;
width: 0.5em;
height: 0.5em;
max-width: 8px;
max-height: 8px;
background: rgb(var(--color-foreground-title));
}

/* Header */
body.body--hidden {
overflow-y: hidden;
}

.header-main-section {
z-index: 11;
will-change: transform;
display: none;
position: relative;
}
@media screen and (min-width: 1200px) {
.header-main-section {
display: block;
}
}

.shopify-section-header-sticky {
position: sticky;
top: 0;
}

.shopify-section-header-hidden {
transform: translateY(-100%);
}

.header-main-section.animate {
transition: transform 0.15s ease-out;
}

.header {
display: flex;
align-items: center;
width: 100%;
padding: 0.5rem 0;
border-bottom: 1px solid rgb(var(--color-border));
}
.header__wrapper {
display: grid;
grid-template-columns: 1fr auto 1fr;
gap: 3rem;
align-items: center;
}
.header__wrapper.header-position-left {
grid-template-columns: auto 1fr 1fr;
}
.header__list, .header__buttons {
display: flex;
align-items: center;
}
.header__list > li > *, .header__buttons > li > * {
font-family: var(--font-header-menu-family);
font-style: var(--font-header-menu-style);
font-weight: var(--font-header-menu-weight);
text-transform: var(--font-header-menu-text-transform);
font-size: var(--font-header-menu-link-size);
color: rgb(var(--color-foreground-title));
}
.header__buttons {
gap: 1.6rem;
justify-content: flex-end;
}
@media screen and (min-width: 1860px) {
.header__buttons {
gap: 3.2rem;
}
}
.header__list {
flex-wrap: wrap;
row-gap: 0.5rem;
margin: 0 -1.2rem;
}
.header__list > li:first-child {
padding-left: 0;
}
.header__list > li:last-child {
padding-right: 0;
}
.header__list > li:hover > .header-menu-trigger::before,
.header__list > li:hover span::before {
display: block;
}
.header__list > li:hover > .header-menu-trigger .icon,
.header__list > li:hover span .icon {
transform: rotate(180deg);
}
.header__list > li > .header-menu-trigger,
.header__list > li span {
position: relative;
display: flex;
align-items: center;
gap: 0.8rem;
}
.header__list > li > .header-menu-trigger .icon,
.header__list > li span .icon {
width: 1.2rem;
transition: transform var(--duration-default) linear;
}
.header__list > li > .header-menu-trigger::before,
.header__list > li span::before {
content: "";
position: absolute;
height: 20rem;
opacity: 0;
width: 400%;
left: 50%;
top: 100%;
display: none;
transform: translateX(-50%);
}
.header__list > li > a {
padding: 0 0.8rem;
}
.header__list li {
position: relative;
}
.header__list li:hover .header-menu-trigger, .header__list li:focus .header-menu-trigger, .header__list li:focus-within .header-menu-trigger {
z-index: 5;
}
.header__list li:hover > .header__submenu,
.header__list li:hover > .header__submenu-child, .header__list li:focus > .header__submenu,
.header__list li:focus > .header__submenu-child, .header__list li:focus-within > .header__submenu,
.header__list li:focus-within > .header__submenu-child {
opacity: 1;
pointer-events: unset;
visibility: visible;
}
.header__list li:hover > .header-mega-menu, .header__list li:focus > .header-mega-menu, .header__list li:focus-within > .header-mega-menu {
pointer-events: unset;
opacity: 1;
visibility: visible;
}
.header__submenu {
position: absolute;
left: 0;
top: calc(100% + 2.5rem);
background: rgb(var(--color-background));
border: 1px solid rgb(var(--color-border));
opacity: 0;
pointer-events: none;
visibility: hidden;
transition: all var(--duration-default) linear;
min-width: 23rem;
z-index: 5;
}
.header__submenu:not(.header__submenu-child) > li > a {
display: block;
padding: 0.8rem 3.2rem;
}
.header__submenu:not(.header__submenu-child) > li:first-child > a {
padding-top: 2.8rem;
}
.header__submenu:not(.header__submenu-child) > li:last-child > a {
padding-bottom: 2.8rem;
}
.header__submenu:not(.header__submenu-child) > li.have-submenu .header-menu-trigger,
.header__submenu:not(.header__submenu-child) > li.have-submenu span {
display: flex;
justify-content: space-between;
align-items: center;
cursor: default;
}
.header__submenu:not(.header__submenu-child) > li.have-submenu .header-menu-trigger .icon,
.header__submenu:not(.header__submenu-child) > li.have-submenu span .icon {
transform: rotate(-90deg);
width: 1.2rem;
}
.header__submenu-wrapper {
position: relative;
}
.header__submenu-child {
padding: 1.6rem 3.2rem;
left: 100%;
top: 0;
}
.header__submenu-child li {
padding: 0.8rem 0;
}
.header__submenu > li.have-submenu + li:not(.have-submenu):before,
.header__submenu > li:not(.have-submenu) + .have-submenu:before {
content: "";
background: rgb(var(--color-border));
height: 1px;
width: calc(100% - 6.4rem);
display: block;
position: absolute;
top: 0;
left: 50%;
transform: translateX(-50%);
}
.header__submenu li:hover > span,
.header__submenu li:hover > a {
opacity: 0.6;
}
.header__account {
align-items: center;
position: relative;
}
.header__account-modal {
position: absolute;
top: calc(100% + 1rem);
left: 50%;
display: flex;
flex-direction: column;
padding: 2.4rem;
width: 20rem;
font-weight: var(--font-body-weight);
text-transform: none;
background-color: rgba(var(--color-background));
transform: translateX(-50%);
transition: opacity var(--duration-default);
z-index: 10;
opacity: 0;
border: 1px solid rgb(var(--color-border));
}
details[open] > .header__account-modal {
opacity: 1;
}
.header__account__login {
width: 100%;
}
.header__account__login .icon {
margin-right: 1rem;
margin-left: 0;
width: 1.5rem;
height: 1.5rem;
}
.header__account__register {
display: inline;
margin-top: 1rem;
font-size: 1.5rem;
line-height: var(--font-body-line-height);
text-align: center;
text-decoration: none;
text-transform: uppercase;
}
.header__account__register span {
position: relative;
}
.header__account__register span::after {
content: "";
position: absolute;
bottom: -0.3rem;
left: 0;
right: 0;
width: 0;
height: 0.1rem;
background-color: rgb(var(--color-accent));
transition: width var(--duration-default);
}
.header__account__register:hover span::after {
width: 100%;
}
.header__account__name {
margin-bottom: 1.6rem;
padding-bottom: 1rem;
color: rgba(var(--color-foreground));
border-bottom: 0.1rem solid rgb(var(--color-accent));
}
.header__account__body {
margin: 0;
padding: 0;
list-style: none;
}
.header__account__body a {
display: block;
padding: 1rem 1.6rem;
font-size: 1.5rem;
text-decoration: none;
transition: var(--duration-default);
}
.header__account__body a:hover {
background-color: rgba(var(--color-accent), 0.1);
}
.header__account__logout {
margin-top: 1.5rem;
width: 100%;
}
.header__cart-wrapper {
padding-left: 1.6rem;
position: relative;
}
.header__cart-wrapper:before {
content: "";
position: absolute;
left: 0;
top: 50%;
transform: translateY(-50%);
height: 72px;
width: 1px;
background: rgb(var(--color-border));
}
@media screen and (min-width: 1860px) {
.header__cart-wrapper {
padding-left: 3.2rem;
}
}
.header__submenu a,
.header__submenu span, .header-mega-menu__have-submenu__list-submenu a,
.header-mega-menu__have-submenu__list-submenu span {
font-size: 14px;
font-family: var(--font-body-family);
font-style: var(--font-body-style);
font-weight: var(--font-body-weight);
text-transform: none;
}
.header__search a {
display: flex;
align-items: center;
gap: 0.8rem;
}
.header__heading {
margin: 0;
}
.header__heading .header__logo, .header__heading.header__logo {
display: block;
height: auto;
max-height: calc(var(--header-height) - 0.8rem);
object-fit: contain;
max-width: 150px;
}
.header__heading .header__logo img, .header__heading.header__logo img {
height: auto;
}
.header__heading .header__logo img.header__heading-logo--overlay, .header__heading.header__logo img.header__heading-logo--overlay {
display: none;
}
@media screen and (min-width: 750px) {
.header__heading .header__logo, .header__heading.header__logo {
max-width: unset;
}
}
.header-mega-menu {
position: fixed;
top: 100%;
left: 0;
width: 100%;
max-width: 100%;
padding: 1.6rem 0;
z-index: 5;
pointer-events: none;
opacity: 0;
visibility: hidden;
transition: all var(--duration-default) linear;
max-height: 52rem;
overflow: auto;
overflow-x: hidden;
}
.header-mega-menu:after {
content: "";
position: absolute;
bottom: 0;
left: 0;
width: 100%;
height: 1px;
background: rgb(var(--color-border));
z-index: -1;
}
.header-mega-menu::-webkit-scrollbar {
width: 3px;
height: 3px;
}
.header-mega-menu::-webkit-scrollbar-thumb {
background-color: rgba(var(--color-foreground), 0.7);
border-radius: 100px;
}
.header-mega-menu span {
cursor: default;
}
.header-mega-menu__card .product-parameters {
display: none;
}
.header-mega-menu__card .card-information {
position: absolute;
bottom: 2.4rem;
left: 2.4rem;
}
.header-mega-menu__collection-list {
display: grid;
grid-template-columns: repeat(3, 1fr);
gap: 3.2rem;
padding: 0;
}
.header-mega-menu__collection-list li {
position: relative;
}
.header-mega-menu__collection-list li:not(:last-child):not(:nth-child(3n)):before {
content: "";
position: absolute;
right: -1.6rem;
top: 0;
width: 1px;
height: 100%;
background: rgb(var(--color-border));
pointer-events: none;
}
.header-mega-menu__collection-list li img {
transition: all var(--duration-default) ease;
}
.header-mega-menu__collection-list li:hover img {
transform: scale(1.12);
}
.header-mega-menu__collection-list .collection-grid__image-block::before {
padding-bottom: 84% !important;
}
.header-mega-menu__wrapper {
display: flex;
justify-content: space-between;
gap: 4rem;
}
@media screen and (min-width: 1860px) {
.header-mega-menu__wrapper {
gap: 8rem;
}
}
.header-mega-menu__card {
position: relative;
max-width: 38rem;
width: 100%;
left: calc((100vw - 100% - 17px) / 2);
margin: -1.6rem 0;
}
.header-mega-menu__card.collection-product-card {
overflow: hidden;
margin: -3.2rem 0 0;
transform: translate(0.8rem, 1.6rem);
max-height: 515px;
}
.header-mega-menu__card.collection-product-card:before {
content: "";
position: absolute;
left: 0;
bottom: 0;
width: 100%;
height: 60%;
background: var(--overlay-gradient);
z-index: 3;
transition: all var(--duration-long) linear;
pointer-events: none;
}
.header-mega-menu__card.collection-product-card .price {
font-family: var(--font-body-family);
font-weight: 400;
}
.header-mega-menu__card.collection-product-card .price.price--on-sale .price-item--regular {
opacity: 0.6;
}
.header-mega-menu__card .collection-grid__image-item img {
transition: all var(--duration-default) ease;
}
.header-mega-menu__card:hover .collection-grid__image-item img {
transform: scale(1.12);
}
.header-mega-menu__card .media,
.header-mega-menu__card .card__inner,
.header-mega-menu__card .card--product,
.header-mega-menu__card .card-wrapper {
position: static;
}
.header-mega-menu__card .card-wrapper::before {
content: "";
position: absolute;
left: 0;
bottom: 0;
width: 100%;
height: 60%;
background: var(--overlay-gradient);
z-index: 1;
}
@media screen and (min-width: 1860px) {
.header-mega-menu__card {
max-width: 48rem;
}
}
.header-mega-menu .quick-add {
display: none;
}
.header-mega-menu .card-information .caption-with-letter-spacing {
opacity: 0.6;
}
.header-mega-menu__no-submenu__list {
display: flex;
flex-direction: column;
padding-top: 2.8rem;
padding-bottom: 2.8rem;
gap: 2.4rem;
}
@media screen and (min-width: 1860px) {
.header-mega-menu__no-submenu__list {
gap: 3.2rem;
}
}
.header-mega-menu__no-submenu__list:not(:empty) + .header-mega-menu__have-submenu__list {
border-left: 1px solid rgb(var(--color-border));
padding-left: 4rem;
}
@media screen and (min-width: 1860px) {
.header-mega-menu__no-submenu__list:not(:empty) + .header-mega-menu__have-submenu__list {
padding-left: 8rem;
}
}
.header-mega-menu__have-submenu__list {
display: flex;
flex: 1 1 auto;
padding-top: 2.8rem;
padding-bottom: 2.8rem;
gap: 3rem;
flex-wrap: wrap;
}
.header-mega-menu__have-submenu__list-submenu {
display: flex;
flex-direction: column;
gap: 0.8rem;
margin-top: 1.2rem;
}
@media screen and (min-width: 1860px) {
.header-mega-menu__have-submenu__list-submenu {
gap: 1.6rem;
margin-top: 2.4rem;
}
}
.header-mega-menu__have-submenu__list .view-all-link {
font-weight: 500;
}
@media screen and (min-width: 1860px) {
.header-mega-menu__have-submenu__list {
gap: 6rem;
}
}
@media (prefers-color-scheme: light) {
.header__toggle-scheme-item--dark {
display: flex;
}
}
.header__toggle-scheme-item--dark :root, [data-scheme=light] .header__toggle-scheme-item--dark {
display: flex;
}
@media (prefers-color-scheme: light) {
:root [data-scheme=auto] .header__toggle-scheme-item--dark {
display: flex;
}
}
@media (prefers-color-scheme: dark) {
.header__toggle-scheme-item--dark {
display: none;
}
}
@media (prefers-color-scheme: dark) {
:root [data-scheme=auto] .header__toggle-scheme-item--dark {
display: none;
}
}
[data-scheme=dark] .header__toggle-scheme-item--dark {
display: none;
}
@media (prefers-color-scheme: light) {
.header__toggle-scheme-item--light {
display: none;
}
}
.header__toggle-scheme-item--light :root, [data-scheme=light] .header__toggle-scheme-item--light {
display: none;
}
@media (prefers-color-scheme: light) {
:root [data-scheme=auto] .header__toggle-scheme-item--light {
display: none;
}
}
@media (prefers-color-scheme: dark) {
.header__toggle-scheme-item--light {
display: flex;
}
}
@media (prefers-color-scheme: dark) {
:root [data-scheme=auto] .header__toggle-scheme-item--light {
display: flex;
}
}
[data-scheme=dark] .header__toggle-scheme-item--light {
display: flex;
}
.header__toggle-scheme-item {
cursor: pointer;
}
.header__localization {
display: flex;
align-items: center;
gap: 2.4rem;
}
@media screen and (min-width: 1860px) {
.header__localization {
gap: 3.2rem;
}
}
.header__localization ul.disclosure__list {
top: calc(100% + 1.6rem);
right: 0;
bottom: auto;
margin-top: 0;
padding: 0.8rem;
min-width: auto;
max-width: 30rem;
max-height: 30rem;
background-color: rgba(var(--color-background));
box-shadow: none;
transition: opacity var(--duration-default) ease, transform var(--duration-default) ease;
animation: animateLocalOpen var(--duration-default) ease;
transform: translateY(0);
border: 1px solid rgb(var(--color-border));
}
.header__localization ul.disclosure__list .link {
color: rgb(var(--color-foreground-title));
}
.header__localization ul.disclosure__list .link:hover {
background-color: rgb(var(--color-orders-hover));
}
.header__localization li a {
padding: 1rem 1.6rem;
height: auto;
display: flex;
align-items: center;
transition: background var(--duration-default);
}
.header__localization .disclosure__link--flag:before {
content: "";
display: flex;
align-items: center;
justify-content: center;
position: relative;
width: 2rem;
height: 2rem;
flex: none;
overflow: hidden;
margin-right: 1rem;
background-size: cover;
background-position: center;
background-repeat: no-repeat;
border-radius: 50%;
border: 1px solid #c4c4c4;
}
.header__localization .localization-selector.link {
padding: 0;
background: transparent;
font-size: inherit;
font-family: inherit;
font-weight: inherit;
color: inherit;
display: flex;
align-items: center;
gap: 0.3rem;
text-decoration: none;
}
.header__localization .localization-selector.link * {
text-decoration: none;
}
.header__localization .localization-form__select:hover {
box-shadow: none;
}
.header__localization-button .icon {
vertical-align: middle;
}
.header .hover-opacity {
transition: all var(--duration-default) ease;
}
.header .hover-opacity:hover {
opacity: 0.6;
}
.header .header__toggle-scheme-item {
background: transparent;
border: 0;
}
.header-mobile-section {
position: sticky;
top: 0;
}
.header-mobile-section {
z-index: 100;
}
.header-mobile-section.hide .header-mobile {
transform: translateY(-150%);
}

.header-mobile {
height: 75px;
display: flex;
align-items: center;
padding: 1rem 0;
position: relative;
z-index: 1;
border-bottom: 1px solid rgb(var(--color-border));
transition: all var(--duration-default) linear;
}
@media screen and (min-width: 1200px) {
.header-mobile {
display: none;
}
}
.header-mobile .header-mobile__localization {
padding: 2.4rem;
padding-top: 0;
display: flex;
flex-direction: column;
gap: 1.6rem;
}
.header-mobile .header-mobile__localization .localization-selector.link {
gap: 0.5rem;
background: rgb(var(--color-background));
color: rgb(var(--color-foreground));
font-family: var(--font-heading-family);
font-weight: var(--font-heading-weight);
padding: 0;
box-shadow: none;
}
.header-mobile .header-mobile__localization .localization-selector.link .icon {
width: 2rem;
margin-left: 0;
margin-right: auto;
}
.header-mobile .header-mobile__localization .localization-selector + .disclosure__list {
background: rgb(var(--color-background));
color: rgb(var(--color-foreground));
box-shadow: none;
position: static;
transform: unset;
margin-top: 1.6rem;
margin-bottom: 1.6rem;
animation: none;
padding: 0;
}
.header-mobile .header-mobile__localization .localization-selector + .disclosure__list a {
color: inherit;
}
.header-mobile .header-mobile__localization .disclosure__button {
font-size: calc(var(--font-aside-menu-link-size) * 1.2);
}
.header-mobile .header-mobile__localization noscript:first-child + localization-form {
border-top: 0.1rem solid rgb(var(--color-border));
padding-top: 2.4rem;
}
.header-mobile__logo__wrapper img,
.header-mobile__logo__wrapper span {
z-index: -1;
position: relative;
}
.header-mobile__header {
display: grid;
align-items: center;
gap: 1.5rem;
padding: 0 2rem;
width: 100%;
grid-template-columns: 1fr auto 1fr;
}
.header-mobile__header.header-mobile-position-left {
grid-template-columns: auto 1fr 1fr;
}
.header-mobile__right, .header-mobile__left {
display: flex;
align-items: center;
gap: 1.5rem;
mix-blend-mode: difference;
}
.header-mobile__right *, .header-mobile__left *,
.header-mobile .animate-bg * {
color: rgb(255, 255, 255);
}
.header-mobile__right {
justify-content: flex-end;
}
.header-mobile__btn {
width: 2.4rem;
height: 2.4rem;
max-width: 2.4rem;
min-width: 2.4rem;
display: flex;
align-items: center;
}
.header-mobile__heading, .header-mobile__logo {
margin: 0 auto;
display: block;
object-fit: contain;
}
.header-mobile__heading img, .header-mobile__logo img {
height: auto;
max-width: 100%;
max-height: 100%;
object-fit: contain;
}
.header-mobile__heading img.header__heading-logo--overlay, .header-mobile__logo img.header__heading-logo--overlay {
display: none;
}
@media screen and (min-width: 750px) {
.header-mobile__heading, .header-mobile__logo {
max-width: unset;
}
}
.header-mobile__logo {
display: flex;
}
.header-mobile__cart {
position: relative;
}
.header-mobile__cart-count-bubble {
position: absolute;
top: 0;
right: 0;
display: flex;
justify-content: center;
align-items: center;
height: 1.6rem;
width: 1.6rem;
font-size: 1rem;
line-height: 1;
background-color: currentColor;
border-radius: 50%;
transform: translate(25%, -25%);
}
.header-mobile__cart-count-bubble * {
mix-blend-mode: difference;
}
.header-mobile__burger:not(.active) .icon-close {
display: none;
}
.header-mobile__burger.active .icon-hamburger {
display: none;
}
.header-mobile__menu,
.header-mobile .animate-bg {
position: absolute;
left: 0;
width: 100%;
transition: all var(--duration-long) linear;
transform: translateX(-150%);
}
.header-mobile__menu.active,
.header-mobile .animate-bg.active {
transform: translateX(0);
}
.header-mobile .animate-bg {
top: 0;
height: 75px;
pointer-events: none;
z-index: -1;
display: flex;
align-items: center;
justify-content: center;
}
.header-mobile .animate-bg.active .show-animate {
animation: show 0.4s linear forwards;
animation-delay: 0.2s;
}

.header-mobile .animate-bg .show-animate {
opacity: 0;
}
.header-mobile__menu {
height: calc(100vh - 70px - 40px + 0.1rem);
overflow: auto;
top: 100%;
}
.header-mobile__menu-header {
display: flex;
align-items: center;
border-top: 1px solid rgb(var(--color-border));
}
.header-mobile__menu-header li {
flex: 1 0 33.33%;
}
.header-mobile__menu-header li:not(:last-child) {
border-right: 1px solid rgb(var(--color-border));
}
.header-mobile__menu-header li a {
padding: 2rem 1rem;
text-align: center;
font-size: var(--font-aside-menu-link-size);
transition: opacity var(--duration-default) ease;
}
.header-mobile__menu-header li:not(.active) a {
opacity: 0.6;
}
.header-mobile__menus {
border-top: 1px solid rgb(var(--color-border));
}
.header-mobile__menus > li {
display: none;
padding: 2.4rem;
}
.header-mobile__menu-item {
display: flex;
flex-direction: column;
gap: 3.2rem;
}
.header-mobile__menu-item li {
position: relative;
}
.header-mobile__menu-item li:not(:last-child)::before {
content: "";
position: absolute;
bottom: -1.6rem;
width: 100%;
height: 1px;
background: rgb(var(--color-border));
}
.header-mobile__menu-main {
display: flex;
flex-direction: column;
gap: 1.6rem;
color: rgb(var(--color-foreground-title));
}
.header-mobile__menu-main > li.have-submenu details summary span {
display: flex;
justify-content: space-between;
}
.header-mobile__menu-main > li.have-submenu details summary .icon {
width: 2rem;
transition: all var(--duration-default) linear;
}
.header-mobile__menu-main > li.have-submenu details ul {
display: none;
}
.header-mobile__menu-main > li.have-submenu details[open] > summary .icon {
transform: rotate(180deg);
}
.header-mobile__menu-main > li.have-submenu details[open] ul {
display: flex;
}
.header-mobile__menu-main .h6 {
font-size: calc(var(--font-aside-menu-link-size) * 1.2);
}
.header-mobile__menu-main li.have-submenu + li:not(.have-submenu),
.header-mobile__menu-main li:not(.have-submenu) + li.have-submenu {
padding-top: 1.6rem;
border-top: 1px solid rgb(var(--color-border));
}
.header-mobile__submenu {
padding: 1.6rem 0;
flex-direction: column;
gap: 1.2rem;
}
.header-mobile__submenu li.have-submenu + li:not(.have-submenu),
.header-mobile__submenu li:not(.have-submenu) + li.have-submenu {
padding-top: 1.2rem;
}
.header-mobile__submenu-child {
padding: 1.6rem 1.6rem 0 1.6rem;
}
.header-mobile .view-all-link {
font-weight: 500;
}
.header-mobile.header-scroll .header-mobile__menu {
height: calc(100vh - var(--header-mobile-height) + 0.1rem);
}
@keyframes show {
0% {
opacity: 0;
}
50% {
opacity: 0;
}
100% {
opacity: 1;
}
}

.no-js #header-mobile-menu:target .animate-bg,
.no-js #header-mobile-menu:target .header-mobile__menu {
transform: translateX(0);
}
.no-js #header-mobile-menu:target .header-mobile__menu {
height: calc(100vh - var(--header-mobile-height));
}
.no-js #header-mobile-menu:target .animate-bg .show-animate {
animation: show 0.4s linear forwards;
animation-delay: 0.2s;
}
.no-js #header-mobile-menu:target .header-mobile__no-js-close {
display: block;
}
.no-js #header-mobile-menu:target .header-mobile__burger {
display: none;
}
.no-js .header-mobile__no-js-close {
display: none;
}

/* Breadcrumb  */
.breadcrumb {
position: relative;
z-index: 1;
display: flex;
align-items: center;
flex-wrap: wrap;
row-gap: 1rem;
margin: 3.6rem 0 0 0;
font-size: 1.4rem !important;
line-height: 150%;
color: rgb(var(--color-foreground-sub-title));
}
@media (min-width: 576px) {
.breadcrumb {
margin: 4.8rem 0 0 0;
}
}
.breadcrumb__delimiter {
margin: 0 1rem;
}
@media (min-width: 576px) {
.breadcrumb__delimiter {
margin: 0 1.3rem;
}
}
.breadcrumb--center {
justify-content: center;
}
.breadcrumb a {
color: inherit;
text-decoration: none;
}
.breadcrumb .link-hover-line::after {
background-color: rgb(var(--color-border));
}
.breadcrumb span {
color: rgb(var(--color-foreground-sub-title), 0.6);
}

.have-overlay {
position: relative;
z-index: 1;
line-height: 0px;
}
/*==.have-overlay::before {
content: "";
position: absolute;
width: 100%;
height: 100%;
top: 0;
left: 0;
z-index: -1;
pointer-events: none;
transition: all 0.4s ease;
background: rgba(var(--color-overlay), 0.4);
}==*/

.section_border_top {
border-top: 1px solid rgb(var(--color-border));
}

.section_border_bottom {
border-bottom: 1px solid rgb(var(--color-border));
}

.section-header__line {
padding: 3rem 0 0;
margin-bottom: 1.5rem;
}
@media screen and (min-width: 576px) {
.section-header__line {
padding: 5rem 0 0;
margin-bottom: 2.5rem;
}
}
@media screen and (min-width: 1200px) {
.section-header__line {
padding: 8rem 0 0;
margin-bottom: 4rem;
}
}
.section-header__line .button--simple {
margin-right: auto;
}
.section-header__line .section-header__title__block {
display: flex;
flex-direction: column;
gap: 2rem;
}
@media screen and (min-width: 990px) {
.section-header__line .section-header__title__block {
flex-direction: row;
justify-content: space-between;
align-items: center;
}
.section-header__line .section-header__title__block .button--simple {
margin-right: unset;
}
}
.section-header__line .section-header__title {
margin: 0;
}

/* Popup */
.popup-scroll-hidden {
overflow: hidden;
width: 100%;
}

.popup-main .popup-thumbnail img {
position: absolute;
width: 100%;
height: 100%;
}
.popup-main .popup-thumbnail {
position: relative;
align-self: normal;
}
.popup-main .popup-outer {
min-height: 445px;
align-items: center;
}
.popup-main .popup-content {
padding: 30px;
}
@media screen and (min-width: 990px) {
.popup-main .popup-content {
padding: 80px;
}
}
.popup-main .popup-container {
background-color: var(--container-bg, #ffffff);
}
.popup-main .popup-heading {
color: var(--heading-color, #232322);
}
.popup-main .popup-text {
color: var(--text-color, #454544);
}
.popup-main .popup-close {
color: var(--icon-color, #232322);
}
.popup-main .popup-close:hover {
color: var(--icon-color-hover, #8F8F8E);
}

.notification-banner .popup-container {
background-color: var(--container-bg, #ffffff);
}
.notification-banner .popup-heading {
color: var(--heading-color, #232322);
}
.notification-banner .popup-text {
color: var(--text-color, #454544);
}
.notification-banner .popup-close {
color: var(--icon-color, #232322);
}
.notification-banner .popup-close:hover {
color: var(--icon-color-hover, #8F8F8E);
}

.popup-animated {
animation-duration: 0.5s;
animation-fill-mode: both;
overflow: hidden !important;
}

@keyframes popupOpenFade {
from {
opacity: 0;
}
to {
opacity: 1;
}
}
@keyframes popupExitFade {
from {
opacity: 1;
}
to {
opacity: 0;
}
}
@keyframes popupOpenSlide {
from {
transform: translate3d(0, 100vh, 0);
visibility: visible;
}
to {
transform: translate3d(0, 0, 0);
}
}
@keyframes popupExitSlide {
from {
transform: translate3d(0, 0, 0);
}
to {
visibility: hidden;
transform: translate3d(0, 100vh, 0);
}
}
@keyframes popupOpenZoom {
from {
opacity: 0;
transform: scale(1.1);
}
to {
opacity: 1;
transform: scale(1);
}
}
@keyframes popupExitZoom {
from {
opacity: 1;
transform: scale(1);
}
to {
opacity: 0;
transform: scale(1.1);
}
}
@keyframes popupOpenSlideFade {
from {
opacity: 0;
transform: translate3d(0, 4rem, 0);
visibility: visible;
}
to {
opacity: 1;
transform: translate3d(0, 0, 0);
}
}
@keyframes popupExitSlideFade {
from {
opacity: 1;
transform: translate3d(0, 0, 0);
}
to {
opacity: 0;
visibility: hidden;
transform: translate3d(0, 4rem, 0);
}
}
.popupOpenFade {
animation-name: popupOpenFade;
}

.popupExitFade {
animation-name: popupExitFade;
}

.popupOpenSlide {
animation-name: popupOpenSlide;
}

.popupExitSlide {
animation-name: popupExitSlide;
}

.popupOpenZoom {
animation-name: popupOpenZoom;
}

.popupExitZoom {
animation-name: popupExitZoom;
}

.popupOpenSlideFade {
animation-name: popupOpenSlideFade;
}

.popupExitSlideFade {
animation-name: popupExitSlideFade;
}

.popup {
display: none;
position: fixed;
z-index: 999999;
max-width: calc(100vw - 4rem);
max-height: calc(100vh - 4rem);
-webkit-backface-visibility: hidden;
}
.popup--top {
top: 20px;
left: 50%;
transform: translate3d(-50%, 0, 0);
}
.popup--top-left {
top: 20px;
left: 20px;
}
.popup--top-right {
top: 20px;
right: 20px;
}
.popup--bottom {
bottom: 20px;
left: 50%;
transform: translate3d(-50%, 0, 0);
}
.popup--bottom-left {
left: 20px;
bottom: 20px;
}
.popup--bottom-right {
right: 20px;
bottom: 20px;
}
.popup--left {
top: 50%;
left: 20px;
transform: translate3d(0, -50%, 0);
}
.popup--right {
top: 50%;
right: 20px;
transform: translate3d(0, -50%, 0);
}
.popup--center {
top: 50%;
left: 50%;
transform: translate3d(-50%, -50%, 0);
}
.popup-wrap {
position: relative;
overflow-x: hidden;
overflow-y: auto;
width: 100%;
box-shadow: 0 0 4rem 0 rgba(0, 0, 0, 0.075);
}
.popup-wrap::-webkit-scrollbar {
width: 1rem;
}
.popup-wrap::-webkit-scrollbar-track {
background: #f1f1f1;
}
.popup-wrap::-webkit-scrollbar-thumb {
background: rgb(var(--color-accent));
}
.popup-container {
width: 100%;
background: #fff;
overflow: hidden;
}
.popup-outer {
position: relative;
display: flex;
flex-direction: column;
}
.popup-text {
color: rgb(var(--color-foreground-secondary));
}
.popup-text a {
text-decoration: underline;
transition: color var(--duration-default);
}
.popup-text p {
margin: 0;
}
.popup .popup-close {
position: absolute;
top: 20px;
right: 20px;
z-index: 2;
}
.popup-open {
display: flex;
}
@media (max-width: 576px) {
.popup-open[data-mobile-disable=true] {
display: none;
}
.popup-open[data-mobile-disable=true] + .popup-overlay {
display: none;
}
}
.popup-open + .popup-overlay {
display: block;
}
.popup-overlay {
background-color: rgba(var(--color-overlay), 0.3);
position: fixed;
display: none;
top: 0;
bottom: 0;
right: 0;
left: 0;
width: 100%;
height: 100%;
z-index: 999998;
}
.popup-overlay .popup-wrap {
box-shadow: none;
}
.popup-thumbnail img {
width: 100%;
}

@media (max-width: 750px) {
.popup-main {
max-width: calc(100vw - 4rem);
max-height: calc(100vh - 4rem);
}
}
.popup-main .popup-heading {
margin-top: 0;
margin-bottom: 1.6rem;
font-size: clamp(20px, var(--font-popup-heading-size) * 0.7, var(--font-popup-heading-size));
}
@media screen and (min-width: 750px) {
.popup-main .popup-heading {
font-size: var(--font-popup-heading-size);
}
}
.popup-main .popup-text {
margin-bottom: 3.2rem;
font-size: var(--font-popup-text-size);
line-height: 1.2;
}
@media (min-width: 576px) {
.popup-main .popup-outer {
flex-direction: row;
}
}
.popup-main .popup-thumbnail {
flex: 1 0 15%;
display: flex;
min-height: 340px;
}
@media screen and (min-width: 750px) {
.popup-main .popup-thumbnail {
min-height: unset;
}
}
.popup-main .popup-thumbnail img {
width: 100%;
height: 100%;
object-fit: cover;
}
@media screen and (max-width: 750px) {
.popup-main .popup-thumbnail img {
padding: 0 !important;
object-position: top;
}
}
.popup-main .popup-content {
flex: 1 0 50%;
order: 2;
}
.popup-main .popup-content:first-child:last-child {
flex: 1 0 100%;
}
@media (min-width: 576px) {
.popup-main-image--right .popup-outer {
flex-direction: row-reverse;
}
}
.popup-main-image--top .popup-outer {
flex-direction: column;
}
.popup-main-image--top .popup-thumbnail {
position: relative;
padding-bottom: 55%;
}
.popup-main-image--top .popup-thumbnail img {
position: absolute;
inset: 0;
width: 100%;
height: 100%;
object-fit: cover;
}
.popup-main-image--bottom .popup-outer {
flex-direction: column-reverse;
}
.popup-main-image--bottom .popup-thumbnail {
position: relative;
padding-bottom: 55%;
}
.popup-main-image--bottom .popup-thumbnail img {
position: absolute;
inset: 0;
width: 100%;
height: 100%;
object-fit: cover;
}
.popup-main .newsletter-form .field,
.popup-main .newsletter-form .button {
min-height: 5.8rem;
}
.popup-main .newsletter-form .button {
max-width: 100%;
}
.popup-main .newsletter-form__inner {
flex-direction: column;
justify-content: flex-start;
align-items: stretch;
gap: 0;
}
@media screen and (min-width: 576px) {
.popup-main .newsletter-form__inner:not(.newsletter-form__inner_column) {
flex-direction: row;
}
}
.popup-main .list-social {
flex-direction: row;
padding: 2rem;
justify-content: center;
}
.popup-main .list-social__item .icon {
transition: none;
}
.popup-main .list-social__item:hover .icon {
transform: rotateY(360deg);
transition: all var(--duration-long) linear;
}

.notification-banner {
cursor: pointer;
}
.notification-banner .popup-outer {
padding: 30px;
}
.notification-banner .popup-heading {
margin: 0;
font-family: var(--font-popups-heading-family);
font-style: var(--font-popups-heading-style);
font-weight: var(--font-popups-heading-weight);
font-size: var(--font-notification-heading-size);
}
.notification-banner .popup-heading + .popup-text {
margin-top: 0.8rem;
}
.notification-banner .popup-text {
padding-right: 1rem;
font-family: var(--font-popups-text-family);
font-style: var(--font-popups-text-style);
font-weight: var(--font-popups-text-weight);
font-size: var(--font-notification-text-size);
}
.notification-banner .popup-button {
width: 100%;
margin-top: 1.6rem;
}
.notification-banner--box .popup-close {
top: 15px;
right: 15px;
}
.notification-banner--bar.popup--top {
width: 100%;
max-width: 100%;
top: 0;
left: 0;
bottom: auto;
transform: none;
}
.notification-banner--bar.popup--bottom {
width: 100%;
max-width: 100%;
top: auto;
left: 0;
bottom: 0;
transform: none;
}
.notification-banner--bar .popup-outer {
padding-top: 20px;
padding-bottom: 20px;
padding-left: 60px;
padding-right: 60px;
}
@media (min-width: 750px) {
.notification-banner--bar .popup-outer {
justify-content: center;
align-items: center;
flex-direction: row;
flex-wrap: wrap;
gap: 1.6rem 2.4rem;
margin: 0 auto;
padding-left: 40px;
padding-right: 40px;
}
.notification-banner--bar .button {
margin-top: 0;
width: auto;
}
}
.notification-banner--banner .popup-outer {
flex-direction: row;
align-items: center;
flex-wrap: wrap;
padding: 0;
}
.notification-banner--banner .popup-outer-image--right {
flex-direction: row-reverse;
}
.notification-banner--banner .popup-outer-image--top {
flex-direction: column;
}
.notification-banner--banner .popup-outer-image--bottom {
flex-direction: column-reverse;
}
.notification-banner--banner .popup-outer-image--top .notification-banner__image, .notification-banner--banner .popup-outer-image--bottom .notification-banner__image {
position: relative;
padding-bottom: 55%;
}
.notification-banner--banner .popup-outer-image--top .notification-banner__image img, .notification-banner--banner .popup-outer-image--bottom .notification-banner__image img {
position: absolute;
inset: 0;
}
.notification-banner--banner .notification-banner__image {
flex: 1 35%;
min-width: 7.6rem;
align-self: stretch;
display: flex;
overflow: hidden;
}
.notification-banner--banner .notification-banner__image img {
width: 100%;
height: 100%;
object-fit: cover;
}
.notification-banner--banner .notification-banner__content {
flex: 1 65%;
padding: 2.4rem 2rem;
}
.notification-banner--banner .popup-close {
top: 1rem;
right: 1rem;
}

.age-verification {
position: fixed;
inset: 0;
-webkit-backface-visibility: hidden;
width: 100vw;
height: 100vh;
max-width: unset;
max-height: unset;
padding: 2.4rem;
background-color: rgb(var(--color-background));
}
@media screen and (min-width: 750px) {
.age-verification {
padding: 6.4rem;
}
}
.age-verification .popup-text p {
text-align: center;
}
.age-verification__wrapper {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 100%;
box-shadow: none;
}
.age-verification__content {
display: flex;
flex-direction: column;
align-items: center;
}
.age-verification__question {
display: none;
}
.age-verification__question.show {
display: block;
}
.age-verification__heading {
margin-top: 0;
margin-bottom: 1.6rem;
text-align: center;
}
.age-verification__buttons {
display: flex;
flex-direction: column;
justify-content: center;
gap: 0.8rem;
margin-top: 2.4rem;
}
@media screen and (min-width: 576px) {
.age-verification__buttons {
flex-direction: row;
}
}
.age-verification__buttons .button {
min-width: 12rem;
}
.age-verification__declined {
display: none;
}
.age-verification__declined.show {
display: block;
}

[data-scheme=dark] .notification-banner,
[data-scheme=dark] .popup-main {
--container-bg: rgba(23, 23, 23, 1);
--heading-color: rgb(var(--color-foreground));
--text-color: rgb(var(--color-foreground));
--icon-color: rgb(var(--color-foreground));
--icon-color-hover: rgb(var(--color-foreground));
}
[data-scheme=dark] .notification-banner .list-social__item .icon,
[data-scheme=dark] .popup-main .list-social__item .icon {
color: rgb(var(--color-social));
}
[data-scheme=dark] .notification-banner .list-social__item:hover .icon,
[data-scheme=dark] .popup-main .list-social__item:hover .icon {
color: rgb(var(--color-accent));
}

@media (prefers-color-scheme: dark) {
[data-scheme=auto] .notification-banner,
[data-scheme=auto] .popup-main {
--container-bg: rgba(23, 23, 23, 1);
--heading-color: rgb(var(--color-foreground));
--text-color: rgb(var(--color-foreground));
--icon-color: rgb(var(--color-foreground));
--icon-color-hover: rgb(var(--color-foreground));
}
[data-scheme=auto] .notification-banner .list-social__item .icon,
[data-scheme=auto] .popup-main .list-social__item .icon {
color: rgb(var(--color-social));
}
[data-scheme=auto] .notification-banner .list-social__item:hover .icon,
[data-scheme=auto] .popup-main .list-social__item:hover .icon {
color: rgb(var(--color-accent));
}
}
/*============================================================================*/
html {
box-sizing: border-box;
font-size: 10px;
height: 100%;
}
body {
font-size: var(--font-body-size) !important;
line-height: 1.5;
font-family: var(--font-body-family) !important;
font-style: var(--font-body-style);
font-weight: var(--font-body-weight);
}
/*============================================================================*/
.sectionmedium{
padding: 60px 0px;
}
.sectionlarge{
padding: 80px 0px;
}
.breadcrumb {
justify-content : center;
}
/*============================================================================*/
.topheader{
background-color: rgb(var(--color-announcement-bar-background-1));
display: flex;
justify-content: center;
flex-direction: column;
text-transform: uppercase;
height: 40px;
}
.announcement{
text-transform: capitalize;
font-family: var(--font-heading-family);
font-style: var(--font-heading-style);
font-weight: var(--font-heading-weight);
font-size: 1.8rem;
display: flex;
align-items: center;
justify-content: center;
flex-wrap: wrap;
gap: 0.8rem;
text-align: center;
}
.header {
min-height: 75px;
}
.header__wrapper {
display: grid;
grid-template-columns: 1fr auto 1fr;
gap: 3rem;
align-items: center;
}
/*============================================================================*/
.login-section{
padding-top: 20px;
padding-bottom: 80px;
}
.customer {
overflow: hidden;
}
.customer .account__wrapper {
display: block;
}
@media (min-width: 1200px) {
.customer .account__wrapper {
display: flex;
}
}
.customer .account__wrapper .account__navigation {
max-width: unset;
width: 100%;
position: relative;
padding-bottom: 1.6rem;
margin-bottom: 2.4rem;
}
@media (min-width: 1200px) {
.customer .account__wrapper .account__navigation {
max-width: 30.5rem;
padding-bottom: 0;
margin-bottom: 0;
}
}
.customer .account__wrapper .account__navigation .account__navigation-wrapper {
display: flex;
justify-content: space-between;
}
@media (min-width: 1200px) {
.customer .account__wrapper .account__navigation .account__navigation-wrapper {
display: block;
}
}
.customer .account__wrapper .account__navigation::after {
content: "";
position: absolute;
bottom: 0;
background-color: rgb(var(--color-border));
height: 1px;
width: 300vw;
right: -100vw;
transform: none;
}
@media (min-width: 1200px) {
.customer .account__wrapper .account__navigation::after {
width: 1px;
height: 100%;
right: 0;
transform: translate(-1.6rem);
}
}
.customer .account__wrapper .account__navigation-list {
padding: 0;
display: flex;
gap: 1.6rem;
flex-direction: row;
margin: 0;
}
@media (min-width: 1200px) {
.customer .account__wrapper .account__navigation-list {
margin-bottom: 8rem;
flex-direction: column;
}
}
.customer .account__wrapper .account__navigation-list li a {
color: rgb(var(--color-foreground-title));
opacity: 0.6;
text-decoration: none;
transition: all var(--duration-default) ease;
}
.customer .account__wrapper .account__navigation-list li a.active, .customer .account__wrapper .account__navigation-list li a:hover {
opacity: 1;
}
.customer .account__wrapper .account-block {
width: 100%;
max-width: 1190px;
}
.customer .account__wrapper .account-block__header {
padding: 0 0 3.2rem;
}
@media (min-width: 1200px) {
.customer .account__wrapper .account-block__header {
padding: 0 0 3.2rem 4.8rem;
}
}
.customer .account__wrapper .account-block__header h2 {
margin: 0;
}
.customer .account__wrapper .account-block .account-item {
position: relative;
padding: 3.2rem 0;
}
@media (min-width: 1200px) {
.customer .account__wrapper .account-block .account-item {
padding: 3.2rem 0 3.2rem 4.8rem;
}
}
.customer .account__wrapper .account-block .account-item::before {
content: "";
position: absolute;
top: 0;
left: -100vw;
width: 300vw;
height: 1px;
background-color: rgb(var(--color-border));
}
@media (min-width: 1200px) {
.customer .account__wrapper .account-block .account-item::before {
left: 0;
width: 100%;
}
}
.customer .account__wrapper .account-block .account-item.address-list__item-wrapper--default::before {
height: 0;
}
.customer .account__wrapper .account-block .account-item__title {
margin: 0 0 2.4rem;
}
.customer .account__wrapper .account-block .account-item__title-box {
display: flex;
align-items: center;
justify-content: space-between;
}
.customer .account__wrapper .account-block .account-item__title-box h1,
.customer .account__wrapper .account-block .account-item__title-box h2,
.customer .account__wrapper .account-block .account-item__title-box h3,
.customer .account__wrapper .account-block .account-item__title-box h4,
.customer .account__wrapper .account-block .account-item__title-box h5,
.customer .account__wrapper .account-block .account-item__title-box h6 {
margin: 0;
}
.customer .account__wrapper .address-list__wrapper {
display: flex;
align-items: flex-start;
justify-content: space-between;
}
.customer .customer-address__list.addresses-address__list {
display: flex;
flex-wrap: wrap;
max-width: 25rem;
}
.customer .addresses-address__list-item:not(:last-child)::after {
content: ",";
position: absolute;
bottom: 0;
right: -5px;
}
.customer .customer-address__item.addresses-address__list-item {
position: relative;
margin-right: 10px;
}
.customer .account-item__title-box-border {
position: relative;
}
.customer .account-item__title-box-border::before {
content: "";
position: absolute;
bottom: 0;
left: -100vw;
width: 300vw;
height: 1px;
background-color: rgb(var(--color-border));
}
@media (min-width: 1200px) {
.customer .account-item__title-box-border::before {
left: 0;
width: 100%;
}
}
.customer--margin-unset {
margin: 0;
}
.customer--height-unset {
min-height: unset;
}
.customer .address-buttons-block {
padding-top: 0.8rem;
}
.customer .total-price__fw {
font-weight: 500;
}
.customer .btn-remove {
margin-left: auto;
}

.customer .field input[aria-invalid=true] {
border-color: rgba(var(--color-error), 0.4);
}

.customer form {
margin-top: 2rem;
}
@media screen and (min-width: 990px) {
.customer form {
margin-top: 4rem;
}
}

.customer button {
margin: 4rem 0 1.5rem;
}

.customer ul {
line-height: 1.6;
padding-left: 4.4rem;
text-align: left;
margin-bottom: 4rem;
}

.customer ul a {
display: inline;
}

.customer strong {
font-weight: normal;
color: rgb(var(--color-foreground));
}

.customer .field {
margin: 2.5rem 0 0 0;
pointer-events: auto;
}

.customer .field__wrapper {
display: flex;
margin-top: 2.5rem;
}

.customer .field__wrapper .field {
margin: 0;
}

.customer .field:first-of-type {
margin-top: 0;
}

.customer .field {
margin-top: 2.5rem;
}

.customer__buttons {
display: flex;
align-items: center;
margin-top: 3rem;
}

.customer__buttons.center {
justify-content: center;
}

.customer__buttons.center > * {
width: 14rem;
}

.customer button {
margin: 0;
}

.form__errors {
padding: 2.4rem;
background-color: rgba(var(--color-error), 0.05);
}

.customer .form__message {
font-size: 1.4rem;
color: rgba(var(--color-error));
margin: 0 0 1rem 0;
font-weight: var(--font-body-weight);
}

.customer span.form__message {
display: flex;
font-size: 1.2rem;
margin: 1rem 0 0 0;
}

.customer span.form__message:before {
content: "*";
color: rgba(var(--color-error));
margin-right: 0.2rem;
}

.customer .form__message svg {
width: 2.6rem;
height: 2.6rem;
margin-right: 1rem;
}

.customer .errors {
margin-bottom: 24px;
}

.customer .errors ul {
display: flex;
flex-direction: column;
list-style: none;
padding-left: 0;
margin: 0.8rem 0 0;
}

.customer .errors ul li {
display: inline-flex;
font-size: 1.2rem;
line-height: 1;
color: rgba(var(--color-error));
position: relative;
}

.customer .errors ul li:before {
content: "*";
color: rgba(var(--color-error));
margin-right: 0.2rem;
}

.form__message:focus-visible {
outline: none;
box-shadow: none;
}

/* Customer Table */
.customer .order-table {
width: 100%;
}
.customer .order-table .row {
display: flex;
flex-direction: column;
width: 100%;
height: auto;
}
@media (min-width: 1200px) {
.customer .order-table .row {
flex-direction: row;
align-items: center;
min-height: 5.5rem;
}
}
.customer .order-table .row > * {
display: block;
padding: 0 2rem 0 0;
}
.customer .order-table .row > *::before {
content: attr(data-label);
display: inline-block;
max-width: 10rem;
width: 100%;
}
@media (min-width: 1200px) {
.customer .order-table .row > *::before {
display: none;
}
}
@media (min-width: 1200px) {
.customer .order-table .row > *:first-child {
width: 19.64%;
}
}
@media (min-width: 1200px) {
.customer .order-table .row > *:nth-child(2) {
width: 29.9%;
}
}
@media (min-width: 1200px) {
.customer .order-table .row > *:nth-child(3) {
width: 20.91%;
}
}
@media (min-width: 1200px) {
.customer .order-table .row > *:nth-child(4) {
width: 23.48%;
}
}
@media (min-width: 1200px) {
.customer .order-table .row > *:last-child {
width: 17.05%;
}
}
.customer .order-table .thead {
display: none;
}
@media (min-width: 1200px) {
.customer .order-table .thead {
display: flex;
min-height: unset;
margin-bottom: 1.6rem;
}
}
.customer .order-table .thead > * {
font-size: 1.4rem;
font-weight: 500;
color: rgb(var(--color-foreground-secondary));
position: relative;
}
.customer .order-table .tbody {
width: 100%;
border-top: 0.1rem solid rgb(var(--color-border));
border-bottom: 0.1rem solid rgb(var(--color-border));
text-decoration: none;
padding: 1.5rem 0;
}
.customer .order-table .tbody + .tbody {
margin-top: -1px;
}
.customer .order-table .tbody:not(div):hover {
background-color: rgb(var(--color-orders-hover));
position: relative;
}
.customer .order-table .tbody > * {
display: flex;
align-items: center;
padding: 1.3rem 0;
box-sizing: border-box;
color: rgb(var(--color-foreground-title));
}
@media (min-width: 1200px) {
.customer .order-table .tbody > * {
align-items: flex-start;
padding: 0;
}
}
.customer .order-table .tbody.tbody__none-border {
border: none;
}
.customer .order-table .tfoot {
display: block;
padding: 1.6rem 2.4rem;
background-color: rgb(var(--color-base-background-2));
margin-top: 3.2rem;
}
.customer .order-table .tfoot .customer-address__list.addresses-address__list {
justify-content: flex-end;
}
.customer .order-table .tfoot .customer-address__list.addresses-address__list .customer-address__item,
.customer .order-table .tfoot .customer-address__list.addresses-address__list .customer-address__item > span {
margin-bottom: 0;
font-weight: 400;
font-size: 1.6rem;
color: rgb(92, 92, 91);
text-transform: none;
}
.customer .order-table .tfoot .order__value-unstayled span:not(:first-child) {
font-weight: 400;
font-size: 1.6rem;
color: rgb(92, 92, 91);
text-transform: none;
}
.customer .order-table .tfoot > div {
display: flex;
width: 100% !important;
padding: 0;
margin-bottom: 2.4rem;
font-weight: 600;
text-transform: uppercase;
color: rgba(var(--color-foreground-title));
justify-content: space-between;
align-items: flex-start;
}
.customer .order-table .tfoot > div::before {
display: none;
}
.customer .order-table .tfoot > div.total-price {
border-bottom: 0.1rem solid rgb(var(--color-border));
padding-bottom: 3.2rem;
margin-bottom: 3.2rem;
}
.customer .order-table .tfoot > div span {
font-size: 1.4rem;
}
.customer .order-table .tfoot > div span:first-child {
margin-right: 0;
width: auto;
}
.customer .order-table--details {
border-bottom: 0;
border-top: 0;
}
@media (min-width: 1200px) {
.customer .order-table--details .row > *:first-child {
width: 38.596%;
}
}
.customer .order-table--details .row > *:first-child a,
.customer .order-table--details .row > *:first-child p {
text-decoration: none;
}
.customer .order-table--details .row > *:first-child .properties-block {
line-height: 1.7rem;
}
.customer .order-table--details .row > *:first-child .properties-block .properties {
display: inline-block;
padding-top: 0.8rem;
}
@media (min-width: 1200px) {
.customer .order-table--details .row > *:nth-child(2) {
width: 11.46%;
}
}
@media (min-width: 1200px) {
.customer .order-table--details .row > *:nth-child(3) {
width: 16.6%;
}
}
@media (min-width: 1200px) {
.customer .order-table--details .row > *:nth-child(4) {
width: 15.55%;
}
}
@media (min-width: 1200px) {
.customer .order-table--details .row > *:last-child {
width: 17.77%;
}
}
@media (min-width: 1200px) {
.customer .account-item-info__box .row {
min-height: unset;
}
}
.customer .account-item-info__box .tbody {
margin-top: 1.6rem;
}
@media (min-width: 1200px) {
.customer .account-item-orders .row {
padding: 0 1.2rem;
}
}
.customer--small-text {
color: rgb(var(--color-foreground-title));
}
.customer--small-heading {
font-size: var(--font-heading-h6-size);
}

/* Pagination */
.customer .pagination {
margin-top: 5rem;
margin-bottom: 7rem;
}

@media screen and (min-width: 990px) {
.customer .pagination {
margin-top: 7rem;
margin-bottom: 10rem;
}
}
.customer .pagination ul {
display: flex;
justify-content: center;
list-style: none;
padding: 0;
}

.customer .pagination li {
flex: 1 1;
max-width: 4rem;
}

.customer .pagination li:not(:last-child) {
margin-right: 1rem;
}

.customer .pagination li :first-child {
display: inline-flex;
justify-content: center;
align-items: center;
position: relative;
height: 4rem;
width: 100%;
padding: 0;
text-decoration: none;
}

.customer .pagination li :first-child svg {
height: 0.6rem;
}

.customer .pagination li:first-of-type svg {
margin-left: -0.2rem;
transform: rotate(90deg);
}

.customer .pagination li:last-of-type svg {
margin-right: -0.2rem;
transform: rotate(-90deg);
}

.customer .pagination li [aria-current]::after {
content: "";
display: block;
width: 2rem;
height: 0.01rem;
position: absolute;
bottom: 0.08rem;
left: 50%;
transform: translateX(-50%);
background-color: currentColor;
}

/* Login */
.customer.login {
width: 100%;
position: relative;
}

.register .page-header .title--page:not(:first-child) {
margin: 0;
}
.register .customer__buttons {
margin-top: 3rem;
gap: 2rem;
flex-wrap: wrap;
}
@media screen and (min-width: 990px) {
.register .customer__buttons {
margin-top: 4rem;
}
}
.register .customer__buttons > * {
pointer-events: auto;
width: auto;
}
.register .customer__buttons .login__page-link {
display: flex;
gap: 0.8rem;
color: rgb(var(--color-foreground-sub-title));
}

.login-wrapper .customer-form__placeholder-block {
max-width: 100%;
}
@media screen and (min-width: 576px) {
.login-wrapper .customer-form__placeholder-block {
max-width: 50%;
}
}
@media screen and (min-width: 990px) {
.login-wrapper .customer-form__placeholder-block {
max-width: 42.6rem;
width: 100%;
}
}
.login-wrapper .login .page-header {
text-align: left;
border-bottom: 0;
padding: 0;
margin-bottom: 0;
max-width: 100%;
}
@media (min-width: 576px) {
.login-wrapper .login .page-header {
max-width: 60%;
}
}
@media screen and (min-width: 990px) {
.login-wrapper .login .page-header {
margin-bottom: 0;
padding: 0;
max-width: 100%;
}
}
.login-wrapper .login .page-header__inner {
display: flex;
flex-direction: column;
gap: 2rem;
}
@media screen and (min-width: 990px) {
.login-wrapper .login .page-header__inner {
gap: 3.2rem;
}
}
.login-wrapper .login .page-header h1 {
margin-top: 1.5rem;
pointer-events: none;
}
.login-wrapper .login .page-header h1 i {
white-space: nowrap;
}
.login-wrapper .login .breadcrumb {
margin: 0;
pointer-events: auto;
}
.login-wrapper .login__subtitle {
pointer-events: none;
}
.login-wrapper .login__reset, .login-wrapper .login__form {
max-width: 100%;
}
@media screen and (min-width: 990px) {
.login-wrapper .login__reset, .login-wrapper .login__form {
max-width: 48.3rem;
width: 100%;
position: relative;
z-index: 4;
}
}
.login-wrapper .login__reset {
margin-bottom: 2rem;
pointer-events: none;
}
.login-wrapper .login__form {
display: flex;
flex-direction: column;
justify-content: flex-start;
pointer-events: none;
}
.login-wrapper .login__field {
display: flex;
flex-direction: column;
pointer-events: auto;
}
.login-wrapper .login__forgot-password {
margin-top: 2rem;
pointer-events: auto;
}
@media screen and (min-width: 990px) {
.login-wrapper .login__forgot-password {
margin-top: 4rem;
}
}
.login-wrapper .login__buttons {
gap: 1.6rem;
flex-wrap: wrap;
}
.login-wrapper .login__sign-in {
pointer-events: auto;
}
.login-wrapper .login__create-acc {
pointer-events: auto;
}

.login .field + a {
margin-top: 1rem;
}

.login p {
margin: 1.5rem 0;
}

.login h3 {
margin-top: 1.5rem;
text-align: left;
font-size: 1.4rem;
}

#customer_login_guest button {
margin-top: 0;
}

#recover {
display: none;
}

#recover:target {
display: block;
}

#recover:target ~ #login__form {
display: none;
}

.activate button[name=decline] {
background-color: transparent;
box-shadow: 0 0 0 0.1rem rgb(var(--color-link));
color: rgb(var(--color-link));
}

.activate button[name=decline]:hover {
box-shadow: 0 0 0 0.2rem rgb(var(--color-link));
}

@media only screen and (min-width: 750px) {
.activate button[name=decline] {
margin-top: inherit;
margin-left: 1rem;
}
}
/* Account/Order */
:is(.account, .order, .addresses) p {
margin: 0 0 2.4rem;
}

:is(.account, .order, .addresses) h1 {
margin-bottom: 1rem;
}

.account-row h2 {
margin-top: 0;
margin-bottom: 1.6rem;
--font-heading-weight: 500;
letter-spacing: -0.02em;
}

.account h1 + a {
display: inline-flex;
align-items: center;
font-size: 1.3rem;
text-align: left;
}
@media (min-width: 1200px) {
.account h1 + a {
margin-top: 0;
text-align: center;
}
}

@media screen and (min-width: 990px) {
.account table td,
.order table tbody td {
padding-top: 2.6rem;
padding-bottom: 2.6rem;
}
}
.account table td:first-of-type a,
.order table td:first-of-type a {
font-size: 1.4rem;
color: rgb(var(--color-foreground));
}

.account table td:first-of-type a:hover,
.order table td:first-of-type a:hover {
color: rgb(var(--color-foreground), 0.75);
}

@media (min-width: 990px) {
.account .page-header,
.order .page-header {
margin-bottom: 4rem;
padding-bottom: 3rem;
}
}
@media (min-width: 1200px) {
.account .page-header,
.order .page-header {
margin-bottom: 2rem;
padding-bottom: 2rem;
}
}

.addresses .page-header {
border-bottom: none;
}
@media (min-width: 750px) {
.addresses .page-header {
margin-bottom: 0;
padding-bottom: 4rem;
}
}
.addresses .address-add-item,
.addresses .address-cancel {
margin-bottom: 1.5rem;
}
@media (min-width: 1200px) {
.addresses .address-add-item,
.addresses .address-cancel {
margin-bottom: 0;
}
}
.addresses .address-edit-buttons {
display: flex;
flex-wrap: wrap;
align-items: center;
justify-content: space-between;
gap: 0.8rem;
margin-top: 4rem;
}
.addresses .address-add-item {
padding: 0 3.5rem;
}

/* Addresses */
.addresses li > button {
margin-left: 0.5rem;
margin-right: 0.5rem;
}

.addresses li > button + button,
.addresses form button + button {
margin-top: 0rem;
}

@media screen and (min-width: 750px) {
.addresses li > button:first-of-type {
margin-top: 3rem;
}
}
.address-add-buttons {
display: flex;
flex-wrap: wrap;
gap: 1rem;
}

label[for=AddressCountryNew],
label[for=AddressProvinceNew] {
display: block;
font-size: 1.4rem;
margin-bottom: 0.6rem;
}

.addresses form {
display: grid;
gap: 2.4rem;
}

.addresses form .field {
margin: 0;
}

.addresses ul {
list-style: none;
padding-left: 0;
}

.address-list {
display: flex;
flex-direction: column;
}

.address-list__item-wrapper--default {
order: -1;
}

.addresses [data-expand=false] {
display: none !important;
}

.addresses [data-expand=true] {
display: block !important;
}
.addresses [data-expand=true].account-none-address {
display: flex !important;
}

.address-edit-form {
display: none;
padding-bottom: 3.2rem;
}

.address-edit-form.active {
display: block;
}

.addresses .address-add-form {
display: flex;
flex-direction: column;
align-items: center;
}

.address-add-form .account-none-address {
width: 100%;
}

.addresses .address-add-form__item {
width: 100%;
position: relative;
}
.addresses .address-add-form__item::before {
content: "";
position: absolute;
bottom: 0;
left: 0;
width: 100%;
height: 1px;
background-color: rgb(var(--color-border));
}

.addresses .address-add-form__inner {
padding: 3.2rem 0;
}
@media (min-width: 1200px) {
.addresses .address-add-form__inner {
padding: 3.2rem 0 3.2rem 4.8rem;
}
}

.addresses .address-add-form__inner h2 {
margin: 0;
font-weight: var(--font-body-weight);
}

.address-add-default-check {
display: flex;
align-items: center;
font-size: 1.2rem;
}

.addresses h2 {
text-align: left;
}

li[data-address] > h2 {
margin: 0 0 2rem 0;
}

@media only screen and (min-width: 990px) {
.addresses form {
grid-template-columns: 1fr 1fr 1fr 1fr;
grid-template-rows: auto;
grid-template-areas: "a1 a2 a2 a3" "a4 a4 a5 a5" "a9 a9 a10 a10" "a7 a7 a6 a6" "a8 a8 a a" "a11 a11 a11 a11" "a12 a12 a12 a12";
}
.addresses form div:nth-of-type(1) {
grid-area: a1;
}
.addresses form div:nth-of-type(2) {
grid-area: a2;
}
.addresses form div:nth-of-type(3) {
grid-area: a3;
}
.addresses form div:nth-of-type(4) {
grid-area: a4;
}
.addresses form div:nth-of-type(5) {
grid-area: a5;
}
.addresses form div:nth-of-type(6) {
grid-area: a6;
}
.addresses form div:nth-of-type(7) {
grid-area: a7;
}
.addresses form div:nth-of-type(8) {
grid-area: a8;
}
.addresses form div:nth-of-type(9) {
grid-area: a9;
}
.addresses form div:nth-of-type(10) {
grid-area: a10;
}
.addresses form div:nth-of-type(11) {
grid-area: a11;
}
.addresses form div:nth-of-type(12) {
grid-area: a12;
}
}
.addresses ul p {
margin-bottom: 0;
}

.customer .customer-address__list {
list-style: none;
padding: 0;
margin: 0;
}

.customer .customer-address__item {
color: rgb(var(--color-foreground-title));
}

.customer .customer-address__item:not(:last-child) {
margin-bottom: 1.2rem;
}

.customer .order-address__list {
list-style: none;
padding: 0;
margin: 0;
}

.customer .order-status {
font-size: 1.2rem;
letter-spacing: 0.075em;
text-transform: uppercase;
color: rgb(var(--color-foreground));
font-weight: var(--font-button-weight);
margin: 0 0 1.2rem;
}

.icon-account-order-none {
width: 36px;
height: 36px;
margin-bottom: 0.8rem;
color: rgb(var(--color-foreground-title));
}

.icon-account-address-none {
width: 37px;
height: 35px;
margin-bottom: 1.3rem;
color: rgb(var(--color-foreground-title));
}

.account-none {
display: flex;
flex-direction: column;
align-items: flex-start;
}

.customer .account-row-title {
display: flex;
flex-wrap: wrap;
align-items: flex-end;
gap: 1rem;
margin-bottom: 1.6rem;
}

.customer .account-row-title h2 {
margin: 0;
}

.customer .account-row-title p {
align-self: center;
margin: 0;
font-size: 1.2rem;
color: rgba(var(--color-foreground), 0.6);
}
@media screen and (min-width: 1440px) {
.customer .account-row-title p {
padding-top: 0.8rem;
}
}

.login-wrapper {
display: flex;
flex-wrap: wrap;
gap: 4.8rem;
}
@media screen and (min-width: 990px) {
.login-wrapper {
flex-wrap: nowrap;
gap: 3.6rem;
}
}
.login-wrapper__image {
flex: 0 0 100%;
max-width: 100%;
}
@media screen and (min-width: 990px) {
.login-wrapper__image {
flex: 0 0 40%;
max-width: 40%;
}
}
.login-wrapper__image-box {
position: relative;
width: 100%;
height: 100%;
}
.login-wrapper__image-box img {
height: 100%;
width: 100%;
object-fit: cover;
}
.login-wrapper__image-box::after {
content: "";
position: absolute;
top: 0;
width: 100%;
height: 1px;
background-color: rgb(var(--color-border));
left: 0;
transform: translateY(-2.4rem);
}
@media screen and (min-width: 990px) {
.login-wrapper__image-box::after {
width: 1px;
height: 100%;
transform: translate(-1.6rem);
}
}

.order .order__title {
display: flex;
align-items: center;
justify-content: space-between;
padding-bottom: 3.2rem;
margin-bottom: 3.2rem;
}

.customer-form__placeholder-block .field input:not(input[type=checkbox]) + label {
display: inline-block;
position: absolute;
top: 50%;
transform: translateY(-50%);
left: 1.6rem;
transition: all var(--duration-default) ease;
}

.customer-form__placeholder-block .field input:not(input[type=checkbox]):not(:placeholder-shown) + label {
font-size: 1.2rem;
color: rgb(115, 115, 114);
display: inline-block;
position: absolute;
top: 0.3rem;
left: 1.6rem;
transform: translateY(0);
}

.customer-form__placeholder-block .field input:not(input[type=checkbox]):focus + label {
font-size: 1.2rem;
color: rgb(115, 115, 114);
top: 0.3rem;
transform: translateY(0);
}

.customer input[type=text],
.customer input[type=email],
.customer input[type=tel],
.customer input[type=password],
.customer .field__input,
.article-template input[type=text],
.article-template input[type=email],
.article-template input[type=tel],
.article-template input[type=password],
.article-template .field__input {
padding: 2rem 1.8rem 0.7rem;
}

.default-address {
background-color: var(--color-badge-bg);
color: var(--color-badge-text);
margin: 0;
padding: 0.6rem 1.2rem;
flex: none;
}

.custom-checkbox input[type=checkbox]:checked + label .custom-checkbox__icon {
background-color: rgb(var(--color-foreground-title));
}
.custom-checkbox input[type=checkbox]:checked + label .custom-checkbox__icon .icon {
display: block;
}
.custom-checkbox__icon {
width: 1.6rem;
height: 1.6rem;
display: inline-flex;
align-items: center;
justify-content: center;
color: rgb(var(--color-background));
margin-right: 1rem;
border: 0.1rem solid rgb(var(--color-foreground-title));
}
.custom-checkbox__icon .icon {
display: none;
}

.no-js .addresses [data-expand] {
display: block !important;
}
/*============================================================================*/
.collection-banner-section{
--slide-height: 60vh;
}
.collection-banner {
overflow: hidden;
display: flex;
align-items: flex-end;
flex-wrap: wrap;
margin-top: 2rem;
}
.collection-banner__body {
position: relative;
z-index: 1;
display: flex;
align-items: flex-end;
flex-wrap: wrap;
}
.collection-banner__body.show_img::before {
content: "";
display: block;
padding-bottom: var(--slide-height);
}
.collection-banner__body.overlay-enable::after {
content: "";
position: absolute;
bottom: 0;
left: 0;
width: 100%;
height: 50%;
background: var(--overlay-gradient);
z-index: -1;
}
@media screen and (min-width: 750px) {
.collection-banner {
margin-top: 3.2rem;
}
}
.collection-banner > * {
flex: 0 0 100%;
}
.collection-banner__img {
position: absolute;
width: 100%;
height: 100%;
left: 0;
top: 0;
z-index: -2;
}
.collection-banner__img img {
width: 100%;
height: 100%;
object-fit: cover;
object-position: center;
max-width: 100%;
max-height: 100%;
min-width: auto;
min-height: auto;
}
.collection-banner__ticker__wrapper.ticker-top {
order: -1;
}
.collection-banner__text {
padding: 3rem 0;
}
@media screen and (min-width: 750px) {
.collection-banner__text {
padding: 6rem 0;
}
}
.collection-banner__text.no-paddings {
padding: 0;
}
.collection-banner__title {
max-width: 530px;
margin: 0 0 1.5rem;
}
@media screen and (min-width: 750px) {
.collection-banner__title {
margin: 0 0 3rem;
}
}
.collection-banner__title:last-child, .collection-banner__title:only-child {
margin: 0;
}
.collection-banner__desc {
max-width: 900px;
}

.container .collection-banner .container {
margin: 0;
}
@media screen and (min-width: 750px) {
.container .collection-banner .container {
padding: 0 4rem;
}
}
/*============================================================================*/
.collection-grid-section{
padding-bottom: 80px;
}
.collection__main-wrapper {
overflow: hidden;
}

.collection {
position: relative;
margin-top: 3rem;
}
@media screen and (min-width: 750px) {
.collection {
margin-top: 3.4rem;
}
}
.collection-filters {
display: flex;
flex-direction: column;
}
@media screen and (min-width: 750px) {
.collection-filters {
display: block;
}
}
.collection-product-list {
list-style: none;
padding: 0;
margin-bottom: 0;
}
.collection--empty .title-wrapper {
margin-top: 10rem;
margin-bottom: 15rem;
}

.collection-product {
position: relative;
}
.collection-product .loading-overlay {
top: 0;
right: 0;
bottom: 0;
left: 0;
z-index: 6;
display: none;
width: 100%;
padding: 0 5rem;
background-color: rgba(var(--color-background), 0.7);
}
.collection-product.loading > .loading-overlay {
display: block;
}
.collection-product.loading > .loading-overlay .loading-overlay__spinner {
top: 15rem;
}

.collection-product-list {
--gap: 3.2rem;
display: grid;
align-self: flex-start;
gap: var(--gap);
grid-template-columns: repeat(var(--mobile-per-row), 1fr);
--per-row: 3;
}
@media screen and (min-width: 750px) {
.collection-product-list {
grid-template-columns: repeat(2, 1fr);
}
}
@media screen and (min-width: 1200px) {
.collection-product-list {
grid-template-columns: repeat(var(--per-row), 1fr);
}
}
@media screen and (max-width: 750px) {
.collection-product-list.collection-product-list-row .collection-product-card:not(:nth-child(2n)):not(:last-child) .card__inner {
position: relative;
}
.collection-product-list.collection-product-list-row .collection-product-card:not(:nth-child(2n)):not(:last-child) .card__inner::before {
content: "";
position: absolute;
width: 1px;
height: 100%;
background: rgb(var(--color-border));
right: calc(var(--gap) / -2);
pointer-events: none;
}
.collection-product-list.collection-product-list-row .card__link {
min-height: unset;
}
}
.collection-product-list .collection-product-card {
align-self: flex-start;
height: 100%;
}
@media screen and (min-width: 750px) and (max-width: 1200px) {
.collection-product-list .collection-product-card:not(:nth-child(2n)):not(:last-child) .card__inner {
position: relative;
}
.collection-product-list .collection-product-card:not(:nth-child(2n)):not(:last-child) .card__inner::before {
content: "";
position: absolute;
width: 1px;
height: 100%;
background: rgb(var(--color-border));
right: calc(var(--gap) / -2);
pointer-events: none;
}
}
@media screen and (min-width: 1200px) {
.collection-product-list .collection-product-card:not(.hide_border):not(:last-child) .card__inner {
position: relative;
}
.collection-product-list .collection-product-card:not(.hide_border):not(:last-child) .card__inner::before {
content: "";
position: absolute;
width: 1px;
height: 100%;
background: rgb(var(--color-border));
right: calc(var(--gap) / -2);
pointer-events: none;
}
}
.collection-product-list img.img-scale {
transition: transform var(--duration-default) ease;
}
.collection-product-list .collections-grid__card:hover img.img-scale {
transform: scale(1.05);
}
@media screen and (min-width: 1200px) {
.collection-product-list .collection-product-card--portrait {
min-height: 70rem;
}
}
@media screen and (min-width: 1360px) {
.collection-product-list .collection-product-card--portrait {
min-height: 78rem;
}
}
@media screen and (min-width: 1200px) {
.collection-product-list .collection-product-card--portrait .card-wrapper::before {
padding-bottom: 130%;
}
.collection-product-list .collection-product-card--square .card-wrapper::before {
padding-bottom: 100%;
}
.collection-product-list .collection-product-card--landscape .card-wrapper::before {
padding-bottom: 70%;
}
.collection-product-list .collection-product-card--extended {
grid-column: span 2;
}
.collection-product-list .collection-product-card--extended .quick-add {
position: unset;
}
.collection-product-list .collection-product-card--extended .media img + img {
opacity: 1;
}
.collection-product-list .collection-product-card--extended .card--product {
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
}
.collection-product-list .collection-product-card--extended .card__inner {
height: 100%;
}
.collection-product-list .collection-product-card--extended .card-wrapper {
display: flex;
height: auto;
}
.collection-product-list .collection-product-card--extended .card-wrapper .media {
padding: 0;
height: 100%;
}
.collection-product-list .collection-product-card--extended .card-wrapper::before {
content: "";
display: table;
box-sizing: border-box;
width: 0;
}
.collection-product-list .collection-product-card--extended .card__badge {
top: 4rem;
right: 4rem;
}
.collection-product-list .collection-product-card--extended.quickview--hover:hover .card__badge {
opacity: 1;
}
.collection-product-list .collection-product-card--extended .card-information {
display: flex;
flex-direction: column;
justify-content: flex-end;
flex: 1;
margin: 4rem;
padding: 0;
}
.collection-product-list .collection-product-card--extended .card-information__wrapper {
position: relative;
display: grid;
grid-template-columns: minmax(auto, 34rem) auto;
align-items: center;
justify-content: space-between;
column-gap: 2.4rem;
width: 100%;
padding: 1.8rem 3rem;
}
.collection-product-list .collection-product-card--extended .card-information__wrapper > * {
color: rgb(var(--color-base-text));
}
.collection-product-list .collection-product-card--extended .card-information__wrapper .price__regular,
.collection-product-list .collection-product-card--extended .card-information__wrapper .price--on-sale .price-item--regular {
color: rgb(var(--color-base-text));
text-decoration-color: rgb(var(--color-base-text));
}
.collection-product-list .collection-product-card--extended .card-information__wrapper::before {
content: "";
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
z-index: -1;
pointer-events: none;
background-color: rgb(var(--color-background-card));
border-radius: 10rem;
transition: transform var(--duration-default) ease;
}
.collection-product-list .collection-product-card--extended .card-information__wrapper:hover::before {
transform: scale(1.03);
}
.collection-product-list .collection-product-card--extended .subtitle {
margin-bottom: 0;
}
.collection-product-list .collection-product-card--extended .subtitle + .card__title {
grid-row: 2;
}
.collection-product-list .collection-product-card--extended .subtitle + .card__title + .price {
grid-row: 1/span 2;
}
.collection-product-list .collection-product-card--extended .card__title {
position: static;
grid-column: 1;
display: block;
margin: 0;
z-index: 2;
pointer-events: all;
overflow: hidden;
}
.collection-product-list .collection-product-card--extended .card__title a {
font-size: var(--font-heading-card-size-big);
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
}
.collection-product-list .collection-product-card--extended .card__title a svg {
display: none;
}
.collection-product-list .collection-product-card--extended .card__title a::after {
content: "";
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
}
.collection-product-list .collection-product-card--extended .price {
grid-column: 2;
}
.collection-product-list .collection-product-card--extended .price-item,
.collection-product-list .collection-product-card--extended .price .price__sale .price-item--regular {
font-size: 1.5rem;
}
.collection-product-list .collection-product-card--extended .card-information__wrapper > *:not(.visually-hidden:first-child) + .price {
margin-top: 0;
}
.collection-product-list .collection-product-card--extended .card__link {
position: absolute;
top: 4rem;
left: 4rem;
bottom: auto;
text-decoration: none;
line-height: var(--font-body-line-height);
opacity: 1;
background-color: unset;
transform: none;
overflow: visible;
}
.collection-product-list .collection-product-card--extended .card__link::after {
content: "";
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: rgb(var(--color-background));
border-radius: 29px;
z-index: -1;
transition: transform var(--duration-default) ease;
}
.collection-product-list .collection-product-card--extended .card__link:hover::after {
transform: scale(1.1);
}
.collection-product-list .collection-product-card--extended .card__description {
display: none;
}
.collection-product-list .collection-product-card--extended:hover .card__link {
transform: none;
}
}

.collection-product-image {
position: relative;
display: none;
grid-column-start: 3;
grid-row: 2/span 2;
margin: -1px -1px 0;
overflow: hidden;
z-index: 2;
}
@media screen and (min-width: 990px) {
.collection-product-image {
display: block;
}
}
.collection-product-image--left {
grid-column-start: 1;
}
.collection-product-image__wrapper {
width: 100%;
height: 100%;
}
.collection-product-image__media {
display: block;
position: absolute;
top: 0;
left: 0;
right: 0;
width: 100%;
height: 115%;
object-fit: cover;
}

.title-wrapper-empty .title .link {
font-size: inherit;
letter-spacing: 0;
transition: var(--duration-default);
}

@media screen and (min-width: 990px) {
.collection-product .pagination-wrapper {
margin-top: 11.2rem;
}
}

.collections-grid {
display: grid;
gap: 2.4rem;
}
@media screen and (min-width: 750px) {
.collections-grid {
grid-template-columns: repeat(2, 1fr);
}
}
@media screen and (min-width: 990px) {
.collections-grid {
grid-template-columns: repeat(3, 1fr);
}
}
@media screen and (min-width: 1200px) {
.collections-grid {
grid-template-columns: repeat(4, 1fr);
}
}
.collections-grid__card:hover .card-wrapper {
clipPath: inset(0.5rem round 0.3rem);
}
.collections-grid__information {
position: relative;
display: flex;
flex-direction: column;
justify-content: flex-end;
padding: 4rem;
margin-top: 2.4rem;
width: 100%;
z-index: 2;
}
.collections-grid__title a {
position: relative;
z-index: 1;
word-break: break-all;
text-decoration: none;
color: inherit;
}
.collections-grid__title a svg {
margin-left: 0.8rem;
height: 1.4rem;
width: auto;
}
@media screen and (min-width: 750px) {
.collections-grid__title a svg {
height: 1.6rem;
}
}
.collections-grid img.img-scale {
transition: transform var(--duration-long) cubic-bezier(0.165, 0.84, 0.44, 1);
}
.collections-grid .collections-grid__card:hover img.img-scale {
transform: scale(1.05);
}
.collections-grid .card-wrapper {
position: relative;
display: flex;
overflow: hidden;
clipPath: inset(0 round 0.3rem);
transition: clipPath var(--duration-long) cubic-bezier(0.165, 0.84, 0.44, 1);
}
.collections-grid .card-wrapper::after {
position: absolute;
content: "";
top: 0;
right: 0;
bottom: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(var(--color-overlay), 0.4);
transition: var(--duration-default);
z-index: 1;
pointer-events: none;
}
.collections-grid .card-wrapper .image-ratio {
display: block;
box-sizing: border-box;
width: 0;
}
.collections-grid .card-wrapper .image-ratio--portrait {
padding-bottom: 114%;
}
.collections-grid .card-wrapper .image-ratio--square {
padding-bottom: 100%;
}
.collections-grid .card-wrapper .image-ratio--landscape {
padding-bottom: 70%;
}
.collections-grid .card-information__count {
position: absolute;
top: 4rem;
left: 4rem;
z-index: 2;
font-size: 1.2rem;
font-weight: 700;
letter-spacing: 0.06em;
text-transform: uppercase;
color: #fff;
pointer-events: none;
}
.collections-grid .card-wrapper__image {
opacity: 1;
}
.collections-grid__title {
margin: 0;
color: #fff;
}
.collections-grid__title:not(:last-child) {
margin-bottom: 1.2rem;
}
.collections-grid__desc {
margin: 0;
font-size: 1.2rem;
line-height: 145%;
color: #fff;
}

.collection-product-card.collection-product-card--extended.quickview--hover:hover .media.media--hover-effect {
transform: none;
}
/*============================================================================*/
.form-menu__mask:empty, .form-menu__mask {
position: fixed;
left: 0;
top: 0;
width: 100%;
height: 100%;
z-index: 100;
background: rgba(0, 0, 0, 0.5);
display: block;
pointer-events: none;
opacity: 0;
}

.facets-menu {
position: fixed;
left: 0;
top: 0;
width: 100%;
height: 100%;
z-index: 120;
background: rgb(var(--color-background));
overflow: auto;
transform: translateX(-150%);
transition: all var(--duration-long) linear;
padding: 3rem;
max-width: 360px;
}
@media screen and (min-width: 576px) {
.facets-menu {
max-width: 640px;
padding: 6.5rem 6rem 3rem;
}
}
.facets-menu__close {
position: absolute;
right: 3rem;
top: 3rem;
background: transparent;
border: 0;
width: 20px;
height: 20px;
padding: 0;
cursor: pointer;
}
.facets-menu__close svg {
transition: all var(--duration-default) linear;
}
.facets-menu__close:hover svg {
transform: rotate(90deg);
}
.facets-menu__all-items-count {
color: rgb(var(--color-foreground-sub-title));
margin-bottom: 4.7rem;
}
.facets-menu__title {
margin: 0 0 1.6rem;
}
.facets-menu__buttons {
text-align: center;
}
.facets-menu__buttons > button {
margin-bottom: 1.6rem;
}
.facets-menu::-webkit-scrollbar {
width: 1.6rem;
}
.facets-menu::-webkit-scrollbar-track {
background: transparent;
}
.facets-menu::-webkit-scrollbar-thumb {
background: rgba(var(--color-border), 1);
border-right: 0.8rem solid rgb(var(--color-background));
}
.facets-menu::-webkit-scrollbar-thumb:hover {
background: rgba(var(--color-border), 0.8);
}

.facets {
overflow: hidden;
border-top: 1px solid rgb(var(--color-border));
border-bottom: 1px solid rgb(var(--color-border));
margin-top: 2rem;
}
@media (min-width: 990px) {
.facets {
margin-top: 8rem;
}
}
.facets-checkbox {
display: flex;
align-items: center;
cursor: pointer;
}
.facets-checkbox__marker {
display: inline-flex;
align-items: center;
justify-content: center;
padding: 0.2rem;
width: 20px;
height: 20px;
border: 2px solid rgb(var(--color-foreground-title));
margin-right: 1rem;
color: rgb(var(--color-background));
}
.facets-checkbox__marker svg {
width: 100%;
height: 100%;
display: inline-block;
}
.facets-checkbox__hide {
display: none;
}
.facets-checkbox__hide:checked + .facets-checkbox__marker {
background: rgb(var(--color-foreground-title));
}
.facets-checkbox--disabled {
cursor: unset;
}
.facets-checkbox--disabled > * {
color: rgba(var(--color-foreground), 0.4);
}
.facets-checkbox--disabled .facets-checkbox__hide:checked + .facets-checkbox__marker {
background: rgba(var(--color-foreground-title), 0.4);
color: rgba(var(--color-foreground), 0.4);
}
.facets-checkbox--disabled .facets-checkbox__hide:not(:disabled) + .facets-checkbox__marker:hover {
border-color: #737372;
}
.facets-checkbox--disabled .facets-checkbox__marker {
border-color: rgba(var(--color-foreground), 0.4);
color: rgb(var(--color-background));
}
.facets-checkbox__count {
margin-left: 0.5rem;
}
.facets-wrap {
border: 0;
padding: 0;
margin: 0;
}
.facets__list {
margin: 0;
padding: 0;
list-style: none;
}
.facets__list li {
text-transform: capitalize;
margin-bottom: 2rem;
}
.facets__list li:last-child {
margin-bottom: 0;
}
.facets__details {
margin-bottom: 4rem;
width: 100%;
}
.facets__details > summary {
display: flex;
align-items: center;
justify-content: space-between;
}
.facets__details > summary svg {
transition: all var(--duration-long) linear;
width: 15px;
height: 15px;
}
@media screen and (min-width: 576px) {
.facets__details > summary svg {
width: 20px;
height: 20px;
}
}
.facets__details-title {
margin: 0;
}
.facets__details[open] > summary {
margin-bottom: 2.4rem;
}
.facets__details[open] > summary svg {
transform: rotate(180deg);
}
.facets__details-first {
order: -1;
}
.facets__details-last {
order: 2;
}
.facets__details__wrapper {
display: flex;
flex-direction: column;
}
.facets__reset {
opacity: 0.5;
pointer-events: none;
}
.facets__price-range {
align-items: center;
max-width: 420px;
flex-direction: column;
}
@media screen and (min-width: 576px) {
.facets__price-range {
flex-direction: row;
}
}
.facets__price-range label {
display: flex;
align-items: center;
flex: 1 1 auto;
width: 100%;
}
.facets__price-range label > span {
margin-right: 0.4rem;
}
@media screen and (min-width: 576px) {
.facets__price-range label {
width: unset;
}
}
.facets__price-range__delimiter {
display: inline-block;
background: rgb(var(--color-foreground-secondary));
width: 2px;
margin: 1rem 0;
height: 16px;
}
@media screen and (min-width: 576px) {
.facets__price-range__delimiter {
width: 16px;
height: 2px;
margin: 0 1.6rem;
}
}
.facets__wrapper {
display: flex;
flex-wrap: wrap;
justify-content: space-between;
padding: 2rem 0;
}
@media screen and (min-width: 576px) {
.facets__wrapper {
flex-wrap: unset;
justify-content: unset;
padding: 0;
}
}
.facets__product-count {
text-align: center;
display: flex;
align-items: center;
justify-content: center;
order: 2;
flex: 0 0 100%;
margin: 2rem 0 0;
min-height: unset;
padding-top: 2rem;
position: relative;
font-size: 1.4rem;
color: rgba(var(--color-foreground-secondary));
}
.facets__product-count:before {
content: "";
position: absolute;
top: 0;
left: 0;
width: 300vw;
height: 1px;
background: rgb(var(--color-border));
transform: translateX(-100vw);
}
@media screen and (min-width: 576px) {
.facets__product-count {
order: unset;
border-right: 1px solid rgb(var(--color-border));
border-left: 1px solid rgb(var(--color-border));
flex: 1 1 auto;
min-height: 72px;
padding-top: 0;
margin: 0 3rem;
}
.facets__product-count:before {
display: none;
}
}
@media screen and (min-width: 990px) {
.facets__product-count {
margin: 0 6rem;
}
}
.facets__product-count:last-child {
border-right: 0;
}
.facets__sort-by {
font-family: var(--font-heading-family);
text-transform: uppercase;
color: rgb(var(--color-foreground-title));
display: flex;
align-items: center;
position: relative;
transition: all var(--duration-default) linear;
min-height: 7.2rem;
}
.facets__sort-by svg {
position: absolute;
right: 0;
top: 50%;
transform: translateY(-50%);
pointer-events: none;
transition: inherit;
}
.facets__sort-by:hover {
color: rgba(var(--color-foreground-title), 0.8);
}
.facets__select-label {
margin-right: 0.5rem;
pointer-events: none;
transition: inherit;
}
.facets .open_filters:hover .animate-left {
transform: translateX(-5px);
}
.facets .open_filters:hover .animate-right {
transform: translateX(5px);
}
.facets .animate-in-hover {
transition: all var(--duration-default) linear;
}

.hide__checkbox {
display: flex;
flex-wrap: wrap;
gap: 2rem;
}
.hide__checkbox .facets-checkbox__count,
.hide__checkbox .facets-checkbox__marker {
display: none;
}
.hide__checkbox .facets-checkbox__label {
background-color: rgb(var(--color-background-input));
color: rgb(var(--color-placeholder-input));
padding: 0.8rem 1.6rem;
transition: all var(--duration-long) ease;
}
.hide__checkbox .facets-checkbox__hide:not(:disabled) ~ .facets-checkbox__label:hover,
.hide__checkbox .facets-checkbox__hide:checked ~ .facets-checkbox__label {
background: rgb(var(--color-foreground-title));
color: rgb(var(--color-background));
}
.hide__checkbox li {
margin: 0;
}

.no-js #open_filters_menu:target .form-menu__mask,
#open_filters_menu.show_menu .form-menu__mask {
opacity: 1;
pointer-events: all;
}
.no-js #open_filters_menu:target .facets-menu,
#open_filters_menu.show_menu .facets-menu {
transform: translateX(0);
}

.open_filters {
display: flex;
align-items: center;
}
.open_filters svg {
width: 24px;
height: 24px;
margin-right: 1.2rem;
}

.select__sort_by {
transition: inherit;
font-family: inherit;
border: 0;
-webkit-appearance: none;
font-size: inherit;
text-transform: inherit;
color: inherit;
line-height: inherit;
outline: none;
padding-right: 1.5rem;
cursor: pointer;
background-color: transparent;
}
@media screen and (min-width: 576px) {
.select__sort_by {
padding-right: 0 2rem;
}
}

.type-filter__list {
display: flex;
align-items: center;
list-style: none;
flex-wrap: wrap;
gap: 1.4rem;
padding: 1.6rem 0 3.2rem;
margin: 0;
justify-content: center;
}
@media screen and (min-width: 990px) {
.type-filter__list {
padding: 3.2rem 0 6.4rem;
}
}
.type-filter__input {
display: none;
}
.type-filter__title {
transition: all var(--duration-long) ease;
}
.type-filter__label:not(.facets-checkbox--disabled) {
cursor: pointer;
}
.type-filter__label:not(.facets-checkbox--disabled):not(:hover) .type-filter__input:not(:checked) + .type-filter__title {
opacity: 1;
}
.type-filter__container + .facets {
margin-top: 0;
}
.type-filter__label{
    border: 1px solid #333;
    padding: 10px 15px 10px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-heading-family);
    font-style: var(--font-heading-style);
    text-transform: uppercase;
    font-weight: 600;
}
.field__range {
pointer-events: none;
height: 0.2rem;
margin-bottom: 2.4rem;
vertical-align: top;
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
width: 100%;
background: transparent;
/* Chrome and Safari */
/* Firefox */
}
.field__range::-webkit-slider-thumb {
-webkit-appearance: none;
}
.field__range::-webkit-slider-thumb {
-webkit-appearance: none;
position: relative;
height: 1.8rem;
width: 1.8rem;
border-radius: 100%;
border: none;
background: rgb(var(--color-base-background-2));
cursor: pointer;
margin-top: -1rem;
margin-left: -0.2rem;
z-index: 1;
box-shadow: inset 0 0 0 0.7rem rgb(var(--color-button));
}
.field__range::-webkit-slider-runnable-track {
width: 100%;
height: 0.2rem;
cursor: pointer;
background: rgb(var(--color-border));
border-radius: 0.2rem;
border: none;
}
.field__range::-moz-range-thumb {
height: 1.8rem;
width: 1.8rem;
border-radius: 100%;
border: none;
background: rgb(var(--color-base-background-2));
cursor: pointer;
/*margin-top: -1rem;*/
margin-left: -0.2rem;
z-index: 1;
box-shadow: inset 0 0 0 0.7rem rgb(var(--color-button));
}
.field__range::-moz-range-progress, .field__range::-moz-range-track {
width: 100%;
height: 0.2rem;
cursor: pointer;
border-radius: 0.2rem;
border: none;
}
.field__range::-moz-range-progress {
background-color: rgb(var(--color-button));
}
.field__range::-moz-range-track {
background-color: rgb(var(--color-border));
}
.field__range:last-child {
position: absolute;
top: 0;
left: 0;
}

.facets__range {
position: relative;
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
height: 0.2rem;
order: -1;
background: linear-gradient(to right, rgb(var(--color-border)) var(--range-min), rgb(var(--color-button)) var(--range-min), rgb(var(--color-button)) var(--range-max), rgb(var(--color-border)) var(--range-max));
max-width: 42rem;
margin: 0 0 3.2rem;
}
.facets__range .field__range {
pointer-events: none;
height: 0.2rem;
vertical-align: top;
}
.facets__range .field__range::-webkit-slider-runnable-track {
background: none;
}
.facets__range .field__range::-webkit-slider-thumb {
pointer-events: auto;
}
.facets__range .field__range::-moz-range-progress,
.facets__range .field__range::-moz-range-track {
background: none;
}
.facets__range .field__range::-moz-range-thumb {
pointer-events: auto;
}
.facets__range .field__range:last-child {
position: absolute;
top: 0;
left: 0;
}

.facets__price-label {
display: block;
margin-top: 1.6rem;
}
.facets__price-ranges {
display: flex;
flex-direction: column;
}

.no-js .facets__sort-by + noscript {
margin: auto;
margin-left: 1rem;
}
/*============================================================================*/
/*============================================================================*/
.popular-products-section{
--per-row :4;
padding-top: 0px;
padding-bottom: 80px;
}
.popular-products {
--gap: 1.6rem;
--grid-width: 100%;
}
@media screen and (min-width: 750px) {
.popular-products {
--grid-width: calc(50% - (var(--gap) * (1 - 1 / var(--per-row))));
}
}
@media screen and (min-width: 1200px) {
.popular-products {
--gap: 3.2rem;
--grid-width: calc(
100% / var(--per-row) - (var(--gap) * (1 - 1 / var(--per-row)))
);
}
}
.popular-products__wrapper {
display: grid;
align-self: flex-start;
gap: var(--gap);
grid-template-columns: repeat(var(--mobile-per-row), 1fr);
margin: 2.4rem 0 0;
padding: 0;
}
@media screen and (min-width: 750px) {
.popular-products__wrapper {
grid-template-columns: repeat(2, 1fr);
}
}
@media screen and (min-width: 1200px) {
.popular-products__wrapper {
grid-template-columns: repeat(var(--per-row), 1fr);
}
}
@media screen and (max-width: 750px) {
.popular-products__wrapper.popular-products__wrapper-row li:not(:nth-child(2n)):not(:last-child) .card__inner {
position: relative;
}
.popular-products__wrapper.popular-products__wrapper-row li:not(:nth-child(2n)):not(:last-child) .card__inner::before {
content: "";
position: absolute;
width: 1px;
height: 100%;
background: rgb(var(--color-border));
right: calc(var(--gap) / -2);
pointer-events: none;
}
.popular-products__wrapper.popular-products__wrapper-row .card__link {
min-height: unset;
}
}
.popular-products .collection-popular-card {
transition: opacity var(--duration-default);
}
@media screen and (min-width: 750px) and (max-width: 1200px) {
.popular-products .collection-popular-card:not(:nth-child(2n)):not(:last-child) .card__inner {
position: relative;
}
.popular-products .collection-popular-card:not(:nth-child(2n)):not(:last-child) .card__inner::before {
content: "";
position: absolute;
width: 1px;
height: 100%;
background: rgb(var(--color-border));
right: calc(var(--gap) / -2);
pointer-events: none;
}
}
@media screen and (min-width: 1200px) {
.popular-products .collection-popular-card:not(.hide_border):not(:last-child) .card__inner {
position: relative;
}
.popular-products .collection-popular-card:not(.hide_border):not(:last-child) .card__inner::before {
content: "";
position: absolute;
width: 1px;
height: 100%;
background: rgb(var(--color-border));
right: calc(var(--gap) / -2);
pointer-events: none;
}
}
.collection-product-card {
position: relative;
margin: 0;
transition: var(--duration-default);
}
.collection-product-card .badge {
position: relative;
}
.collection-product-card .caption-with-letter-spacing {
margin-top: 0;
margin-bottom: 0.8rem;
}
@media screen and (min-width: 990px) {
.collection-product-card:hover .card__link {
opacity: 1;
}
}
/*============================================================================*/
.card-wrapper {
position: relative;
display: block;
height: 100%;
text-decoration: none;
background-color: rgb(var(--color-background-card));
}
.card-wrapper__image {
opacity: 0;
position: absolute;
top: 0;
bottom: 0;
right: 0;
left: 0;
width: 100%;
height: 100%;
object-fit: cover;
z-index: 1;
transition: var(--duration-default);
}
.unstyled-link,
.full-unstyled-link,
.placeholder-svg {
transition: var(--duration-default);
}
.card-wrapper__link--overlay {
display: block;
text-decoration: none;
color: rgb(var(--color-link));
background-color: transparent;
z-index: 1;
}
.card {
display: block !important;
text-decoration: none;
border: 0px !important;
}
.card-information {
position: relative;
padding-top: 2rem;
z-index: 3;
pointer-events: none;
}
.card__inner {
position: relative;
display: block;
}
.card__text {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}
.сard__vendor {
font-family: var(--font-body-family);
font-style: var(--font-body-style);
font-weight: var(--font-button-weight);
font-size: 1.1rem;
line-height: 176.8%;
letter-spacing: var(--font-subtitle-letter-spacing);
text-transform: uppercase;
color: rgba(var(--color-base-text), 0.6);
transition: var(--duration-default);
}
.card__title {
position: relative;
display: block;
margin: 0;
max-width: 34.1rem;
color: rgb(var(--color-foreground-title));
z-index: 2;
pointer-events: all;
}
.card__title a {
word-break: break-word;
color: inherit;
}
.card__title a:hover {
opacity: 0.8;
}
.caption-with-letter-spacing {
max-width: 25.5rem;
}
.card__description {
margin-top: 1.6rem;
font-family: var(--font-text-card-family);
font-style: var(--font-text-card-style);
font-weight: var(--font-text-card-weight);
font-size: var(--font-text-card-size);
line-height: 1.45;
}
.card-information__bottom {
display: flex;
align-items: center;
justify-content: flex-start;
flex-wrap: wrap;
gap: 1.25rem;
margin-top: 0.8rem;
}
.card__link {
position: absolute;
left: 0;
bottom: 0;
margin: 0;
line-height: 1.2;
font-weight: 500;
text-transform: uppercase;
opacity: 1;
z-index: 3;
}
@media (min-width: 1200px) {
.card__link {
opacity: 0;
}
}
.card__link.loading {
opacity: 1;
pointer-events: none;
}
.card__link.loading .quick-add__label {
opacity: 0;
}
.card__link .loading-overlay__spinner {
display: flex;
align-items: center;
justify-content: center;
}
.card .icon-wrap {
margin-left: 0.8rem;
white-space: nowrap;
transition: transform var(--duration-short) ease;
overflow: hidden;
}
.card .h1 .icon-arrow,
.card .h2 .icon-arrow {
padding-bottom: 0.3rem;
padding-top: 0.3rem;
}
.card--media {
display: block;
text-decoration: none;
}
.card--text-only {
display: flex;
justify-content: center;
}
.card--text-only::before {
content: "";
display: block;
padding-bottom: 100%;
}
.card--product {
position: relative !important;
}
.card__inner {
background-color: rgba(var(--color-background));
}
.card--stretch {
height: 100%;
}
.card--light-border {
border: 0.1rem solid rgba(var(--color-base-text), 0.04);
transition: var(--duration-short);
}
.card--light-border:hover {
border: 0.1rem solid rgba(var(--color-base-text), 0.3);
box-shadow: none;
}
.card__text-spacing {
padding: 2rem;
}
.card-colored.color-background-1 {
background-color: rgba(var(--color-base-text), 0.04);
}
.card--media .card__text-spacing {
padding: 2rem;
text-align: center;
}
.card--text-only .card__inner {
display: grid;
grid-template-rows: 1fr auto 1fr;
width: 100%;
}
.card__content {
margin-left: 2.5rem;
margin-right: 2.5rem;
}
.card__content > * {
margin: 0;
}
.card--text-only .card__content {
grid-row: 2;
justify-self: center;
margin-bottom: 6rem;
margin-top: 5rem;
}
.card--text-only .card__badge {
align-self: flex-end;
grid-row: 3;
}
.card--search .card__badge > *,
.card--text-only .card__badge > * {
margin: 0 1rem 1rem;
}
.card--search .card__badge,
.card--text-only .card__badge {
position: initial;
}
.card--text-only .card__content + .card__badge {
margin-top: -5rem;
}
.card--search .card__content {
justify-self: flex-start;
text-align: left;
}
.media + .card__content {
margin-top: 2rem;
margin-bottom: 1.5rem;
}
.card-wrapper .media {
padding: 0;
background-color: #fff;
}
.card-wrapper .media > img {
width: 100%;
height: 100%;
object-fit: cover;
}
@media screen and (min-width: 750px) {
.card--text-only .card__content {
margin-top: 7rem;
margin-bottom: 7rem;
}
.card--text-only .card__content + .card__badge {
margin-top: -7rem;
}
}
.card__text-spacing > * {
margin: 0;
}
.card__text-spacing > *:not(.overlay-card) + * {
margin-top: 0.5rem;
}
.card__text {
margin: 0;
word-break: break-word;
}
.card--text-only .card__text {
text-align: center;
}
.card-information__text {
display: block;
padding-right: 1.2rem;
}
.card-information__wrapper {
display: flex;
flex-direction: column;
align-items: flex-start;
width: 100%;
}
.price-item {
transition: var(--duration-short);
}
.card-information__wrapper > *:not(.visually-hidden:first-child) + .price {
margin-top: 0.8rem;
}
.collection-product-card {
padding: 0;
}
.cart__footer-wrapper + .collection-products-section .collection-product-card:hover .card__link {
opacity: 0;
}
.card-wrapper:focus-within .card {
box-shadow: none;
}
.card__media-spacer {
padding: 2rem;
}
@media screen and (min-width: 750px) {
.card__media-spacer {
padding: 3rem;
}
}
.card-article-info {
margin-top: 1rem;
display: flex;
flex-wrap: wrap;
}
.card__badge {
position: absolute;
top: 1.6rem;
right: 1.6rem;
display: flex;
flex-direction: row-reverse;
flex-wrap: wrap;
opacity: 1;
z-index: 3;
}
.card__badge > * {
margin-left: 1rem;
margin-bottom: 0.5rem;
}
.card__badge {
transition: opacity var(--duration-default);
}
.card__badge .badge {
display: flex;
align-items: center;
justify-content: flex-start;
gap: 0.2rem;
text-transform: capitalize;
}
@media screen and (min-width: 990px) {
.card .media.media--hover-effect > img,
.card-wrapper .media.media--hover-effect > img {
transition: transform var(--duration-long) ease, opacity var(--duration-long) ease;
}
.card__inner .media.media--hover-effect {
transition: transform var(--duration-long) ease, opacity var(--duration-long) ease;
}
.card--search img {
transition: transform var(--duration-long) ease, opacity var(--duration-long) ease;
}
.card-wrapper:hover .card__heading {
text-decoration: underline;
text-underline-offset: 0.3rem;
}
.card-wrapper:hover .card.card--search {
background-color: rgba(var(--color-card-hover), 0.06);
transition: background-color var(--duration-long) ease;
}
}
@media screen and (max-width: 1199px) {
.card-information {
padding-top: 1.4rem;
}
}
@media screen and (min-width: 990px) {
.collection-product-card.quickview--hover:hover .card-wrapper__image {
opacity: 1;
transform: scale(1.05);
}
.collection-product-card.quickview--hover:hover .placeholder-svg {
transform: scale(1.05);
}
.collection-product-card.quickview--hover:hover .media.media--hover-effect > img:first-child:not(:only-of-type) {
opacity: 0;
transform: scale(1.12);
transition: transform var(--duration-long) ease, opacity var(--duration-long) ease;
}
.collection-product-card.quickview--hover:hover .media.media--hover-effect > img + img {
opacity: 1;
transform: scale(1.1);
transition: transform var(--duration-long) ease, opacity var(--duration-long) ease;
}
.collection-product-card.quickview--hover:hover .media.media--hover-effect > img {
transform: scale(1.12);
transition: transform var(--duration-long) ease, opacity var(--duration-long) ease;
}
.collection-product-card.quickview--hover:hover .card__badge {
opacity: 0;
pointer-events: none;
}
}
.card__sku {
margin: 0 0 0.8rem 0;
}
/*============================================================================*/
.price-wrapper {
margin-top: 1.6rem;
}
.price {
align-items: center;
display: flex;
flex-direction: row;
flex-wrap: wrap;
line-height: 1.5;
color: rgb(var(--color-foreground-title));
}
.price.price--unavailable {
visibility: hidden;
}
.price--end {
justify-content: flex-end;
}
.price dl {
margin: 0;
display: flex;
flex-direction: column;
}
.price dd {
margin: 0;
}
.price dd:not(:last-child) {
margin: 0 0.8rem 0 0;
}
.product .price dd {
margin: 0 0.8rem 0 0;
}
.price .price__last:last-of-type {
margin: 0;
}
@media screen and (min-width: 750px) {
.price {
margin-bottom: 0;
}
}
.price--large {
font-size: 1.4rem;
line-height: 1.5;
letter-spacing: 0.13rem;
}
.price--product {
font-size: 1.8rem;
}
@media screen and (min-width: 750px) {
.price--large {
font-size: 1.8rem;
}
.price--product {
font-size: 2.5rem;
}
}
.price--sold-out .price__availability,
.price__regular {
display: block;
}
.price__sale,
.price__availability,
.price--on-sale .price__regular,
.price--on-sale .price__availability,
.price--no-compare .price__compare {
display: none;
}
.price--on-sale .price__sale {
display: flex;
align-items: center;
flex-direction: row;
flex-wrap: wrap;
}
.price__sale dd {
display: flex;
align-items: center;
}
.price--center {
display: flex;
justify-content: center;
}
.price--on-sale .price-item--regular {
text-decoration: line-through;
color: rgb(var(--color-foreground-secondary));
text-decoration-color: rgb(var(--color-foreground-secondary));
}
.unit-price {
font-size: 1.1rem;
letter-spacing: 0.04rem;
line-height: 1;
text-transform: uppercase;
color: rgba(var(--color-foreground), 0.7);
display: inline-block;
}
/*============================================================================*/
.categories-list {
position: relative;
z-index: 1;
min-height: 300px;
display: flex;
--category-list-color: rgba(var(--color-foreground-title), 40%);
--category-list-color-overlay: rgba(var(--color-overlay), 20%);
}
.categories-list.have-overlay::before {
background: var(--category-list-color-overlay);
}
@media (min-width: 990px) {
.categories-list {
min-height: 640px;
}
}
.categories-list .categories-list__wrapper {
display: flex;
flex-direction: column;
justify-content: center;
height: 100%;
}
.categories-list .category-list__bg {
z-index: -3;
}
.categories-list .category-list__bg,
.categories-list .category-list__img {
position: absolute;
left: 0;
top: 0;
width: 100%;
height: 100%;
transition: all var(--duration-long) ease;
}
.categories-list .category-list__bg img,
.categories-list .category-list__img img {
width: 100%;
height: 100%;
object-fit: cover;
object-position: center;
}
.categories-list .category-list__subtitle {
text-align: center;
margin-bottom: 2rem;
}
.categories-list .category-list__list {
display: flex;
flex-wrap: wrap;
justify-content: center;
max-width: 875px;
margin: 0 auto;
padding: 0;
}
.categories-list .category-list__list li {
list-style: none;
padding: 0.6rem 1.2rem;
width: 100%;
}
.categories-list .category-list__list li.opacity .category-list__title {
color: var(--category-list-color);
}
.categories-list .category-list__list li .category-list__title {
transition: all var(--duration-long) ease;
margin: 0;
text-align: center;
}
@media (min-width: 990px) {
.categories-list .category-list__list li .category-list__title {
text-align: left;
}
}
.categories-list .category-list__list li .category-list__title a {
text-decoration: none;
color: inherit;
cursor: pointer;
}
.categories-list .category-list__list li .category-list__img {
z-index: -2;
opacity: 0;
pointer-events: none;
}
.categories-list .category-list__list li.active .category-list__img, .categories-list .category-list__list li:hover .category-list__img {
opacity: 1;
}
@media screen and (min-width: 750px) {
.categories-list .category-list__list li {
padding: 1.2rem 2.4rem;
width: 100%;
text-align: center;
display: flex;
align-items: center;
justify-content: center;
}
}
/*============================================================================*/
/*============================================================================*/
.slideshow .container .slideshow-slide__desc {
max-width: 24rem;
}
@media screen and (min-width: 750px) {
.slideshow .container .slideshow-slide__desc {
max-width: 44rem;
}
}
.slideshow-slide {
text-align: center;
position: relative;
z-index: 1;
display: flex;
align-items: center;
justify-content: space-between;
height: 100%;
box-sizing: border-box;
}
/*=.slideshow-slide::before {
content: "";
display: block;
padding-bottom: var(--slide-height);
}==*/
.slideshow-slide .have-overlay::before {
background: var(--slide-overlay);
}
/*==.slideshow-slide__img {
position: absolute;
left: 0;
top: 0;
width: 100%;
height: 100%;
z-index: -2;
}==*/
.slideshow-slide__img svg,
.slideshow-slide__img img {
width: 100%;
height: 100%;
max-width: 100%;
max-height: 100%;
object-fit: cover;
object-position: center;
position: relative;
z-index: -2;
}
.slideshow-slide__text {
max-width: 94.5rem;
margin: auto;
padding: 3rem 5rem 5rem;
}
@media screen and (min-width: 750px) {
.slideshow-slide__text {
padding: 6rem;
}
}
@media screen and (min-width: 1200px) {
.slideshow-slide__text {
padding: 8rem 0;
}
}
.slideshow-slide__title {
margin: 0 0 2.4rem;
}
@media screen and (min-width: 1200px) {
.slideshow-slide__title {
margin: 0 0 3.2rem !important;
}
}
.slideshow-slide__title:only-child {
margin: 0;
}
.slideshow-slide__desc {
max-width: 44rem;
margin: 0 auto 4rem;
}
.slideshow-slide__desc:last-child {
margin-bottom: 0;
}
.slideshow-swiper__slide {
height: auto;
}
[data-id="block_3"].slideshow-slide {
--slide-overlay : rgba(var(--color-overlay), 20%);
/*========--slide-height : calc(100vh - 75px - 39px);===*/
}

/*============================================================================*/

.multicolumn{
padding: 20px 0px;
}
.multicolumn-sectionlarge{
padding-top: 100px;
padding-bottom: 100px;
}
.multicolumn .multicolumn-list {
display: flex;
flex-direction: column;
gap: 2.4rem;
}
.multicolumn .multicolumn__wrapper-subtitle {
text-align: center;
word-break: break-word;
margin-bottom: 3.2rem;
}
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper {
display: grid;
grid-template-columns: 1fr;
gap: 2.4rem;
}
@media screen and (min-width: 990px) {
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper {
grid-template-columns: repeat(2, 1fr);
}
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper--1 {
grid-template-columns: 1fr;
}
}
@media screen and (min-width: 990px) {
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper {
gap: 3.2rem;
}
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper--1 {
grid-template-columns: 1fr;
}
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper--2 {
grid-template-columns: repeat(2, 1fr);
}
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper--3 {
grid-template-columns: repeat(3, 1fr);
}
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper--4 {
grid-template-columns: repeat(4, 1fr);
}
}
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper .multicolumn-list__item {
display: flex;
align-items: flex-start;
position: relative;
}
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper .multicolumn-list__item::after {
content: "";
position: absolute;
top: 0;
right: 0;
height: 100%;
width: 1px;
background-color: rgb(var(--color-border));
transform: translate(1.2rem);
}
@media screen and (min-width: 990px) {
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper .multicolumn-list__item::after {
transform: translate(1.6rem);
}
}
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper .multicolumn-list__item:nth-child(n)::after {
width: 0;
}
@media screen and (min-width: 990px) {
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper .multicolumn-list__item:nth-child(n)::after {
width: 1px;
}
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper--1 .multicolumn-list__item:nth-child(n)::after {
width: 0;
}
}
@media screen and (min-width: 1200px) {
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper .multicolumn-list__item:nth-child(n)::after {
width: 1px;
}
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper .multicolumn-list__item:nth-child(2n)::after {
width: 1px;
}
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper--1 .multicolumn-list__item:nth-child(1n)::after {
width: 0;
}
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper--2 .multicolumn-list__item:nth-child(2n)::after {
width: 0;
}
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper--3 .multicolumn-list__item:nth-child(3n)::after {
width: 0;
}
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper--4 .multicolumn-list__item:nth-child(4n)::after {
width: 0;
}
}
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper .multicolumn-card {
text-align: center;
max-width: 50rem;
margin: 0 auto;
padding: 3rem 6rem;
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
gap: 2.4rem;
}
@media screen and (min-width: 990px) {
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper .multicolumn-card {
max-width: 40rem;
padding: 3rem 0;
gap: 3.2rem;
}
}
@media screen and (min-width: 1200px) {
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper .multicolumn-card {
max-width: 33rem;
}
}
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper .multicolumn-card__info {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 1.6rem;
}
@media (min-width: 990px) {
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper .multicolumn-card__info {
gap: 2.4rem;
}
}
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper .multicolumn-card__info-title {
margin: 0;
}
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper .multicolumn-card__info-text {
font-size: calc(var(--font-body-size) * 0.75);
}
@media (min-width: 576px) {
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper .multicolumn-card__info-text {
font-size: var(--font-body-size);
}
}
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper .multicolumn-card__image-wrapper {
position: relative;
display: block;
overflow: hidden;
}
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper .multicolumn-card__image-item {
margin: 0 auto;
position: relative;
display: block;
}
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper .multicolumn-card__image-item img,
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper .multicolumn-card__image-item svg {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
}
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper .multicolumn-card__image-block {
margin: 0 auto;
}
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper .multicolumn-card__image-size-small {
width: 30%;
}
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper .multicolumn-card__image-size-medium {
width: 50%;
}
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper .multicolumn-card__image-size-large {
width: 100%;
}
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper .multicolumn-card__image-size-icon {
width: 56px;
}
@media (min-width: 576px) {
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper .multicolumn-card__image-size-icon {
width: 64px;
}
}
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper .multicolumn-card__image-ratio-landscape {
padding-bottom: 56.25%;
}
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper .multicolumn-card__image-ratio-portrait {
padding-bottom: 125%;
}
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper .multicolumn-card__image-ratio-square {
padding-bottom: 100%;
}
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper--1 .multicolumn-card {
max-width: 60rem;
}
@media screen and (min-width: 990px) {
.multicolumn .multicolumn__wrapper .multicolumn-list__wrapper--2 .multicolumn-card {
max-width: 40rem;
}
}
.multicolumn .multicolumn__wrapper .swiper-buttons__wrapper {
display: block;
}
@media screen and (min-width: 990px) {
.multicolumn .multicolumn__wrapper .swiper-buttons__wrapper {
display: none;
}
}
.multicolumn .multicolumn__wrapper .horizontal-alignment-left .multicolumn-card {
text-align: left;
align-items: flex-start;
}
.multicolumn .multicolumn__wrapper .horizontal-alignment-left .multicolumn-card__info {
align-items: flex-start;
}

.multicolumn .multicolumn__wrapper .swiper-initialized .multicolumn-list__wrapper {
display: flex;
gap: 0;
}
/*============================================================================*/
.newsletter-form__inner {
display: flex;
justify-content: center;
gap: 1rem;
flex-wrap: wrap;
width: 100%;
position: relative;
}
@media (min-width: 1200px) {
.newsletter-form__inner {
flex-direction: row;
gap: 0.4rem;
}
}
.newsletter-form__field-wrapper {
flex: 1;
width: 100%;
position: relative;
}
@media (min-width: 1200px) {
.newsletter-form__field-wrapper {
width: auto;
}
}
.newsletter-form__message {
margin-bottom: 0;
}
@media (min-width: 750px) {
.newsletter-form__message {
justify-content: flex-start;
}
}
.newsletter-form__message--success {
margin-top: 2rem;
}
@media (min-width: 750px) {
.newsletter-form__message--success {
width: 100%;
}
}
.newsletter-form__button {
margin-left: 1.4rem;
}
.newsletter-form .field {
min-height: 6.5rem;
}
.footer .newsletter__form {
max-width: 100%;
}
@media screen and (min-width: 1200px) {
.footer .newsletter__form {
max-width: 42.5rem;
width: 100%;
}
}
.newsletter__form {
max-width: 42.5rem;
width: 100%;
}
.newsletter__form .rte {
color: rgba(var(--color-foreground), 0.6);
}
.newsletter__form .rte a {
color: rgb(var(--color-link), 1);
transition: color var(--duration-default);
}
.newsletter__form .rte a:hover {
color: rgba(var(--color-foreground), 0.8);
}
.newsletter__title {
margin-bottom: 2.4rem;
}
.newsletter__text {
margin-top: 2.4rem;
}
.newsletter__text p {
font-size: 1.2rem;
margin: 0;
color: rgb(var(--color-foreground-secondary));
}
.newsletter .content_position-left {
margin-right: auto;
}
.newsletter .content_position-center {
margin: 0 auto;
}
.newsletter .content_position-right {
margin-left: auto;
}
.newsletter .newsletter__text {
margin-bottom: 0;
}
/*============================================================================*/
.list-payment {
display: flex;
flex-wrap: wrap;
justify-content: flex-start;
margin: 0;
padding: 0;
}
.list-payment__item {
align-items: center;
display: flex;
padding: 0.1rem;
}
/*============================================================================*/
.list-social {
display: flex;
flex-direction: row;
flex-wrap: wrap;
margin-left: -1.2rem;
margin-right: -1.2rem;
}
.list-social a {
padding: 1.2rem;
}
.list-social .footer-links_mobile-hidden {
display: none;
}
@media screen and (min-width: 1200px) {
.list-social {
flex-direction: column;
margin-left: 0;
margin-right: 0;
gap: 1rem;
}
.list-social a {
padding: 0;
}
.list-social .footer-links_mobile-hidden {
display: block;
}
}
.list-social__item .icon {
height: 2rem;
width: 2rem;
color: rgba(var(--color-social));
transition: var(--duration-default);
}
.list-social__link {
display: flex;
align-items: center;
gap: 1rem;
}
/*============================================================================*/
.ticker {
display: flex;
flex-direction: column;
padding: 20px 0px;
}
@media screen and (max-width: 1023px) {
.ticker {
padding: 15px 0 !important;
}
}
.ticker .container {
position: relative;
overflow: hidden;
max-width: 100%;
}
.ticker .swiper-wrapper {
transition-timing-function: linear !important;
width: 100vw;
}
.ticker .swiper-slide {
width: auto !important;
flex-shrink: 1;
display: inline-flex;
}
.ticker .ticker__title {
display: inline-flex;
text-transform: uppercase;
text-align: center;
align-items: center;
white-space: nowrap;
margin-left: 2.4rem;
}
.ticker .ticker__title h6 {
margin: 0;
display: flex;
font-size: 32px !important;
align-items: center;
}
.ticker .ticker__title h6::after {
content: "";
margin-left: 2rem;
display: inline-block;
width: 0.5em;
height: 0.5em;
max-width: 8px;
max-height: 8px;
background: rgb(var(--color-foreground-title));
}
@media screen and (max-width: 1023px) {
.ticker .ticker__title h6 {
font-size: 1.4rem !important;
}
}
/*============================================================================*/
.image-banner-section{
--image-height: 600px;
}
.image-banner {
position: relative;
z-index: 1;
display: flex;
align-items: center;
word-break: break-word;
}
.image-banner.have-overlay::before {
background: var(--slide-overlay);
}
.image-banner::after {
content: "";
display: block;
padding-bottom: var(--image-height);
}
.image-banner__img {
position: absolute;
left: 0;
top: 0;
width: 100%;
height: 100%;
z-index: -3;
}
.image-banner__img img {
width: 100%;
height: 100%;
object-fit: cover;
object-position: center;
}
.image-banner__block {
text-align: center;
margin: 0 auto;
max-width: 58rem;
display: flex;
flex-direction: column;
gap: 2rem;
position: relative;
z-index: 2;
padding: 3rem 0;
}
@media screen and (min-width: 990px) {
.image-banner__block {
gap: 4rem;
}
}
.image-banner__block-info {
display: flex;
flex-direction: column;
gap: 1rem;
}
@media screen and (min-width: 990px) {
.image-banner__block-info {
gap: 2rem;
}
}
.image-banner__block-header {
display: flex;
flex-direction: column;
gap: 1rem;
}
@media screen and (min-width: 990px) {
.image-banner__block-header {
gap: 1.6rem;
}
}
.image-banner__block-header-subheading {
margin: 0;
}
.image-banner .image-with-description__block-heading {
margin: 0;
}
.image-banner-author__position {
margin: 0;
opacity: 0.6;
}
/*============================================================================*/
.accordion summary {
display: flex;
position: relative;
line-height: 1;
padding: 1.5rem 0;
}

.accordion .summary__title {
display: flex;
flex: 1;
}

.accordion + .accordion {
margin-top: 0 !important;
border-top: none !important;
}

.accordion {
margin-top: 2.5rem !important;
margin-bottom: 0;
border-top: 0.1rem solid rgba(var(--color-foreground), 0.2) !important;
border-bottom: 0.1rem solid rgba(var(--color-foreground), 0.2);
}

.accordion__title {
display: inline-block;
max-width: calc(100% - 6rem);
min-height: 1.6rem;
margin: 0;
word-break: break-word;
}

.accordion .icon-accordion {
align-self: center;
min-width: 1.6rem;
margin-right: 1rem;
fill: rgb(var(--color-foreground));
}

.accordion details[open] > summary .icon-caret,
.accordion details[open] > summary .icon-filter-two {
transform: rotate(180deg);
}

.accordion__content {
margin-bottom: 1.5rem;
word-break: break-word;
}

.accordion__content img {
max-width: 100%;
}

.footer .accordion__title {
text-transform: var(--font-header-menu-text-transform);
}
/*============================================================================*/
.faq-header {
margin-bottom: 3rem;
}
@media screen and (min-width: 750px) {
.faq-header {
margin-bottom: 4rem;
}
}
@media screen and (min-width: 990px) {
.faq-header {
margin-bottom: 8rem;
}
}
.faq__title {
margin: 0;
}
.faq__subtitle:only-child {
margin: 0;
}
.faq-box {
margin: 0 auto;
max-width: 90rem;
}
.faq-block:last-child {
margin-bottom: 2.4rem;
}
.faq-block:last-child .faq-block__description p {
padding-top: 2.4rem;
}
.faq-block:last-child .accordion {
border-bottom: 0;
}
.faq-block:last-child .accordion summary {
padding-bottom: 0;
}
.faq-block:first-child .accordion {
border-top: 0 !important;
}
.faq-block:first-child .accordion summary {
padding-top: 0;
}
.faq-block + .faq-block .accordion {
border-top: none !important;
}
.faq-block__heading {
margin: 0;
padding-right: 2rem;
}
.faq-block .accordion {
margin: 0 !important;
}
.faq-block .accordion summary {
padding: 2.4rem 0;
transition: all var(--duration-default) ease;
}
.faq-block .accordion summary:hover {
opacity: 0.5;
}
.faq-block__description {
max-width: 77rem;
}
.faq-block__description p {
margin: 0;
padding-bottom: 2.4rem;
}
.faq .accordion.faq-item summary {
display: flex;
align-items: center;
justify-content: space-between;
}

@keyframes appear-down {
0% {
opacity: 0;
}
100% {
opacity: 1;
}
}
.faq details[open] summary ~ * {
animation: appear-down var(--duration-long) ease;
}
/*============================================================================*/
.featured_blog-padding{
padding-bottom: 80px;
}
.featured-blog {
--padding: 0.8rem;
}
@media screen and (min-width: 1360px) {
.featured-blog {
--padding: 1.6rem;
}
}
.featured-blog .featured-blog__wrapper {
display: flex;
flex-wrap: wrap;
margin: calc(-1 * var(--padding));
}
.featured-blog .article__wrapper {
flex: 1 0 50%;
padding: 0 var(--padding);
margin-bottom: 3.2rem;
}
.featured-blog .article__wrapper.article-wrapper__big, .featured-blog .article__wrapper.article-wrapper__small {
flex: 0 0 100%;
}
@media screen and (min-width: 1200px) {
.featured-blog .article__wrapper.article-wrapper__big, .featured-blog .article__wrapper.article-wrapper__small {
flex: 1 0 50%;
}
}
@media screen and (min-width: 750px) {
.featured-blog .article__wrapper {
margin-bottom: 0;
padding: var(--padding);
}
}
.featured-blog .article_img::after {
content: "";
position: absolute;
height: 100%;
top: 0;
width: 1px;
background: rgb(var(--color-border));
right: calc(-1 * var(--padding));
pointer-events: none;
}
.featured-blog .article__border .article_img::after {
width: 0;
}
@media screen and (min-width: 576px) {
.featured-blog .article__border .article_img::after {
display: block;
width: 1px;
}
}
.featured-blog .article__border:last-child .article_img::after, .featured-blog .article__border:nth-child(2n) .article_img::after {
width: 0;
}
.featured-blog .article-wrapper__big .article__block {
z-index: 1;
width: 100%;
padding: 2.4rem 2.8rem;
display: flex;
align-items: flex-end;
min-height: 540px;
}
@media screen and (min-width: 1200px) {
.featured-blog .article-wrapper__big .article__block {
padding: 4.8rem 5.6rem;
}
}
.featured-blog .article-wrapper__big .article_img {
position: absolute;
left: 0;
top: 0;
width: 100%;
height: 100%;
z-index: -2;
margin-bottom: 0;
}
.featured-blog .article-wrapper__big .article_img::after {
width: 0;
}
@media screen and (min-width: 1200px) {
.featured-blog .article-wrapper__big .article_img::after {
display: block;
width: 1px;
}
}
.featured-blog .article-wrapper__big .article_img::before {
content: "";
position: absolute;
width: 100%;
height: 50%;
left: 0;
bottom: 0;
background: var(--overlay-gradient);
}
.featured-blog .article-wrapper__big .article__info {
margin-bottom: 1.2rem;
}
.featured-blog .article-wrapper__big:nth-child(2) .article_img::after {
width: 0;
}
.featured-blog .article-wrapper__small {
display: flex;
flex-wrap: wrap;
padding: 0;
}
.featured-blog .article-wrapper__small .article__wrapper {
flex: 0 0 100%;
}
.featured-blog .article-wrapper__small .article__wrapper:first-child .article__block::before, .featured-blog .article-wrapper__small .article__wrapper:nth-child(2) .article__block::before {
content: "";
position: absolute;
bottom: calc(-1 * var(--padding));
left: 0;
width: 100%;
height: 1px;
background: rgb(var(--color-border));
display: none;
}
@media screen and (min-width: 1200px) {
.featured-blog .article-wrapper__small .article__wrapper:first-child .article__block::before, .featured-blog .article-wrapper__small .article__wrapper:nth-child(2) .article__block::before {
display: block;
}
}
.featured-blog .article-wrapper__small .article__wrapper:first-child .article__block::before {
width: calc(100% + 3.2rem);
}
@media screen and (min-width: 576px) {
.featured-blog .article-wrapper__small .article__wrapper {
flex: 0 0 50%;
}
}
.featured-blog .article-wrapper__small:nth-child(2n) .article_img::after {
display: block;
}
@media screen and (min-width: 576px) {
.featured-blog .article-wrapper__small:nth-child(4n) .article_img::after {
width: 0;
}
}
.featured-blog .article-wrapper__small__row {
display: flex;
flex-wrap: wrap;
padding: 0;
}
.featured-blog .article-wrapper__small__row .article__wrapper {
flex: 0 0 100%;
max-width: 100%;
}
@media screen and (min-width: 576px) {
.featured-blog .article-wrapper__small__row .article__wrapper {
flex: 0 0 50%;
max-width: 50%;
}
}
@media screen and (min-width: 1200px) {
.featured-blog .article-wrapper__small__row .article__wrapper {
flex: 0 0 25%;
max-width: 25%;
}
}
.featured-blog .article-wrapper__small__row .article__block::before {
display: none !important;
}
.featured-blog .article-wrapper__small__row .article__border .article_img::after {
width: 0;
}
@media screen and (min-width: 576px) {
.featured-blog .article-wrapper__small__row .article__border .article_img::after {
display: block;
width: 1px;
}
}
.featured-blog .article-wrapper__small__row .article__border:nth-child(2n) .article_img::after {
width: 0;
}
@media screen and (min-width: 1200px) {
.featured-blog .article-wrapper__small__row .article__border:nth-child(2n) .article_img::after {
display: block;
width: 1px;
}
}
.featured-blog .article-wrapper__small__row .article__border:nth-child(4n) .article_img::after {
width: 0;
}
.featured-blog .article-wrapper__small__row .article__border:last-child .article_img::after {
width: 0;
}
.featured-blog .article__grid .article__border:nth-child(2n) .article_img::after {
width: 0;
}
@media screen and (min-width: 1200px) {
.featured-blog .article__grid .article__border:nth-child(2n) .article_img::after {
display: block;
width: 1px;
}
}
.featured-blog .article__grid .article__border:nth-child(4n) .article_img::after {
width: 0;
}
/*============================================================================*/
.article__title {
margin: 0 0 1.2rem;
position: relative;
display: inline-block;
}
.article__title:last-child {
margin: 0;
}
.article__title a {
opacity: 1;
transition: all var(--duration-default) linear;
}
.article__title a:hover {
opacity: 0.6;
}
.article__desc {
text-transform: none;
}
.article__block {
height: 100%;
position: relative;
}
.article_img {
margin-bottom: 2rem;
display: block;
position: relative;
}
.article_img img,
.article_img svg {
max-width: 100%;
max-height: 100%;
width: 100%;
height: 100%;
object-fit: cover;
object-position: center;
position: absolute;
left: 0;
top: 0;
z-index: -2;
}
.article__info {
display: flex;
align-items: center;
margin-bottom: 0.8rem;
flex-wrap: wrap;
line-height: 1;
}
.article__info > * {
display: flex;
align-items: center;
margin-bottom: 0.2rem;
}
.article__info > *:last-child:after {
display: none;
}
.article__info > *:after {
content: "";
display: inline-block;
width: 4px;
height: 4px;
margin: 0 1.2rem;
background: rgb(var(--color-foreground-secondary));
}
.article__tags {
margin: 0 0 0.2rem;
padding: 0;
list-style: none;
}
.article__tags li.article__tags__delimiter {
margin: 0 0.5rem;
}
.article__author span {
margin-left: 0.5rem;
}
.article__text {
text-transform: uppercase;
}
.article .have-overlay::before {
background: rgba(var(--color-overlay), 0.2);
opacity: 0;
}
.article .have-overlay:hover::before {
opacity: 1;
}
/*============================================================================*/
.collection-grid__link {
z-index: 777;
}
.collection-grid__item {
position: relative;
height: 100%;
}
.collection-grid__title {
z-index: 99;
margin: 0;
padding: 20px;
}
@media screen and (min-width: 990px) {
.collection-grid__title {
padding: 32px;
}
}
.collection-grid__small-pad {
padding: 20px;
}
.collection-grid__image-wrapper {
position: relative;
display: block;
overflow: hidden;
z-index: 1;
overflow: hidden;
height: 100%;
}
.collection-grid__image-wrapper::before {
content: "";
position: absolute;
left: 0;
bottom: 0;
width: 100%;
height: 60%;
background: var(--overlay-gradient);
z-index: 5;
transition: all var(--duration-long) linear;
}
.collection-grid__image-item {
margin: 0 auto;
position: absolute;
top: 0;
left: 0;
display: block;
height: 100%;
width: 100%;
}
.collection-grid__image-item img,
.collection-grid__image-item svg {
width: 100%;
height: 100%;
}
.collection-grid__image-block {
display: flex;
align-items: flex-end;
position: relative;
margin: 0 auto;
height: 100%;
}
.collection-grid__image-block::before {
content: "";
display: block;
}


/*============================================================================*/
.featured-products {
overflow: hidden;
--gap: 1.6rem;
--per-row: 3;
--mobile-per-row: 1;
}
.featured-products__title {
margin: 0 0 2rem;
}
@media screen and (min-width: 750px) {
.featured-products__title {
margin: 0 0 3.2rem;
}
}
.featured-products__title:last-child {
margin-bottom: 0;
}
.featured-products__subtitle {
margin: 0 0 1.6rem;
}
.featured-products__subtitle:last-child {
margin-bottom: 0;
}
.featured-products__desc {
margin-bottom: 2rem;
}
@media screen and (min-width: 750px) {
.featured-products__desc {
margin: 0 0 4rem;
}
}
.featured-products__desc:last-child {
margin-bottom: 0;
}
@media screen and (min-width: 1200px) {
.featured-products {
--gap: 3.2rem;
}
}
.featured-products__list {
display: grid;
align-self: flex-start;
gap: var(--gap);
grid-template-columns: repeat(var(--mobile-per-row), 1fr);
flex: 1 1 auto;
padding: 0;
margin: 0;
list-style: none;
width: 100%;
}
@media screen and (min-width: 750px) {
.featured-products__list {
grid-template-columns: repeat(2, 1fr);
}
}
@media screen and (min-width: 1200px) {
.featured-products__list {
grid-template-columns: repeat(var(--per-row), 1fr);
}
}
@media screen and (max-width: 750px) {
.featured-products__list.featured-products__list-row li:not(:nth-child(2n)):not(:last-child) .card__inner {
position: relative;
}
.featured-products__list.featured-products__list-row li:not(:nth-child(2n)):not(:last-child) .card__inner::before {
content: "";
position: absolute;
width: 1px;
height: 100%;
background: rgb(var(--color-border));
right: calc(var(--gap) / -2);
pointer-events: none;
}
.featured-products__list.featured-products__list-row .card__link {
min-height: unset;
}
}
@media screen and (min-width: 750px) and (max-width: 1200px) {
.featured-products__list li:not(:nth-child(2n)):not(:last-child) .card__inner {
position: relative;
}
.featured-products__list li:not(:nth-child(2n)):not(:last-child) .card__inner::before {
content: "";
position: absolute;
width: 1px;
height: 100%;
background: rgb(var(--color-border));
right: calc(var(--gap) / -2);
pointer-events: none;
}
}
@media screen and (min-width: 1200px) {
.featured-products__list li:not(.hide_border):not(:last-child) .card__inner {
position: relative;
}
.featured-products__list li:not(.hide_border):not(:last-child) .card__inner::before {
content: "";
position: absolute;
width: 1px;
height: 100%;
background: rgb(var(--color-border));
right: calc(var(--gap) / -2);
pointer-events: none;
}
}
.featured-products__wrapper {
display: flex;
flex-direction: column;
}
@media screen and (min-width: 990px) {
.featured-products__wrapper {
flex-direction: row;
}
}
.featured-products__text {
width: 100%;
margin-bottom: 1.6rem;
padding-bottom: 3.2rem;
position: relative;
}
.featured-products__text:before {
content: "";
position: absolute;
bottom: 0;
width: 300vw;
height: 1px;
background: rgb(var(--color-border));
transform: translateX(-100vw);
}
@media screen and (min-width: 990px) {
.featured-products__text {
max-width: calc(300px + 3.2rem);
margin: 0 1.6rem 0 0;
padding: 0 3.2rem 0 0;
border: 0;
border-right: 1px solid rgb(var(--color-border));
}
.featured-products__text:before {
display: none;
}
}
@media screen and (min-width: 1360px) {
.featured-products__text {
max-width: calc(410px + 3.2rem);
}
}
.featured-products__desc {
max-width: 345px;
}
/*============================================================================*/
.countdown {
position: relative;
z-index: 1;
text-align: center;
padding: 80px 0px;
}
.countdown::before {
background: var(--countdown-overlay);
}
.countdown__wrapper {
max-width: 1000px;
padding: 1.5rem;
margin: 0 auto;
}
.countdown.countdown-content-left .countdown__block:first-child .countdown__block__num, .countdown.countdown-content-left .countdown__block:last-child .countdown__block__num, .countdown.countdown-content-right .countdown__block:first-child .countdown__block__num, .countdown.countdown-content-right .countdown__block:last-child .countdown__block__num {
min-width: unset;
}
.countdown.countdown-content-left .countdown__block:first-child .countdown__block__num, .countdown.countdown-content-right .countdown__block:first-child .countdown__block__num {
padding-left: 0;
}
.countdown.countdown-content-left .countdown__block:last-child .countdown__block__num, .countdown.countdown-content-right .countdown__block:last-child .countdown__block__num {
padding-right: 0;
}
.countdown.countdown-content-left .countdown__wrapper {
margin-left: 0;
}
.countdown.countdown-content-right .countdown__wrapper {
margin-right: 0;
}
.countdown__header {
margin: 0 0 2rem;
}
@media screen and (min-width: 750px) {
.countdown__header {
margin: 0 0 4rem;
}
}
.countdown__desc {
margin-top: 1.6rem;
}
@media screen and (min-width: 750px) {
.countdown__desc {
margin-top: 3.2rem;
}
}
.countdown__img {
position: absolute;
width: 100%;
height: 100%;
left: 0;
top: 0;
z-index: -2;
}
.countdown__img img {
width: 100%;
height: 100%;
max-width: 100%;
max-height: 100%;
object-fit: cover;
object-position: center;
}
.countdown__body {
margin-bottom: 2.4rem;
}
@media screen and (min-width: 750px) {
.countdown__body {
margin: 0 0 4.8rem;
}
}
.countdown__body:only-child {
margin-bottom: 0;
}
.countdown__main {
display: inline-flex;
justify-content: center;
padding-top: 1.6rem;
border-top: 1px solid rgba(255, 255, 255, 0.4);
}
.countdown__main .countdown__block {
display: flex;
flex-direction: column;
justify-content: center;
}
.countdown__main .countdown__block .countdown__block__num {
border-right: 1px solid rgba(255, 255, 255, 0.4);
padding: 0 1.2rem;
text-transform: capitalize;
text-align: center;
}
.countdown__main .countdown__block .countdown__block__num > div {
margin: 0;
}
.countdown__main .countdown__block .countdown__block__num p {
font-size: 10px;
font-weight: 500;
}
@media screen and (min-width: 576px) {
.countdown__main .countdown__block .countdown__block__num p {
font-size: calc(var(--font-body-size) * 0.875);
}
}
@media screen and (min-width: 750px) {
.countdown__main .countdown__block .countdown__block__num {
padding: 0 4rem;
}
}
@media screen and (min-width: 1200px) {
.countdown__main .countdown__block .countdown__block__num {
min-width: 185px;
padding: 0 5rem;
}
}
@media screen and (min-width: 1360px) {
.countdown__main .countdown__block .countdown__block__num {
padding: 0 6.4rem;
min-width: 215px;
}
}
.countdown__main .countdown__block:last-child .countdown__block__num {
border-right: none;
}
.countdown__header-title {
margin: 0;
}
.countdown__heading {
display: none;
margin-bottom: 3rem;
}
@media screen and (min-width: 990px) {
.countdown__heading {
margin-bottom: 5rem;
}
}
.countdown__heading h3 {
margin: 0;
}

@media screen and (min-width: 1200px) {
.style-bottom .countdown__main {
margin-top: 1rem;
}
}
.style-bottom .countdown__img {
position: relative;
max-height: 600px;
}
.style-bottom .countdown__img img {
max-height: inherit;
}
.style-bottom .countdown__wrapper {
max-width: 100%;
width: 100%;
padding: 4rem 3rem;
display: flex;
align-items: center;
justify-content: space-between;
flex-direction: column;
}
.style-bottom .countdown__wrapper.show-text {
justify-content: center;
}
.style-bottom .countdown__wrapper.show-text .countdown-down__wrapper,
.style-bottom .countdown__wrapper.show-text .countdown__header-title,
.style-bottom .countdown__wrapper.show-text .countdown__button-wrapper,
.style-bottom .countdown__wrapper.show-text .countdown__desc {
text-align: center;
}
.style-bottom .countdown__wrapper.show-text .countdown-down__wrapper {
margin: 0;
}
@media screen and (min-width: 1200px) {
.style-bottom .countdown__wrapper {
flex-direction: row;
align-items: flex-start;
}
.style-bottom .countdown__wrapper.countdown__wrapper-center {
align-items: center;
}
}
@media screen and (min-width: 1360px) {
.style-bottom .countdown__wrapper {
padding: 6.5rem 6rem;
}
}
.style-bottom .countdown__header,
.style-bottom .countdown__button-wrapper {
text-align: center;
}
@media screen and (min-width: 1200px) {
.style-bottom .countdown__header,
.style-bottom .countdown__button-wrapper {
text-align: left;
}
}
.style-bottom .countdown-down__wrapper {
margin: 0 0 2rem;
text-align: left;
}
@media screen and (min-width: 1200px) {
.style-bottom .countdown-down__wrapper {
margin: 0 4rem 0 0;
}
}
.style-bottom .countdown__body {
margin: 0;
padding: 0;
}

@media screen and (max-width: 1920px) {
.countdown > .container {
padding: 0;
}
}
@media screen and (min-width: 1920px) {
.countdown .countdown__wrapper {
padding-left: 0;
padding-right: 0;
}
}
/*============================================================================*/
.image-with-text {
display: flex;
justify-content: center;
min-height: 740px;
flex-direction: column;
}
@media screen and (min-width: 990px) {
.image-with-text {
flex-direction: row;
}
}
.image-with-text .ticker .ticker__title h6 {
line-height: 1;
}
.image-with-text .image-with-text__block-text {
flex: 1 0 40%;
display: flex;
position: relative;
z-index: 1;
padding: 30px 0;
}
@media screen and (min-width: 990px) {
.image-with-text .image-with-text__block-text {
padding: 0;
}
}
.image-with-text .image-with-text__block-text.have_border::after {
content: "";
position: absolute;
height: 1px;
background: rgb(var(--color-border));
width: 100%;
top: 0;
}
@media screen and (min-width: 990px) {
.image-with-text .image-with-text__block-text.have_border::after {
height: 100%;
width: 1px;
}
}
.image-with-text .image-with-text__block-text.have_border.border-position-left::after {
left: unset;
top: 15px;
}
@media screen and (min-width: 990px) {
.image-with-text .image-with-text__block-text.have_border.border-position-left {
padding-left: 15px;
}
.image-with-text .image-with-text__block-text.have_border.border-position-left::after {
left: 15px;
top: 0;
}
}
.image-with-text .image-with-text__block-text.have_border.border-position-right::after {
bottom: 15px;
right: unset;
top: unset;
}
@media screen and (min-width: 990px) {
.image-with-text .image-with-text__block-text.have_border.border-position-right {
padding-right: 15px;
}
.image-with-text .image-with-text__block-text.have_border.border-position-right::after {
right: 15px;
bottom: unset;
top: 0;
}
}
.image-with-text .image-with-text__block-text .image-with-text__block-text__wrapper {
margin: auto;
max-width: calc(465px + 12rem);
width: 100%;
padding: 8rem 6rem;
}
.image-with-text .image-with-text__block-text .image-with-text__block-text__wrapper.left-center {
margin: auto auto auto 0;
}
.image-with-text .image-with-text__block-text .image-with-text__block-text__wrapper.left-top {
margin: 0 auto auto 0;
}
.image-with-text .image-with-text__block-text .image-with-text__block-text__wrapper.left-bottom {
margin: auto auto 0 0;
}
.image-with-text .image-with-text__block-text .image-with-text__block-text__wrapper.center-center {
margin: auto;
}
.image-with-text .image-with-text__block-text .image-with-text__block-text__wrapper.center-top {
margin: 0 auto auto;
}
.image-with-text .image-with-text__block-text .image-with-text__block-text__wrapper.center-bottom {
margin: 0 auto auto;
}
.image-with-text .image-with-text__block-text .image-with-text__block-text__wrapper.right-center {
margin: auto 0 auto auto;
}
.image-with-text .image-with-text__block-text .image-with-text__block-text__wrapper.right-top {
margin: 0 0 auto auto;
}
.image-with-text .image-with-text__block-text .image-with-text__block-text__wrapper.right-bottom {
margin: auto 0 0 auto;
}
.image-with-text .image-with-text__block-text .image-with-text__title {
margin: 0 0 3.2rem;
}
.image-with-text .image-with-text__block-text .image-with-text__title:last-child {
margin-bottom: 0;
}
.image-with-text .image-with-text__block-text .image-with-text__text {
margin-bottom: 2rem;
}
.image-with-text .image-with-text__block-text .image-with-text__text:last-child {
margin-bottom: 0;
}
@media screen and (min-width: 750px) {
.image-with-text .image-with-text__block-text .image-with-text__text {
margin-bottom: 4rem;
}
}
.image-with-text .image-with-text__block-image {
flex: 1 0 55%;
position: relative;
z-index: 1;
overflow: hidden;
min-height: 300px;
display: flex;
}
@media screen and (min-width: 990px) {
.image-with-text .image-with-text__block-image {
min-height: unset;
}
}
.image-with-text .image-with-text__block-image::before {
background: var(--image-with-text-color-overlay);
}
.image-with-text .image-with-text__block-image .image-with-text__ticker {
position: absolute;
left: 0;
top: 0;
}
.image-with-text .image-with-text__block-image.have_border .image-with-text__ticker::after {
content: "";
position: absolute;
width: 100%;
height: 1px;
background: rgb(var(--color-border));
left: 0;
}
.image-with-text .image-with-text__block-image.have_border.border-position-left .image-with-text__ticker::after {
display: none;
}
@media screen and (min-width: 990px) {
.image-with-text .image-with-text__block-image.have_border.border-position-left .image-with-text__ticker::after {
display: block;
top: 15px;
bottom: unset;
}
}
.image-with-text .image-with-text__block-image.have_border.border-position-right .image-with-text__ticker::after {
display: none;
}
@media screen and (min-width: 990px) {
.image-with-text .image-with-text__block-image.have_border.border-position-right .image-with-text__ticker::after {
display: block;
bottom: 15px;
top: unset;
}
}
.image-with-text .image-with-text__block-image .image-with-text__img-text {
margin: auto;
max-width: calc(480px + 8rem);
padding: 4rem;
text-align: center;
}
.image-with-text .image-with-text__block-image .image-with-text__img {
position: absolute;
width: 100%;
height: 100%;
left: 0;
top: 0;
z-index: -2;
}
.image-with-text .image-with-text__block-image .image-with-text__img svg,
.image-with-text .image-with-text__block-image .image-with-text__img img {
width: 100%;
height: 100%;
max-width: 100%;
max-height: 100%;
object-fit: cover;
object-position: center;
}
.image-with-text .image-with-text__block-image .image-with-text__ticker-wrapper {
position: absolute;
bottom: unset;
right: unset;
transform: unset;
}
@media screen and (min-width: 990px) {
.image-with-text .image-with-text__block-image .image-with-text__ticker-wrapper.ticker-left {
left: 0;
bottom: 0;
transform: rotate(-90deg);
}
.image-with-text .image-with-text__block-image .image-with-text__ticker-wrapper.ticker-right {
right: 0;
bottom: 0;
transform: rotate(-90deg);
}
.image-with-text .image-with-text__block-image .image-with-text__ticker-wrapper.ticker-right .image-with-text__ticker {
transform: translateY(-100%);
}
.image-with-text .image-with-text__block-image .image-with-text__ticker-wrapper.ticker-top {
left: 0;
top: 0;
}
.image-with-text .image-with-text__block-image .image-with-text__ticker-wrapper.ticker-bottom {
bottom: 0;
left: 0;
}
.image-with-text .image-with-text__block-image .image-with-text__ticker-wrapper.ticker-bottom .image-with-text__ticker {
transform: translateY(-100%);
}
}
.image-with-text .image-with-text__block.col-3, .image-with-text .image-with-text__block.in-two {
flex: 0 0 100%;
max-width: 100%;
}
@media screen and (min-width: 990px) {
.image-with-text .image-with-text__block.col-3 {
flex: 0 0 33.33%;
max-width: 33.33%;
}
.image-with-text .image-with-text__block.col-3.image-with-text__block-image {
flex: 0 0 30.4%;
max-width: 30.4%;
}
.image-with-text .image-with-text__block.in-two {
flex: 0 0 50%;
max-width: 50%;
}
}
/*============================================================================*/
.product-markers-section{
padding-top: 0px;
padding-bottom: 80px;
}
.product-markers__box {
display: flex;
justify-content: space-between;
flex-direction: column;
}
@media screen and (min-width: 990px) {
.product-markers__box {
flex-direction: row;
align-items: center;
}
}
.product-markers__img {
width: 100%;
position: relative;
z-index: 1;
max-width: 100%;
padding-bottom: 1.6rem;
margin-bottom: 1.6rem;
}
.product-markers__img::before {
content: "";
position: absolute;
right: 0;
bottom: 0;
width: 100%;
height: 1px;
background-color: rgb(var(--color-border));
}
.product-markers__img img {
width: 100%;
height: 100%;
max-width: 100%;
max-height: 100%;
object-fit: cover;
object-position: center;
}
@media screen and (min-width: 990px) {
.product-markers__img {
padding: 0 1.6rem 0 0;
margin: 0 1.6rem 0 0;
max-width: 50%;
}
.product-markers__img::before {
width: 1px;
height: 100%;
}
}
.product-markers__img-box {
overflow: hidden;
height: 100%;
position: relative;
display: flex;
z-index: 1;
}
.product-markers__product-wrapper {
max-width: 100%;
margin: 0 auto;
position: relative;
}
@media screen and (min-width: 990px) {
.product-markers__product-wrapper {
width: 28%;
}
}
.product-markers__product-wrapper .card-wrapper .product-form__controls-group {
display: none;
}
.product-markers__slider {
margin: 0 auto;
padding-bottom: 3rem;
}
.product-markers__marker {
position: absolute;
box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
width: 2.4rem;
height: 2.4rem;
background-color: rgb(var(--color-background));
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
transition: all var(--duration-default) linear;
cursor: pointer;
}
@media screen and (min-width: 990px) {
.product-markers__marker {
width: 3.2rem;
height: 3.2rem;
}
}
.product-markers__marker svg {
width: 0.6rem;
height: 0.6rem;
transition: inherit;
}
@media screen and (min-width: 990px) {
.product-markers__marker svg {
width: 1.2rem;
height: 1.2rem;
}
}
.product-markers__marker.active, .product-markers__marker:hover {
transform: scale(1.75);
}
.product-markers__marker.active svg, .product-markers__marker:hover svg {
transform: scale(0.57);
}
.product-markers .swiper-slide {
height: auto;
}
.product-markers .swiper-button-absolute {
transform: translateY(-200%);
}
.product-markers .swiper-pagination {
bottom: 0 !important;
}
@media screen and (max-width: 990px) {
.product-markers .swiper-button-absolute {
background-color: rgba(255, 255, 255, 0.3);
display: flex;
align-items: center;
justify-content: center;
z-index: 999;
}
.product-markers .swiper-button-absolute.swiper-button-next {
right: 1.6rem;
left: unset;
}
.product-markers .swiper-button-absolute.swiper-button-prev {
left: 1.6rem;
right: unset;
}
}

@media screen and (prefers-color-scheme: dark) and (max-width: 990px) {
[data-scheme=auto] .swiper-button-absolute {
background-color: rgba(0, 0, 0, 0.3);
}
}
@media screen and (max-width: 990px) {
[data-scheme=dark] .swiper-button-absolute {
background-color: rgba(0, 0, 0, 0.3);
}
}
/*============================================================================*/
.page-hero-section{
padding-top: 60px;
}
.page-hero {
--page-hero-gap: 1.6rem;
display: grid;
gap: 3.2rem;
}
@media screen and (min-width: 990px) {
.page-hero {
gap: 8rem;
}
}
@media screen and (min-width: 750px) {
.page-hero {
--page-hero-gap: 3.2rem;
}
}
.page-hero .page-hero__imgs-wrapper {
display: flex;
flex-wrap: wrap;
position: relative;
z-index: 1;
gap: var(--page-hero-gap);
--image-size:calc(100vh - 70px - 40px);
}
.page-hero .page-hero__img {
position: relative;
z-index: 1;
flex: 0 0 100%;
}
@media screen and (min-width: 990px) {
.page-hero .page-hero__img {
flex: 1 0 calc(50% - var(--page-hero-gap) / 2);
}
}
.page-hero .page-hero__img img,
.page-hero .page-hero__img svg {
max-width: 100%;
max-height: 100%;
min-width: auto;
min-height: auto;
width: 100%;
height: 100%;
object-fit: cover;
object-position: center;
position: absolute;
left: 0;
top: 0;
}
.page-hero .page-hero__img::before {
content: "";
display: block;
padding-bottom: var(--image-size);
}
.page-hero .page-hero__img.page-hero__secondary:not(:only-child):after {
content: "";
position: absolute;
left: 0;
top: calc(-1 * var(--page-hero-gap) / 2);
width: 100%;
height: 1px;
background: rgb(var(--color-border));
}
@media screen and (min-width: 990px) {
.page-hero .page-hero__img.page-hero__secondary:not(:only-child):after {
left: calc(-1 * var(--page-hero-gap) / 2);
top: 0;
width: 1px;
height: 100%;
}
}
.page-hero .page-hero__title {
margin: 0;
}
.image-with-description__wrapper {
flex-wrap: wrap;
display: flex;
gap: 3.2rem;
word-break: break-word;
}
@media screen and (min-width: 990px) {
.image-with-description__wrapper {
flex-wrap: nowrap;
}
}
.image-with-description__box {
flex: 0 0 100%;
max-width: 100%;
gap: 2rem;
display: flex;
flex-direction: column;
}
@media screen and (min-width: 990px) {
.image-with-description__box {
gap: 3.2rem;
flex: 0 0 calc(50% - 1.6rem);
max-width: calc(50% - 1.6rem);
}
}
@media screen and (min-width: 1200px) {
.image-with-description__box {
gap: 4.8rem;
}
}
.image-with-description .alignment__horizontal-left {
align-items: flex-start;
}
.image-with-description .alignment__horizontal-center {
align-items: center;
text-align: center;
}
.image-with-description .alignment__horizontal-center .image-with-description__item-heading {
justify-content: center;
}
.image-with-description__block {
display: flex;
flex-direction: column;
gap: 1.6rem;
max-width: 100%;
}
.image-with-description__block-subheading, .image-with-description__block-heading {
margin: 0;
}
.image-with-description__image {
flex: 0 0 100%;
max-width: 100%;
}
@media screen and (min-width: 990px) {
.image-with-description__image {
flex: 0 0 calc(50% - 1.6rem);
max-width: calc(50% - 1.6rem);
}
}
.image-with-description__image__block {
position: relative;
width: 100%;
height: 100%;
}
.image-with-description__image__block::after {
content: "";
position: absolute;
top: 0;
width: 0;
height: 100%;
background-color: rgb(var(--color-border));
}
@media screen and (min-width: 990px) {
.image-with-description__image__block::after {
width: 1px;
}
}
.image-with-description__image__block img {
width: 100%;
height: 100%;
}
.image-with-description .image-position-right .image-with-description__image__block::after {
left: 0;
transform: translate(-1.6rem);
}
.image-with-description .image-position-left {
display: flex;
order: -1;
}
.image-with-description .image-position-left .image-with-description__image__block::after {
right: 0;
transform: translate(1.6rem);
}
.image-with-description__item {
max-width: 100%;
display: flex;
gap: 1.6rem;
flex-direction: column;
}
@media screen and (min-width: 750px) {
.image-with-description__item {
gap: 2.4rem;
}
}
@media screen and (min-width: 1200px) {
.image-with-description__item {
gap: 4rem;
}
}
.image-with-description__item-elem {
display: flex;
flex-direction: column;
gap: 0.8rem;
}
@media screen and (min-width: 576px) {
.image-with-description__item-elem {
gap: 1.6rem;
}
}
@media screen and (min-width: 1200px) {
.image-with-description__item-elem {
gap: 2rem;
}
}
.image-with-description__item-heading {
display: flex;
align-items: center;
gap: 1.2rem;
}
.image-with-description__item-heading-title {
word-break: break-word;
margin: 0;
}
.image-with-description__item-heading-text {
word-wrap: break-word;
}
.image-with-description__item-icon {
max-width: 28px;
flex: none;
}
.image-with-description__item-icon img {
height: auto;
max-width: 100%;
display: block;
}

.image-with-description__wrapper.image-with-description__wrapper-flex .image-with-description__box {
flex: 0 0 100%;
max-width: 100%;
}
.image-with-description__wrapper.image-with-description__wrapper-flex .image-with-description__item {
max-width: 100rem;
}
/*============================================================================*/
.blockqoute__wrapper {
flex-wrap: wrap;
display: flex;
gap: 2rem;
word-break: break-word;
}
@media screen and (min-width: 990px) {
.blockqoute__wrapper {
flex-wrap: nowrap;
gap: 3.2rem;
}
}
.blockqoute__wrapper .blockqoute__image {
flex: 0 0 100%;
max-width: 100%;
}
@media screen and (min-width: 990px) {
.blockqoute__wrapper .blockqoute__image {
flex: 0 0 40%;
max-width: 40%;
}
}
.blockqoute__wrapper .blockqoute__image__block {
position: relative;
width: 100%;
height: 100%;
}
.blockqoute__wrapper .blockqoute__image__block::after {
content: "";
position: absolute;
top: 0;
width: 0;
height: 100%;
background-color: rgb(var(--color-border));
}
@media screen and (min-width: 990px) {
.blockqoute__wrapper .blockqoute__image__block::after {
width: 1px;
}
}
.blockqoute__wrapper .blockqoute__image__block img {
width: 100%;
height: 100%;
}
.blockqoute__wrapper .image-position-left .blockqoute__image__block::after {
right: 0;
transform: translate(1.6rem);
}
.blockqoute__wrapper .image-position-right {
display: flex;
order: 2;
}
.blockqoute__wrapper .image-position-right .blockqoute__image__block::after {
left: 0;
transform: translate(-1.6rem);
}
.blockqoute__wrapper .blockqoute__box {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
text-align: center;
flex: 0 0 100%;
max-width: 100%;
}
@media screen and (min-width: 990px) {
.blockqoute__wrapper .blockqoute__box {
flex: 0 0 calc(60% - 3.2rem);
max-width: calc(60% - 3.2rem);
}
}
.blockqoute__wrapper .blockqoute__box-text {
position: relative;
margin: 0 0 4rem;
word-break: break-word;
}
.blockqoute__wrapper .blockqoute__box-text__elem {
display: block;
font-family: inherit;
}
.blockqoute__wrapper .blockqoute__box-author__name {
color: rgb(var(--color-foreground-title));
}
.blockqoute__wrapper .blockqoute__box-author__position {
margin: 0;
}
.blockqoute__wrapper .blockqoute__box .blockqoute__block {
max-width: 90rem;
padding: 0;
}
@media screen and (min-width: 990px) {
.blockqoute__wrapper .blockqoute__box .blockqoute__block {
padding: 7rem 0;
}
}

.blockqoute__wrapper.blockqoute__wrapper-flex .blockqoute__box {
flex: 0 0 100%;
max-width: 100%;
}
/*============================================================================*/
.no-js details[open] > .header__icon--search {
top: 1rem;
right: 0.5rem;
}

.search__form-inner .field {
gap: 0.5rem;
}

.search__input.field__input {
min-height: 6.5rem;
}
.search__input.field__input:hover:not(:focus) {
border-color: rgb(var(--color-border-input-hover));
}

@keyframes animateSearchOpen {
0% {
opacity: 0;
}
100% {
opacity: 1;
}
}
@keyframes animateSearchOpacity {
0% {
opacity: 0;
}
50% {
opacity: 0;
}
100% {
opacity: 1;
}
}
/* Remove extra spacing for search inputs in Safari */
input::-webkit-search-decoration {
-webkit-appearance: none;
}

.search__categories {
margin-top: 2.4rem;
width: 100%;
}

.search__categories-wrapper {
overflow-x: auto;
overflow-y: hidden;
}

.search__categories-list {
display: flex;
align-items: center;
justify-content: flex-start;
gap: 0.8rem;
margin: 0;
padding: 0;
width: 100%;
list-style: none;
}

.search__categories-item {
display: flex;
align-items: center;
justify-content: flex-start;
flex: none;
}
.search__categories-item .search__categories-link {
position: relative;
padding: 0.5rem 1rem;
text-align: center;
text-transform: none;
text-decoration: none;
font-size: 1.2rem;
border: 0.1rem solid rgb(var(--color-border));
border-radius: 3rem;
transition: border var(--duration-default);
}
.search__categories-item .search__categories-link:hover {
border-color: rgb(var(--color-button));
}

@media screen and (max-width: 381px) {
.line-divider::after {
margin-left: 3px;
margin-right: 3px;
}
.search__articles {
margin-top: 3.2rem;
}
}
.reser-search {
position: absolute;
background: transparent;
outline: none;
border: 0;
width: 20px;
height: 20px;
display: flex;
align-items: center;
justify-content: center;
padding: 0.5rem;
cursor: pointer;
right: 15px;
top: 50%;
transform: translateY(-50%);
}
.reser-search .icon {
width: 100%;
height: 100%;
}

.search__input:placeholder-shown + label + .reser-search {
display: none;
}

.search__input-wrapper {
width: 100%;
position: relative;
}

.search-modal {
display: block !important;
position: fixed;
right: 0;
top: 0;
z-index: 1001;
width: 100%;
max-width: 64rem;
height: 100vh;
overflow: auto;
transform: translateX(150%);
transition: all var(--duration-default) linear;
}
.search-modal.active {
transform: translateX(0);
}
.search-modal__wrapper {
height: 100%;
display: flex;
flex-direction: column;
}
.search-modal__wrapper > *:nth-last-child(2):not(.search-modal__search-form) {
margin-top: auto;
}
.search-modal__wrapper > *:nth-last-child(3):not(.search-modal__title) {
margin-bottom: 3.2rem;
}
@media screen and (min-width: 576px) {
.search-modal__wrapper > *:nth-last-child(3):not(.search-modal__title) {
margin-bottom: 6.4rem;
}
}
.search-modal__colection {
position: relative;
}
.search-modal__colection .collection-grid__image-item img {
transition: all var(--duration-default) ease;
}
.search-modal__colection:hover .collection-grid__image-item img {
transform: scale(1.1);
}
.search-modal__title {
margin: 0;
padding: 4.2rem 3rem 0;
}
.search-modal__title p {
margin: 0;
padding: 0;
}
@media screen and (min-width: 576px) {
.search-modal__title {
padding: 6.4rem 6rem 0;
}
}
.search-modal__collections {
padding: 2.8rem 3rem 0;
}
@media screen and (min-width: 576px) {
.search-modal__collections {
padding: 5.6rem 6rem 0;
}
}
.search-modal__subtitle {
margin: 0 0 2rem;
}
.search-modal__subtitle p {
margin: 0;
}
.search-modal__collections-list {
display: flex;
flex-wrap: wrap;
column-gap: 2.4rem;
row-gap: 1rem;
}
.search-modal__search-form {
padding: 2rem 3rem 0;
}
.search-modal__search-form:first-child {
padding: 4.2rem 3rem 0;
}
@media screen and (min-width: 576px) {
.search-modal__search-form:first-child {
padding: 6.4rem 6rem 0;
}
}
@media screen and (min-width: 576px) {
.search-modal__search-form {
padding: 4rem 6rem 0;
}
}
.search-modal__close {
position: absolute;
right: 2rem;
top: 2rem;
}
.search-modal::-webkit-scrollbar {
width: 3px;
height: 3px;
}
.search-modal::-webkit-scrollbar-thumb {
background-color: rgba(var(--color-foreground), 0.7);
border-radius: 100px;
}

.search-modal__mask {
position: fixed;
left: 0;
top: 0;
width: 100vw;
height: 100vh;
z-index: 1000;
visibility: hidden;
background-color: rgba(var(--color-overlay), 0.2);
}
.search-modal__mask.active {
visibility: visible;
}
#searchcard	.collection-grid__image-block::before {
padding-bottom: 56.25%;
}
.search-modal__search-form ul{
margin: 0;
padding: 0;
list-style: none;
}
.search-modal__search-form ul li{
display: inline-block;
width: 100%;
}
.search-modal__search-form ul li a{
text-decoration: none;
display: inline-block;
line-height: 30px;
}
/*============================================================================*/
.drawer {
visibility: hidden;
position: fixed;
}
.drawer__inner-empty {
padding: 2rem 3rem;
}
@media screen and (min-width: 750px) {
.drawer__inner-empty {
padding: 4rem 6rem;
}
}

.cart__empty-text {
font-family: var(--font-body-family);
font-weight: 400;
color: var(--color-foreground);
margin-bottom: 2rem;
text-transform: unset;
}
@media screen and (min-width: 750px) {
.cart__empty-text {
margin-bottom: 4rem;
}
}

.drawer__inner__collection {
position: relative;
margin-top: auto;
}
.drawer__inner__collection .collection-grid__image-block::before {
padding-bottom: 100%;
}

.drawer.active .drawer__inner {
transform: translateX(0);
}

cart-remove-button {
display: flex;
}
@media screen and (min-width: 990px) {
cart-remove-button {
display: inline-flex;
}
}
.drawer {
position: fixed;
z-index: 1000;
left: 0;
top: 0;
width: 100vw;
height: 100%;
display: flex;
justify-content: flex-end;
background-color: rgba(0, 0, 0, 0.2);
transition: visibility var(--duration-default) ease;
}
.drawer.active {
visibility: visible;
}
.drawer__contents {
flex-grow: 1;
display: flex;
flex-direction: column;
}
.drawer__inner {
position: relative;
display: flex;
flex-direction: column;
height: 100%;
width: 64rem;
max-width: 100vw;
background-color: rgb(var(--color-background));
transform: translateX(100%);
transition: transform var(--duration-default) ease;
overflow: auto;
}
.drawer__header {
border-bottom: 0.1rem solid rgb(var(--color-border));
padding: 3.5rem 3rem 2rem;
}
@media screen and (min-width: 750px) {
.drawer__header {
padding: 6.5rem 6rem 3rem;
}
}
.drawer__heading {
margin-bottom: 0;
margin-top: 0;
}
.drawer__additional-text {
display: flex;
align-items: flex-start;
color: rgb(var(--color-foreground-secondary));
gap: 1rem;
margin-top: 2rem;
}
.drawer__additional-text svg {
min-width: 24px;
}
.drawer__close {
position: absolute;
top: 1.6rem;
right: 1.6rem;
}
@media screen and (min-width: 750px) {
.drawer__close {
top: 2.3rem;
right: 2.3rem;
}
}
.drawer__footer {
margin-top: auto;
padding: 2rem 3rem;
display: flex;
flex-direction: column;
justify-content: space-between;
border-top: 0.1rem solid rgb(var(--color-border));
}
@media screen and (min-width: 750px) {
.drawer__footer {
padding: 3rem 6rem;
}
}
.drawer .cart-item {
padding: 1.6rem 3rem;
border-bottom: 0.1rem solid rgb(var(--color-border));
}
@media screen and (min-width: 750px) {
.drawer .cart-item {
padding: 1.6rem 6rem;
}
}
.drawer .cart-item__media {
max-width: 15rem;
height: 16rem;
width: 100%;
margin-bottom: auto;
}
.drawer .cart-item__details {
width: 100%;
padding: 1.5rem 0 0;
display: flex;
flex-direction: column;
align-items: flex-start;
}
.drawer .cart-item__inner-top {
display: flex;
column-gap: 3.2rem;
flex-direction: column;
align-items: flex-start;
}
@media screen and (min-width: 750px) {
.drawer .cart-item__inner-top {
flex-direction: row;
align-items: unset;
}
}
.drawer .cart-item__image {
width: 100%;
height: 100%;
object-fit: cover;
object-position: top;
}
.drawer .cart-item__vendor {
margin-bottom: 0.6rem;
line-height: 1;
}
.drawer .cart-item__name {
display: block;
margin-bottom: 0.8rem;
font-family: var(--font-heading-family);
font-weight: var(--font-heading-weight);
font-size: 1.5rem;
color: rgb(var(--color-foreground));
text-decoration: none;
position: relative;
padding-bottom: 0.2rem;
}
@media screen and (min-width: 750px) {
.drawer .cart-item__name {
font-size: 2rem;
margin-bottom: 2rem;
}
}
.drawer .cart-item__name::before {
content: "";
position: absolute;
left: 0;
bottom: 0;
width: 0;
height: 1px;
background: currentColor;
transition: all var(--duration-default) linear;
}
.drawer .cart-item__name:hover::before {
width: 100%;
}
.drawer .cart-item__final-price, .drawer .cart-item__old-price {
line-height: var(--font-body-line-height);
letter-spacing: 0.06em;
}
.drawer .cart-item__final-price {
font-weight: 500;
color: rgb(var(--color-foreground-title));
}
.drawer .cart-item__old-price {
font-weight: 400;
color: rgba(var(--color-foreground-secondary));
}
.drawer .cart-item dl {
display: flex;
flex-wrap: wrap;
gap: 0.2rem 0.4rem;
margin: 0 0 1.6rem;
}
@media screen and (min-width: 750px) {
.drawer .cart-item dl {
margin: 0 0 3rem;
}
}
.drawer .cart-item dl .product-option + .product-option {
position: relative;
padding-left: 0.8rem;
}
.drawer .cart-item dl .product-option + .product-option::before {
position: absolute;
content: ",";
left: -0.2rem;
top: 0;
}
.drawer .cart-item .product-option {
display: flex;
flex-wrap: wrap;
gap: 0.2rem;
font-size: 1.4rem;
color: rgba(var(--color-foreground-secondary));
font-weight: var(--font-subtitle-weight);
}
.drawer .cart-item .product-option dd {
margin: 0;
}
.drawer .cart-item__totals {
position: relative;
}
.drawer .cart-item__totals .loading-overlay {
position: absolute;
z-index: 1;
width: 1.8rem;
left: 0;
top: 0;
bottom: 0;
}
.drawer .cart-item__totals .loading-overlay:not(.hidden) + .cart-item__price-wrapper {
opacity: 0.5;
}
.drawer .cart-item__totals .loading-overlay__spinner {
position: absolute;
top: 50%;
left: 0;
width: 1.8rem;
height: 100%;
transform: translateY(-50%);
}
.drawer .cart-item__price-wrapper {
display: flex;
flex-wrap: wrap;
margin-top: 0.8rem;
}
.drawer .cart-item__discounted-prices {
display: flex;
flex-wrap: wrap;
justify-content: flex-end;
column-gap: 0.6rem;
}
.drawer .cart-item__quantity-price {
display: flex;
align-items: center;
justify-content: space-between;
gap: 0.8rem;
margin-top: auto;
width: 100%;
}
.drawer .cart-item .quantity {
width: 10rem;
height: 3rem;
}
.drawer .cart-item .quantity__button {
width: 3rem;
}
.drawer .cart-item .quantity__input {
height: 3rem;
font-size: 1.2rem;
}
.drawer .cart-item__error {
display: flex;
align-items: flex-start;
margin-top: 0.4rem;
}
.drawer .cart-item__error-text {
font-size: 1.2rem;
line-height: var(--font-body-line-height);
order: 1;
}
.drawer .cart-item__error-text:empty + svg {
display: none;
}
.drawer .cart-item__error-text + svg {
flex-shrink: 0;
width: 1.2rem;
margin-right: 0.7rem;
margin-top: 0.4rem;
}
.drawer .totals {
margin-top: 2rem;
}
.drawer .totals:first-child {
margin: 0;
}
.drawer .totals__subtotal {
font-family: var(--font-body-family);
color: rgba(var(--color-foreground));
}
.drawer .totals > *:not(.totals__subtotal-text):not(.totals__subtotal-value) {
margin: 0;
font-size: 1.6rem !important;
font-weight: 600;
text-transform: uppercase;
line-height: var(--font-body-line-height);
letter-spacing: 0.06em;
font-family: var(--font-subtitle-family);
}
.drawer .totals__subtotal-value {
font-weight: 500;
color: rgb(var(--color-foreground-title));
}
.drawer .totals__subtotal-text {
margin: 0;
color: rgb(var(--color-foreground-secondary));
}
.drawer .cart__ctas {
display: flex;
flex-direction: column;
gap: 1.6rem;
margin-top: 1rem;
align-items: center;
}
@media screen and (min-width: 750px) {
.drawer .cart__ctas {
margin-top: 3rem;
}
}

.cart-drawer__overlay {
position: fixed;
top: 0;
right: 0;
bottom: 0;
left: 0;
}
.cart-drawer__overlay:empty {
display: block;
}
.cart-drawer__footer .totals {
display: flex;
flex-wrap: wrap;
align-items: center;
justify-content: space-between;
gap: 1.7rem;
}
.cart-drawer .tax-note {
display: block;
margin-top: 0.9rem;
font-size: 1.2rem;
line-height: var(--font-body-line-height);
color: rgba(var(--color-foreground), 0.6);
}
.cart-drawer .cart-drawer__bottom .button .icon-cart {
width: 1.8rem;
height: 1.8rem;
}
.cart-drawer__continue {
display: block;
margin-top: 1.9rem;
margin-left: 1.4rem;
font-size: 1.2rem;
line-height: var(--font-body-line-height);
text-align: center;
text-transform: capitalize;
text-decoration: underline;
text-decoration-color: #bfbfbf;
}
.cart-drawer__continue svg {
width: 1rem;
height: 1rem;
transform: translateY(0.3rem);
margin-left: 0.4rem;
}

cart-drawer-items.is-empty + .drawer__footer {
display: none;
}

cart-drawer {
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100%;
}

.cart-drawer__form {
flex-grow: 1;
display: flex;
flex-wrap: wrap;
}

cart-drawer-items {
overflow: auto;
padding-right: 1.6rem;
}

@media screen and (max-height: 650px) {
cart-drawer-items {
overflow: visible;
}
.drawer__inner {
overflow: scroll;
}
}
cart-drawer-items::-webkit-scrollbar {
width: 3px;
height: 3px;
}

cart-drawer-items::-webkit-scrollbar-thumb {
background-color: rgba(var(--color-foreground), 0.7);
border-radius: 100px;
}
/*============================================================================*/
.link-hover-line-outer span {
position: relative;
}
.link-hover-line-outer span::after {
position: absolute;
bottom: 0;
left: 0;
right: 0;
content: "";
width: 0;
height: 0.1rem;
background-color: rgb(var(--color-accent));
transition: width var(--duration-default);
}
.link-hover-line-outer:hover span::after {
width: 100%;
}

.main-product-description > *:not(:last-child) {
margin-bottom: 15px;
}
.main-product-description img,
.main-product-description svg {
max-width: 100%;
max-height: 100%;
width: auto;
height: auto;
}

#container {
overflow: scroll;
position: relative;
}

#content {
height: 200px;
background: lightblue;
}

#floater {
position: absolute;
border: 1px solid black;
padding: 1em 2em;
}

.product .custom-liquid {
margin-bottom: 1.5rem;
}
.product .breadcrumb__desktop {
position: static;
transform: translateY(1.6rem);
margin: 0;
display: flex;
}
@media (min-width: 1200px) {
.product .breadcrumb__desktop {
position: absolute;
left: 0;
}
}
.product__outer {
display: flex;
flex-direction: column;
gap: 1rem;
padding-top: 1.6rem;
padding-bottom: 3.2rem;
align-items: flex-start;
}
@media (min-width: 1200px) {
.product__outer {
flex-direction: row;
gap: 6.4rem;
padding-bottom: 8rem;
}
}
@media (min-width: 1200px) {
.product__outer--desktop-order .product__info-wrapper {
order: 1;
}
}
@media (max-width: 1200px) {
.product__outer--mobile-order .product__info-wrapper {
order: 1;
margin-top: 2rem;
}
}
.product__media-wrapper {
width: 100%;
position: relative;
}
@media (min-width: 1200px) {
.product__media-wrapper {
width: 50%;
position: sticky;
top: calc(var(--header-height) + 1.6rem);
}
}
.product .deferred-media__poster-button svg {
float: left;
}
.product .deferred-media__poster img {
display: block;
width: 100%;
height: 100%;
object-fit: cover;
}
.product__info-container .product-form {
margin: 2.4rem 0 0;
}
.product__info-container .share-button {
margin-top: 1rem;
}
.product__description {
margin-bottom: 3.5rem;
max-width: 42rem;
}
.product .card__description {
display: none;
}
@media (min-width: 750px) {
.product__media-container .slider-buttons {
display: none;
}
}
.product__info-wrapper {
width: 100%;
}
@media (min-width: 1200px) {
.product__info-wrapper {
width: 50%;
}
}
.product .product__info-container > *:first-child {
margin-top: 0 !important;
}
.product .about-section + .steps-section,
.product .about-section + .featured-products-section,
.product .about-section + .product-slider-section {
padding-top: 0;
}
.product .product-about {
overflow: hidden;
margin: 4rem 0 0;
}
.product .product-about + .product-about {
margin: 0;
}
.product .product-about__wrapper__perfect-fit-title {
letter-spacing: -0.02em;
font-size: 1.4rem;
font-weight: var(--font-body-weight);
}
.product .product-about .perfect-fit-description p {
color: rgba(var(--color-foreground), 0.75) !important;
}
.product .product-about__outer {
display: flex;
flex-direction: column;
gap: 1rem;
}
@media (min-width: 1200px) {
.product .product-about__outer {
flex-direction: row;
justify-content: space-between;
gap: 1.5rem;
}
}
.product .product-about__right {
width: 100%;
}
.product .product-about__subheading {
margin-bottom: 1.2rem;
}
.product .product-about__content {
margin: 0;
letter-spacing: -0.02em;
}
.product .product-about__content p {
margin: 0;
}
.product .product-about__accordion-item {
border-bottom: 0.1rem solid rgb(var(--color-border));
border-top: 0.1rem solid rgb(var(--color-border));
}
.product .product-about + .product-about .product-about__accordion-item {
border-top: 0;
}
.product .product-about__more-link {
display: flex;
align-items: center;
font-size: 1.5rem;
opacity: 1;
transition: var(--duration-default);
transition-delay: var(--duration-short);
}
.product .product-about__more-link svg {
width: 1.2rem;
height: 1.2rem;
transform: rotate(0deg);
margin-right: 1rem;
transition: var(--duration-default);
}
.product .product-about__accordion-title {
display: flex;
align-items: center;
justify-content: space-between;
margin: 0;
border-top: none;
padding: 2rem 0;
position: relative;
cursor: pointer;
transition: all var(--duration-default) ease;
}
.product .product-about__accordion-title:last-child {
border-bottom: 0.1rem solid rgb(var(--color-border));
}
.product .product-about__accordion-title:hover {
opacity: 0.5;
}
.product .product-about__accordion-title.active .product-about__more-link svg {
transform: rotate(180deg);
}
.product .product-about__accordion-title.active:hover .product .product-about__more-link {
opacity: 1;
}
.product .product-about__more-link-wrapper {
display: flex;
align-items: center;
}
.product .product-about__accordion-description {
display: none;
font-size: 1.4rem;
line-height: 2.3rem;
margin-bottom: 2rem;
}
.product .product-about__accordion-description li,
.product .product-about__accordion-description > * {
margin: 0 0 0.5em;
}
.product .product-about__accordion-description li:last-child,
.product .product-about__accordion-description > *:last-child {
margin-top: 0;
}
.product .product-about__accordion-description-image {
max-width: 20rem;
position: relative;
}
.product .product-about__accordion-description-image img {
width: 100%;
height: 100%;
object-fit: cover;
object-position: center;
}
.product .product-about__accordion-description-image .button--with-underline {
color: var(--color-title-text);
}
.product .product-about__accordion-description-image .product-recommendations--single .card--product {
max-width: 100%;
}
.product #image {
position: absolute;
display: none;
z-index: 22;
pointer-events: none;
}
.product .description_link p {
color: rgba(var(--color-foreground), 0.75);
}
.product .description_link p a {
font-weight: var(--font-body-weight);
}
.product__title__wrapper {
display: flex;
align-items: flex-start;
justify-content: space-between;
gap: 1.2rem;
width: 100%;
}
.product__title {
letter-spacing: -0.02em;
word-break: break-word;
margin-bottom: 0;
margin-top: 0.5rem;
}
.product__text,
.product .product__sku {
margin: 0.5rem 0 0;
}
.product .price--sold-out .price__badge-sale {
display: block;
}
.product__media-list-wrapper {
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 0.4rem;
}
.product__media-list-wrapper .swiper-slide {
justify-content: center;
overflow: hidden;
min-width: 0;
display: flex;
flex-direction: column;
}
@media (min-width: 1200px) {
.product__media-list-wrapper {
flex-direction: row;
}
}
.product__media-list {
flex-grow: 1;
overflow: hidden;
}
.product__media-list .swiper-btn,
.product__media-list .swiper-pagination {
opacity: 0;
visibility: hidden;
}
.product__media {
height: 100%;
}
.product__media img {
display: block;
width: 100% !important;
height: 100%;
object-fit: cover;
object-position: center;
}
.product__media-sublist {
width: 100%;
order: 1;
height: auto !important;
}
@media (min-width: 1200px) {
.product__media-sublist {
max-width: 24.7%;
order: 0;
align-self: normal;
}
}
.product__media-subitem {
border: 1px solid transparent;
box-sizing: border-box;
overflow: hidden;
cursor: pointer;
height: auto;
}
.product__media-subitem:hover {
border-color: rgb(var(--color-accent));
}
.product__media-subitem.swiper-slide-thumb-active {
border-color: rgb(var(--color-accent));
}
.product__media-subitem .product__media {
position: relative;
box-sizing: border-box;
height: 100%;
}
.product__media-subitem .product__media img {
height: 100% !important;
}
.product noscript .product__media img {
max-width: 100%;
height: 100%;
position: static;
}
.product .product__media img {
height: auto;
max-height: 100%;
}
.product__tags {
margin: 1.2rem 0 0;
}
@media screen and (min-width: 750px) {
.product__tags {
margin: 1.6rem 0 0;
}
}
.product .price {
font-size: 2rem;
line-height: 1;
}
.product .price .price__sale .price-item--regular {
font-size: 2rem !important;
font-weight: 400;
}
.product .price--on-sale .price__sale {
flex-direction: row;
}
.product .price dd {
margin: 0 0.6rem 0 0;
}
.product .price dd:last-child {
margin: 0;
}
.product .price dd.price__compare {
margin: 0 2rem 0 0;
}
.product .price-wrapper {
margin-top: 0.8rem;
}
@media (min-width: 750px) {
.product .price-wrapper {
margin-top: 1.6rem;
}
}
.product .price-wrapper .card__badge {
position: static;
}
.product .price-item {
font-size: 2rem;
}
.product .price--on-sale .price-item--regular {
font-size: 1.6rem;
font-weight: 700;
color: rgba(var(--color-foreground), 0.75);
text-decoration-color: rgba(var(--color-foreground), 0.4);
}
.product .product__tax {
margin-top: 1rem;
}
.product__pickup-availabilities {
margin: 3rem 0 0;
}
.product .share-buttons__label-name {
color: rgba(var(--color-foreground), 0.75);
}
.product .share-buttons__label:after {
position: absolute;
bottom: 0;
left: 0;
right: 0;
content: "";
width: 0;
height: 1rem;
background-color: rgb(var(--color-base-button-background));
transition: width var(--duration-default);
}
.product .share-buttons__label:hover {
width: 100%;
}
.product .share-buttons__label:hover .share-buttons__label-name {
color: rgba(var(--color-foreground));
}
.product .share-buttons__label:hover .icon-share path {
fill: rgba(var(--color-foreground));
}
.product .share-buttons__list:before {
left: 15rem;
}
.product .share-buttons__list {
right: 0;
top: 100%;
}
.product .share-buttons__list:after {
right: 0;
}
.product .share-buttons__list {
background-color: rgb(var(--color-background));
}
.product .share-buttons__list:before {
visibility: hidden;
opacity: 0;
}
.product .share-buttons__list:after {
visibility: hidden;
opacity: 0;
}
@media (min-width: 1440px) {
.product--no-media .shopify-payment-button__button.shopify-payment-button__button--unbranded {
background-position: 18rem center;
}
}
.product--no-media .product .product-about__description {
margin: 1.6rem auto 1.6rem auto;
}
@media (min-width: 990px) {
.product--no-media .product .product-about__info-container {
padding-left: 0;
padding-top: 0;
}
}
@media (min-width: 1200px) {
.product--no-media .product .product-about__info-container {
padding-right: 0;
}
}

/* Product-tags */
.product-tags {
display: flex;
gap: 0.8rem;
flex-wrap: wrap;
list-style: none;
padding: 0;
}
.product-tags__tag {
display: flex;
align-items: center;
font-size: 1.2rem;
color: rgba(var(--color-foreground), 1);
background-color: rgba(var(--color-base-background-2));
padding: 0 1.1rem;
}
.product-tags__tag .icon {
width: 0.847rem;
margin-left: 0.8rem;
color: rgb(var(--color-foreground-title));
}

.product__info-container .product-parameters {
margin-top: 3.2rem;
position: relative;
padding-top: 3.2rem;
}
.product__info-container .product-parameters::before {
content: "";
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 1px;
background-color: rgb(var(--color-border));
}
@media (min-width: 1200px) {
.product__info-container .product-parameters::before {
left: -3.2rem;
width: calc(100% + 3.2rem);
}
}

.product__outer--desktop-order-left .product-parameters:before {
left: 0;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
width: 100%;
}

.product-recommendations--single {
width: 100%;
display: block;
}
.product-recommendations--single-block {
display: grid;
grid-template-columns: 1fr;
gap: 3.2rem;
position: relative;
grid-template-columns: 1fr;
}
@media screen and (min-width: 576px) {
.product-recommendations--single-block {
grid-template-columns: repeat(var(--recommendation-size), 1fr);
}
}
.product-recommendations--single-block::before {
content: "";
position: absolute;
top: -1.6rem;
left: 0;
width: 100%;
height: 1px;
background-color: rgb(var(--color-border));
}
.product-recommendations--single .product-form__group-name--recommend {
margin: 6.4rem 0 3.2rem;
font-size: 1.4rem;
color: rgb(var(--color-foreground));
}
.product-recommendations--single .card-wrapper {
position: relative;
height: 100%;
transition: all var(--duration-long) linear;
}
.product-recommendations--single .card-wrapper .card--product {
position: relative;
transition: inherit;
}
.product-recommendations--single .card-wrapper .card--product_image {
position: relative;
display: block;
padding-bottom: 125%;
transition: inherit;
overflow: hidden;
}
.product-recommendations--single .card-wrapper .card--product_image img {
position: absolute;
width: 100%;
height: 100%;
object-fit: cover;
transition: inherit;
}
.product-recommendations--single .card-wrapper .product-page__popolar-product-vendor {
margin: 0;
font-size: 0.9rem;
}
.product-recommendations--single .card-wrapper:hover .card--product_image img {
transform: scale(1.12);
transition: all var(--duration-long) ease;
}
.product-recommendations--single .card-wrapper:not(:last-child) .card--product::after {
content: "";
position: absolute;
right: -1.6rem;
bottom: 0;
width: 0;
height: 100%;
background-color: rgb(var(--color-border));
}
@media screen and (min-width: 576px) {
.product-recommendations--single .card-wrapper:not(:last-child) .card--product::after {
width: 1px;
}
}
.product-recommendations--single .card-information {
display: flex;
align-items: center;
justify-content: space-between;
flex-grow: 1;
padding: 1.2rem 0 0;
}
.product-recommendations--single .card-information > svg {
transition: var(--duration-default);
}
.product-recommendations--single .card-information__bottom {
margin-top: 0.8rem;
}
.product-recommendations--single .card__title {
font-size: 1.5rem;
}
.product-recommendations--single .card-wrapper__link--overlay {
z-index: 2;
}
.product-recommendations--single .price {
font-size: 1.5rem !important;
}
.product-recommendations--single .price-item {
font-size: 1.5rem !important;
}

/* Dynamic checkout */
.shopify-payment-button__more-options[disabled] {
cursor: default;
}

.shopify-payment-button__button {
font-family: var(--font-button-family);
font-weight: var(--font-button-weight);
font-style: var(--font-button-style);
height: 6rem;
display: flex;
justify-content: center;
align-items: center;
border-radius: 0;
overflow: hidden;
font-size: 1.5rem;
text-align: left;
text-transform: uppercase;
background-repeat: no-repeat;
background-position: calc(50% + 70px) center;
position: relative;
transition: var(--duration-default);
}
.shopify-payment-button__button:hover:not([disabled]) {
opacity: 0.9;
background-color: rgba(var(--color-button-hover));
cursor: pointer;
}
.shopify-payment-button__button:focus {
opacity: 0.9;
background-color: rgba(var(--color-button));
}
.shopify-payment-button__button:focus-visible {
opacity: 0.9;
background-color: rgba(var(--color-button));
}
.shopify-payment-button__button__more-options {
font-size: 1.2rem;
line-height: 1.5;
letter-spacing: 0.05rem;
text-decoration: underline;
text-underline-offset: 0.3rem;
}

.shopify-payment-button__button.shopify-payment-button__button--branded {
padding: 0;
border: none;
}
.shopify-payment-button__button.shopify-payment-button__button--branded .shopify-cleanslate > div {
padding: 1rem 9rem 1rem 3rem !important;
margin: 0;
font-size: 1.5rem !important;
justify-content: flex-start !important;
}
.shopify-payment-button__button.shopify-payment-button__button--branded .shopify-cleanslate > div span {
font-size: 1.5rem;
font-weight: var(--font-button-weight);
margin-right: 0.8rem;
}

.shopify-payment-button__button.shopify-payment-button__button--unbranded {
background-color: rgba(var(--color-button));
box-shadow: none;
font-size: 1.5rem;
line-height: 1.2;
letter-spacing: 0.07rem;
transition: var(--duration-default);
}

/* Product form */
.product-form {
display: block;
position: relative;
}
.product-form__error-message-wrapper:not([hidden]) {
display: flex;
align-items: flex-start;
font-size: 1.2rem;
margin-bottom: 1.5rem;
}
.product-form__error-message-wrapper svg {
flex-shrink: 0;
width: 1.2rem;
height: 1.2rem;
margin-right: 0.7rem;
margin-top: 0.5rem;
}
.product-form__group-name {
margin: 0 0 1.2rem;
}
.product-form__quantity__add__buttons {
display: flex;
justify-content: space-between;
gap: 1.6rem;
}
.product-form .add_to_cart_quantity {
transform: rotate(0) !important;
}
.product-form__input {
width: 100%;
padding: 0;
border: none;
position: relative;
}
@media (min-width: 750px) {
.product-form__input {
width: 100%;
}
}
.product-form__input .link-hover-line-outer span {
font-size: var(--font-heading-h6-size);
}
.product-form__input .quantity {
width: 100%;
box-shadow: none;
border: 0.1rem solid rgb(var(--color-border-input));
max-height: 6rem;
}
.product-form__input .quantity__input {
height: 6rem;
}
.product-form__input .quantity__button {
height: 6rem;
color: var(--color-title-text);
transition: var(--duration-default);
}
.product-form__input .quantity__button:first-child svg {
margin-left: auto;
}
.product-form__input .quantity__button:last-child svg {
margin-right: auto;
}
.product-form__input .select {
position: relative;
color: rgb(var(--color-placeholder-input));
}
.product-form__input .select svg {
width: 2rem;
height: 2rem;
position: absolute;
top: 50%;
right: 5%;
transform: translateY(-50%) rotate(360deg);
pointer-events: none;
transition: all var(--duration-default) ease;
}
.product-form__input select {
padding: 0 2.5rem 0 1.2rem;
width: 100%;
font-size: 1.6rem;
text-transform: none;
font-weight: 400;
-webkit-appearance: none;
appearance: none;
margin: -1px 0;
cursor: pointer;
min-height: 6rem;
border: 1px solid rgb(var(--color-border-input));
background-color: rgb(var(--color-background-input));
display: block;
position: relative;
font-family: inherit;
color: rgb(var(--color-placeholder-input));
}
.product-form__input select option {
background-color: rgb(var(--color-background));
padding: 1rem;
}
.product-form .variant-option + label {
background-color: #f2f2f2;
padding: 10px 15px;
margin: 0 5px;
}
.product-form .variant-option:checked + label {
background-color: #000;
color: #fff;
}
.product-form .variant-option[disabled] + label {
opacity: 0.5;
}
.product-form .variant-option {
display: none;
}
.product-form__quantity {
width: 100%;
}
.product-form__buttons {
display: grid;
gap: 1.2rem;
grid-template-columns: 0.4fr 0.6fr;
align-items: flex-end;
}
@media (min-width: 990px) {
.product-form__buttons {
grid-template-columns: 0.25fr 0.75fr;
}
}
.product-form__buttons > * {
width: 100%;
text-align: left;
}
.product-form__buttons .button--with-underline {
display: none;
}
.product-form__quantity__add__buttons {
grid-column: 1/3;
}
.product-form__checkout {
position: relative;
}
.product-form__checkout-full {
grid-column: 1/3;
}
.product-form__submit {
height: 6rem;
box-shadow: none;
padding: 1rem 3rem;
position: relative;
width: 100%;
}
@media screen and (max-width: 375px) {
.product-form__submit {
padding: 1rem;
}
}
.product-form__submit[disabled] {
opacity: 1;
background-color: rgb(var(--color-button), 0.2);
color: rgb(var(--color-button-secondary-text));
border-color: transparent;
}
.product-form__submit span {
display: flex;
justify-content: center;
width: 100%;
}
.product-form__submit span:nth-of-type(2) {
padding: 1rem 3rem;
}
.product-form__submit svg {
width: 18px;
height: 18px;
color: currentColor;
}
.product-form__submit + .shopify-payment-button {
display: none;
}

.product-popup {
display: inline-flex;
gap: 2rem;
margin-top: 1.2rem;
}
@media (min-width: 750px) {
.product-popup {
margin-top: 2.4rem;
}
}
.product-popup + .product-popup {
margin-left: 3rem;
}

.product-popup-modal {
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.5);
box-sizing: border-box;
opacity: 0;
position: fixed;
visibility: hidden;
z-index: -1;
margin: 0 auto;
top: 0;
left: 0;
overflow: auto;
}
.product-popup-modal[open] {
opacity: 1;
visibility: visible;
z-index: 103;
}
.product-popup-modal__toggle {
position: absolute;
top: 2rem;
right: 2rem;
}
.product-popup-modal__content {
width: calc(100% - 3.2rem);
max-width: 80rem;
height: max-content;
max-height: 80%;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
padding: 3rem;
background-color: rgb(var(--color-background));
box-shadow: 0 4rem 6rem rgba(0, 0, 0, 0.05);
overflow-y: auto;
overflow-x: hidden;
}
.product-popup-modal__title {
margin-top: 0;
}
.product-popup-modal__text {
margin: 3rem 0 1.2rem;
}
.product-popup-modal__text p {
font-size: 1.4rem;
color: rgba(var(--color-foreground), 0.6);
margin: 0;
}
.product-popup-modal__text p + p {
margin-top: 2.6rem;
}
.product-popup-modal-info ul,
.product-popup-modal-info ol {
padding-left: 20px;
}
.product-popup-modal-info img {
width: 100%;
height: 100%;
object-fit: cover;
object-position: center;
}

.fancy-container-info .fancybox-close-button {
top: 1rem;
left: 1.5rem;
right: unset;
}
@media (min-width: 990px) {
.fancy-container-info .fancybox-close-button {
top: 4rem;
left: 4rem;
}
}

variant-radios {
display: flex;
flex-direction: column;
flex-wrap: wrap;
gap: 2.4rem;
}

variant-selects {
display: grid;
grid-template-columns: 1fr;
gap: 2.4rem;
}
@media (min-width: 576px) {
variant-selects {
grid-template-columns: 0.4fr;
}
}
@media (min-width: 990px) {
variant-selects {
grid-template-columns: 0.35fr;
}
}
variant-selects .product-form__input {
border: none;
margin: 0;
}
variant-selects .product-form__input select option {
color: rgba(var(--color-foreground-title), 1);
}
variant-selects .product-form__input select option:hover {
opacity: 0.5;
}
variant-selects .form__label {
letter-spacing: -0.02em;
font-size: 1.4rem;
font-weight: var(--font-body-weight);
text-transform: capitalize;
padding-left: 1.2rem;
margin-bottom: 1.2rem;
}

.product-parameters__item-full variant-selects .product-form__input {
flex: 0 0 calc(50% - 0.8rem);
}
@media (min-width: 576px) {
.product-parameters__item-full variant-selects .product-form__input {
flex: 0 0 calc(33.33% - 0.8rem);
}
}
.product-parameters__item-full variant-selects .product-parameters__quantity {
flex: 0 0 calc(50% - 0.8rem);
margin-left: unset;
}
@media (min-width: 576px) {
.product-parameters__item-full variant-selects .product-parameters__quantity {
flex: 0 0 calc(20% - 0.8rem);
}
}

fieldset.product-form__input .form__label {
letter-spacing: -0.02em;
font-size: 1.4rem;
font-weight: var(--font-body-weight);
text-transform: capitalize;
margin: 0;
}

/* Recipient form */
.recipient-form {
display: block;
width: 100%;
margin-bottom: 2rem;
}
.recipient-form input[type=date] {
padding: 1.5rem 2rem;
}
.recipient-form input[type=checkbox] {
display: none;
}
.recipient-form input[type=checkbox]:checked + label::before {
background: rgb(var(--color-accent)) url("data:image/svg+xml;charset=UTF-8,%3csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M4.66649 6.61451L10.7945 0.48584L11.7378 1.42851L4.66649 8.49984L0.423828 4.25717L1.36649 3.31451L4.66649 6.61451Z' fill='white'/%3e%3c/svg%3e ") no-repeat center center;
border-color: rgb(var(--color-accent));
}
.recipient-form input[type=checkbox]:checked + label span {
color: rgb(var(--color-accent));
}
.recipient-form input[type=checkbox]:checked ~ .recipient-fields {
display: block;
animation: animateMenuOpen var(--duration-default) ease;
}
.recipient-form input[type=checkbox]:not(:checked, :disabled) ~ .recipient-fields {
display: none;
}
.recipient-form input[type=checkbox] + label {
display: inline-flex;
align-items: center;
font-size: 1.5rem;
color: rgb(var(--color-foreground));
cursor: pointer;
}
.recipient-form input[type=checkbox] + label::before {
content: "";
display: flex;
align-items: center;
justify-content: center;
width: 2.4rem;
height: 2.4rem;
margin: 0 1.1rem 0 0;
border: 0.1rem solid rgba(var(--color-border-input));
border-radius: 0.2rem;
background-color: rgb(var(--color-background));
appearance: none;
}
.recipient-form input[type=checkbox] + label span {
font-size: 1.5rem;
font-weight: 500;
transition: var(--duration-default);
}
.recipient-form input[type=checkbox] + label:hover span {
color: rgb(var(--color-accent));
}
.recipient-form input[type=checkbox] + label svg {
display: none;
}
.recipient-form .recipient-email-label {
display: none;
}
.recipient-form .recipient-form-field-label {
font-size: 1.3rem;
margin: 0.4rem 0 0;
text-transform: initial;
}
.recipient-form .recipient-form-field-label--space-between {
display: flex;
justify-content: space-between;
}
.recipient-form .recipient-fields__field:not(:last-child) {
margin: 0 0 1.6rem;
}
.recipient-form .recipient-fields hr {
margin: 1.6rem auto;
}
.recipient-form .form__label {
margin-bottom: 0;
}
.recipient-form .form__message {
font-size: 1.2rem;
line-height: 1;
color: rgb(var(--color-error));
margin: 0.4rem 0 0;
}

.no-js .recipient-checkbox {
display: none !important;
}
.no-js .recipient-email-label.optional {
display: inline;
}
.no-js .product__media-sublist {
padding: 0;
}

.js .recipient-fields {
display: none;
}
.js .recipient-email-label.required {
display: inline;
}

/* Overrides */
.shopify-payment-button__more-options {
display: none;
}

/* Product media */
.product__media-list {
width: 100%;
overflow: hidden;
}
.product__media-list__container {
display: flex;
flex-wrap: wrap;
gap: 1.6rem;
width: 100%;
height: 100%;
}
.product__media-list__container .product__media-item:not(:last-child)::after {
content: "";
position: absolute;
width: 1px;
height: 100%;
top: 0;
right: -0.8rem;
background-color: rgb(var(--color-border));
}
.product__media-list__container--great-five {
display: flex;
flex-wrap: wrap;
gap: 1.6rem;
width: 100%;
height: 100%;
position: relative;
overflow: hidden;
}
.product__media-list__container--great-five .product__media-item {
flex: 1 0 20.5%;
}
.product__media-list__container--great-five .product__media-item:nth-child(1) {
flex: 1 0 47%;
}
.product__media-list__container--great-five .product__media-item:nth-child(2) {
flex: 1 0 47%;
}
@media (min-width: 1200px) {
.product__media-list__container--great-five .product__media-item {
flex: 1 0 22.5%;
}
}
.product__media-list__container--great-five .product__media:before {
content: "";
position: absolute;
width: calc(100% + 1.6rem);
height: 1px;
background: rgb(var(--color-border));
bottom: -0.8rem;
left: 0;
}
.product__media-list__container--great-five .product__media {
position: relative;
}
.product__media-list__container--great-five .product__media-item:nth-child(4n+2):before {
left: -1.6rem;
width: 100%;
}
.product__media-list__container--collage {
display: grid;
grid-template-rows: 1fr 1fr 1fr;
grid-template-columns: 0.3fr 1fr;
gap: 0.4rem;
width: 100%;
height: 100%;
}
.product__media-list__container--collage .product__media-item :nth-child(1) {
grid-row-start: 1;
grid-column-start: 1;
grid-row-end: 2;
grid-column-end: 2;
}
.product__media-list__container--collage .product__media-item :nth-child(2) {
grid-row-start: 2;
grid-column-start: 1;
grid-row-end: 3;
grid-column-end: 2;
}
.product__media-list__container--collage .product__media-item :nth-child(3) {
grid-row-start: 3;
grid-column-start: 1;
grid-row-end: 4;
grid-column-end: 2;
}
.product__media-list__container--collage .product__media-item:last-child {
grid-row-start: 1;
grid-column-start: 2;
grid-row-end: 4;
grid-column-end: 3;
}
.product__media-list__container--large {
display: flex;
flex-direction: column;
gap: 1.6rem;
width: 100%;
height: 100%;
}
.product__media-list__container--large .product__media-item {
flex: 1 1 100%;
}
.product__media-list__container--large .product__media-item:not(:last-child)::after {
content: "";
position: absolute;
width: 100%;
height: 1px;
bottom: -0.8rem;
left: 0;
background-color: rgb(var(--color-border));
}
.product__media-list__container .product__media-item {
flex: 1 0 calc(50% - 1.6rem);
}

.product__outer--desktop-order .product__media-wrapper::after {
content: "";
position: absolute;
width: 100%;
height: 1px;
right: 0;
top: -1.6rem;
background: rgb(var(--color-border));
}
@media (min-width: 1200px) {
.product__outer--desktop-order .product__media-wrapper::after {
right: -1.6rem;
top: 0;
width: 1px;
height: 100%;
}
}

.product__outer--desktop-order-left .product__media-wrapper::after {
content: "";
position: absolute;
width: 100%;
height: 1px;
left: 0;
top: -1.6rem;
background: rgb(var(--color-border));
}
@media (min-width: 1200px) {
.product__outer--desktop-order-left .product__media-wrapper::after {
left: -1.6rem;
top: 0;
width: 1px;
height: 100%;
}
}

.product__outer--mobile-order.product__outer--desktop-order .product__media-wrapper::after {
top: unset;
bottom: -3.2rem;
left: 0;
}
@media (min-width: 1200px) {
.product__outer--mobile-order.product__outer--desktop-order .product__media-wrapper::after {
right: -1.6rem;
top: 0;
width: 1px;
height: 100%;
bottom: unset;
left: unset;
}
}

.product__outer--mobile-order.product__outer--desktop-order-left .product__media-wrapper::after {
top: unset;
bottom: -3.2rem;
left: 0;
}
@media (min-width: 1200px) {
.product__outer--mobile-order.product__outer--desktop-order-left .product__media-wrapper::after {
left: -1.6rem;
top: 0;
width: 1px;
height: 100%;
bottom: unset;
}
}

.product__media-item {
width: 100%;
height: auto;
position: relative;
}
.product__media-item--contain .product__media {
height: auto;
}
@media (min-width: 1200px) {
.product__media-item--contain .product__media {
height: auto;
}
}
.product__media-item--contain .product__media img {
object-fit: contain;
}
.product__media-item--contain modal-opener {
height: auto;
}

.product__media-list__container--great-five .product__media-item:not(:nth-child(2)):not(:nth-child(4n+2)):not(:last-child)::after {
content: "";
position: absolute;
width: 1px;
height: 100%;
right: -0.8rem;
top: 0;
background: rgb(var(--color-border));
}

.product__media-icon .icon {
width: 2.4rem;
height: 2.4rem;
color: rgb(var(--color-button));
}

.product__media-icon {
background-color: transparent;
display: flex;
align-items: center;
justify-content: center;
height: 5rem;
width: 5rem;
position: absolute;
right: 50%;
top: 50%;
transform: translate(50%, -50%);
z-index: 1;
transition: all var(--duration-short) ease, opacity var(--duration-short) ease;
}
@media (min-width: 1200px) {
.product__media-icon {
height: 2.4rem;
width: 2.4rem;
}
}

.product__media-video .product__media-icon {
opacity: 1;
}

.product__modal-opener {
height: 100%;
}

.product__modal-opener--image .product__media-toggle:hover {
cursor: zoom-in;
}

@media screen and (min-width: 750px) {
.grid__item.product__media-item--full {
width: 100%;
}
}
@media screen and (min-width: 990px) {
.product__media-icon {
opacity: 0;
}
.product__modal-opener:hover .product__media-icon,
.product__modal-opener:focus .product__media-icon {
opacity: 1;
}
}
.product__media-item > * {
display: block;
position: relative;
}

.product__media-toggle {
background-color: transparent;
border: none;
cursor: pointer;
display: block;
margin: 0;
padding: 0;
position: absolute;
top: 0;
left: 0;
z-index: 2;
height: 100%;
width: 100%;
}

.product-media-modal {
background-color: rgb(var(--color-background));
height: 100%;
position: fixed;
top: 0;
left: 0;
width: 100%;
visibility: hidden;
opacity: 0;
z-index: -1;
--section-color: rgba(61, 61, 61);
--section-bullet-active-color: rgba(61, 61, 61, 0.24);
}
.product-media-modal__toggle {
position: absolute;
top: 3.2rem;
right: 3.2rem;
z-index: 103;
}
.product-media-modal__toggle svg {
width: 2.4rem !important;
height: 2.4rem !important;
}
.product-media-modal .swiper-zoom-container {
padding: 3rem;
max-width: 100vw;
max-height: 100vh;
width: auto;
min-width: 0;
margin: auto 1rem;
flex: 1 1 auto;
}
.product-media-modal .swiper-zoom-container img {
object-fit: contain;
object-position: center;
width: auto;
height: auto;
max-width: calc(100vw - 2rem);
max-height: calc(100vh - 2rem);
}
@media screen and (min-width: 990px) {
.product-media-modal .swiper-zoom-container img {
max-width: calc(100vw - 6rem);
max-height: calc(100vh - 6rem);
}
}
.product-media-modal .swiper-zoom-container-model {
width: 100%;
}
.product-media-modal .swiper-zoom-container:hover {
cursor: zoom-in;
}
.product-media-modal .swiper-wrapper {
height: 100%;
align-items: center;
}
@media (min-width: 1200px) {
.product-media-modal .swiper-wrapper {
height: auto !important;
}
}
.product-media-modal .swiper-button-next-prod-page,
.product-media-modal .swiper-button-prev-prod-page {
position: absolute;
width: 3.2rem;
height: 3.2rem;
transition: all var(--duration-long) ease;
background: rgba(255, 255, 255, 0.3);
display: flex;
align-items: center;
justify-content: center;
}
@media (min-width: 750px) {
.product-media-modal .swiper-button-next-prod-page,
.product-media-modal .swiper-button-prev-prod-page {
width: 4.8rem;
height: 4.8rem;
}
}
.product-media-modal .swiper-button-next-prod-page svg,
.product-media-modal .swiper-button-prev-prod-page svg {
width: 2.4rem;
height: 2.4rem;
transition: inherit;
color: var(--color-foreground-title);
}
@media (min-width: 750px) {
.product-media-modal .swiper-button-next-prod-page svg,
.product-media-modal .swiper-button-prev-prod-page svg {
width: 4rem;
height: 4rem;
}
}
.product-media-modal .swiper-button-next-prod-page {
right: 2rem;
}
@media screen and (min-width: 1200px) {
.product-media-modal .swiper-button-next-prod-page {
right: 4rem;
}
.product-media-modal .swiper-button-next-prod-page:hover svg {
transform: translateX(5px);
}
}
.product-media-modal .swiper-button-prev-prod-page {
left: 2rem;
}
@media screen and (min-width: 1200px) {
.product-media-modal .swiper-button-prev-prod-page {
left: 4rem;
}
.product-media-modal .swiper-button-prev-prod-page:hover svg {
transform: translateX(-5px);
}
}
.product-media-modal .swiper-pagination_container {
position: absolute;
right: 4rem;
bottom: 6rem;
z-index: 200;
}
.product-media-modal .swiper {
width: 100%;
height: 100%;
position: relative;
display: flex;
}
@media (min-width: 1200px) {
.product-media-modal .swiper {
height: auto;
}
}
.product-media-modal .swiper-slide {
display: flex;
justify-content: center;
overflow: hidden;
min-width: 0;
}
.product-media-modal .swiper-pagination-bullet::after {
content: "";
position: absolute;
width: 3rem;
height: 3rem;
margin-left: -1.5rem;
margin-top: -1rem;
z-index: 10;
}
.product-media-modal .swiper-pagination-bullet {
background: var(--section-color);
opacity: 1;
z-index: 10;
}
.product-media-modal .swiper-pagination-bullet-active {
background: var(--section-color);
z-index: 10;
box-shadow: 0 0 0 7px var(--section-bullet-active-color);
}
.product-media-modal .swiper-pagination-bullet-active:focus:not(:focus-visible):not(.button--secondary):not(.localization-selector.link) {
box-shadow: 0 0 0 7px var(--section-bullet-active-color);
}
.product-media-modal .slideshow-button-prev path,
.product-media-modal .slideshow-button-next path {
stroke: var(--section-color);
}

.product-media-modal[open] {
visibility: visible;
opacity: 1;
z-index: 101;
display: flex;
}

.product-media-modal__dialog {
display: flex;
align-items: center;
height: 100%;
width: 100%;
}
@media (min-width: 1200px) {
.product-media-modal__dialog {
height: auto;
}
}

.product-media-modal__content {
height: 100%;
width: 100%;
overflow: auto;
}
@media (min-width: 1200px) {
.product-media-modal__content {
height: auto;
}
}

.product-media-modal__content > *:not(.active) {
display: block;
}

.product__modal-opener:not(.product__modal-opener--image) {
display: none;
}

.product__media-list .deferred-media {
display: block;
width: 100%;
}

.product-media-modal__content > * {
display: block;
}

.product-media-modal__content--border img,
.product__media--border img {
display: block;
}

.product-media-modal__content .media {
background: none;
}

.product-media-modal__model {
width: 100%;
}

.product-media-modal__content .deferred-media {
width: 100%;
}

.product__media {
display: flex;
justify-content: center;
position: relative;
}
.product__info-wrapper {
padding-top: 3.2rem;
}
.product .price__badge-sale,
.product .price__badge-sold-out {
display: block;
}
.product .price--sold-out .price__badge-sold-out,
.product .price--on-sale .price__badge-sale {
display: inline-flex;
}
.product .inventory {
margin-top: 1.9rem;
}
.product .inventory__title {
display: flex;
}
.product .inventory__title--default {
color: rgb(var(--color-inventory));
font-size: 1.4rem;
display: flex;
align-items: center;
justify-content: center;
gap: 0.4rem;
}
.product .price--on-sale .card__badge .badge {
margin: 0;
}
@media (min-width: 1200px) {
.product .product__info-wrapper {
max-width: 39%;
}
}
.product .product-form__group-name {
font-weight: 500;
font-size: 1.4rem;
color: rgb(var(--color-foreground-title));
padding: 0;
}

.product__media-wrapper--desktop-order {
margin-top: 4rem;
}
@media (min-width: 1200px) {
.product__media-wrapper--desktop-order {
margin-top: 0;
}
}

.product-form__colors-wrapper {
width: 100%;
}
.product-form__colors-wrapper .product-form__controls-group {
min-height: 3rem;
}

.product-form__controls-group label.color-swatch {
width: 3rem;
height: 3rem;
padding: 1rem;
border-radius: 50%;
border: 0.1rem solid transparent;
box-sizing: border-box;
}
.product-form__controls-group label.color-swatch::after {
content: "";
position: absolute;
top: 50%;
left: 50%;
width: 2.5rem;
height: 2.5rem;
transform: translate(-50%, -50%);
border-radius: inherit;
background-color: var(--swatch-color);
transition: border-color var(--duration-default);
border: 0.1rem solid var(--swatch-bg);
}
.product-form__controls-group label.color-swatch .color-swatch__disabled-icon {
color: var(--disable-color);
}

.quick-add-modal .product-form__controls-group label.color-swatch {
height: 3rem;
}

.product-form__controls-group input:checked:not(.disabled) + label.color-swatch {
border-color: rgb(var(--color-button));
}

.product-form__controls-group input.disabled + label.color-swatch {
opacity: 1;
}

.color-swatch__disabled-icon svg {
position: absolute;
z-index: 33;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
}

.product-parameters {
pointer-events: all;
}
.product-parameters label.color-swatch {
margin: 0;
}

.product-form__input--radios {
width: 100%;
}

.product-form__controls-group {
display: flex;
flex-wrap: wrap;
align-items: center;
gap: 0.8rem;
}

.product-form__controls-group input.disabled + label.color-swatch {
opacity: 1;
}

.color-swatch__disabled-icon {
display: none;
}

.product-form__input input[type=radio].disabled + label,
.card-wrapper input[type=radio].disabled + label {
opacity: 0.5;
pointer-events: none;
}
.product-form__input input[type=radio].disabled + label .color-swatch__disabled-icon,
.card-wrapper input[type=radio].disabled + label .color-swatch__disabled-icon {
display: block;
}

.card-wrapper .product-form__controls {
margin-bottom: 0.8rem;
}
.card-wrapper .product-form__controls-group label.color-swatch {
width: 2.4rem;
height: 2.4rem;
}
.card-wrapper .product-form__controls-group label.color-swatch:after {
width: 1.8rem;
height: 1.8rem;
}
.card-wrapper .product-form__controls-group {
gap: 0.5rem;
}

.product--no-media svg {
width: 100%;
height: 100%;
}

.product-form__controls-group label {
display: inline-flex;
align-items: center;
justify-content: center;
font-weight: 500;
color: rgb(var(--color-foreground-title));
cursor: pointer;
position: relative;
transition: border var(--duration-default) ease;
}

.pills-variant-labels {
height: 40px;
min-width: 40px;
padding: 0 1rem;
border: 0.2rem solid rgb(var(--color-border));
margin: 0;
}

.product-form__controls-group input[type=radio].disabled + .pills-variant-labels:before {
content: "";
display: block;
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgb(var(--color-border));
clip-path: polygon(96% 0, 100% 0, 4% 100%, 0% 100%);
}

.product-form__controls-group input.disabled + label.color-swatch:before {
top: 50%;
height: 0.2rem;
clip-path: unset;
z-index: 1;
transform: translateY(-50%) rotate(-45deg);
}

.product-form__input input[type=radio],
.card-wrapper input[type=radio] {
clip: rect(0, 0, 0, 0);
overflow: hidden;
position: absolute;
height: 1px;
width: 1px;
}

.product-form__controls-group input {
clip: rect(0, 0, 0, 0);
overflow: hidden;
position: absolute;
height: 1px;
width: 1px;
}
.product-form__controls-group input + label:hover {
border-color: rgb(var(--color-button));
color: rgb(var(--color-button));
}
.product-form__controls-group input.disabled + label {
opacity: 0.5;
pointer-events: none;
}
.product-form__controls-group input:checked:not(.disabled) + label {
border-color: rgb(var(--color-button));
color: rgb(var(--color-button));
}
.product-form__controls-group input:checked:not(.disabled) + label:before {
background-color: rgb(var(--color-button));
}

.product-grid-wrapper__big .product-form__controls-group input:checked:not(.disabled) + label.color-swatch {
border-color: #fff;
}
.product-grid-wrapper__big .product-form__controls-group input + label:hover {
border-color: #fff;
}

.media-ratio img {
position: absolute;
width: 100%;
height: 100%;
left: 0;
top: 0;
}
/*============================================================================*/
.section-product-recommendations{
padding-top: 0px;
padding-bottom: 80px;
}
.product-recommendations {
display: block;
--gap: 1.6rem;
}
@media screen and (min-width: 1200px) {
.product-recommendations {
--gap: 3.2rem;
}
}
.product-recommendations__list {
display: grid;
align-self: flex-start;
gap: var(--gap);
grid-template-columns: repeat(var(--mobile-per-row), 1fr);
margin: 2.4rem 0 0;
padding: 0;
--per-row: 3;
--mobile-per-row: 1;
}
@media screen and (min-width: 750px) {
.product-recommendations__list {
grid-template-columns: repeat(2, 1fr);
}
}
@media screen and (min-width: 1200px) {
.product-recommendations__list {
grid-template-columns: repeat(var(--per-row), 1fr);
}
}
@media screen and (max-width: 750px) {
.product-recommendations__list.product-recommendations__list-row li:not(:nth-child(2n)):not(:last-child) .card__inner {
position: relative;
}
.product-recommendations__list.product-recommendations__list-row li:not(:nth-child(2n)):not(:last-child) .card__inner::before {
content: "";
position: absolute;
width: 1px;
height: 100%;
background: rgb(var(--color-border));
right: calc(var(--gap) / -2);
pointer-events: none;
}
.product-recommendations__list.product-recommendations__list-row .card__link {
min-height: unset;
}
}
@media screen and (min-width: 750px) and (max-width: 1200px) {
.product-recommendations__list li:not(:nth-child(2n)):not(:last-child) .card__inner {
position: relative;
}
.product-recommendations__list li:not(:nth-child(2n)):not(:last-child) .card__inner::before {
content: "";
position: absolute;
width: 1px;
height: 100%;
background: rgb(var(--color-border));
right: calc(var(--gap) / -2);
pointer-events: none;
}
}
@media screen and (min-width: 1200px) {
.product-recommendations__list li:not(.hide_border):not(:last-child) .card__inner {
position: relative;
}
.product-recommendations__list li:not(.hide_border):not(:last-child) .card__inner::before {
content: "";
position: absolute;
width: 1px;
height: 100%;
background: rgb(var(--color-border));
right: calc(var(--gap) / -2);
pointer-events: none;
}
}
/*============================================================================*/
.article-hero {
display: flex;
align-items: center;
padding: 1.6rem 0;
flex-direction: column;
position: relative;
z-index: 1;
--card-height: calc(100vh - 75px);
}
@media screen and (min-width: 990px) {
.article-hero {
flex-direction: row;
}
}
.article-hero__img, .article-hero__text {
width: 100%;
flex: 0 0 100%;
max-width: 100%;
}
.article-hero__img {
position: absolute;
left: 0;
top: 1.6rem;
height: calc(100% - 3.2rem);
z-index: -1;
}
.article-hero__img + .article-hero__text {
background: rgba(var(--color-background), 0.7);
}
@media screen and (min-width: 990px) {
.article-hero__img {
position: relative;
height: auto;
left: auto;
top: auto;
z-index: unset;
}
.article-hero__img + .article-hero__text {
background: transparent;
}
}
.article-hero__img::after {
content: "";
display: block;
padding-bottom: var(--card-height);
}
@media screen and (min-width: 990px) {
.article-hero__img::before {
content: "";
position: absolute;
right: 0;
bottom: 0;
background: rgb(var(--color-border));
width: 1px;
height: 100%;
}
}
.article-hero__img img {
position: absolute;
width: 100%;
height: 100%;
left: 0;
top: 0;
object-fit: cover;
object-position: top;
}
@media screen and (min-width: 990px) {
.article-hero__img img {
width: calc(100% - 1.6rem);
}
}
@media screen and (min-width: 990px) {
.article-hero__img {
flex: 0 0 50%;
max-width: 50%;
}
}
.article-hero__text {
display: flex;
align-items: center;
justify-content: center;
padding: 2rem;
}
.article-hero__text-wrapper {
text-align: center;
max-width: 50rem;
width: 100%;
}
.article-hero__text::after {
content: "";
display: block;
padding-bottom: var(--card-height);
}
@media screen and (min-width: 990px) {
.article-hero__text {
flex: 1 0 50%;
padding: 0;
}
}
.article-hero__title {
margin: 0 0 1.6rem;
}
@media screen and (min-width: 990px) {
.article-hero__title {
margin: 0 0 3.2rem;
}
}
.article-hero__excerpt {
margin-bottom: 2rem;
}
@media screen and (min-width: 990px) {
.article-hero__excerpt {
margin-bottom: 4rem;
}
}
.article-hero .article__info {
justify-content: center;
margin-bottom: 1.6rem;
}
/*============================================================================*/
.main-blog__title {
margin-bottom: 3.2rem;
}
.main-blog__header {
margin-bottom: 6.4rem;
}
.main-blog__all-tags {
display: flex;
align-items: center;
gap: 2.4rem;
}
.main-blog__all-tags li:not(:hover):not(.active) {
color: rgb(var(--color-foreground-secondary));
transition: all var(--duration-default);
}

.blog-articles__list {
display: grid;
grid-template-columns: 1fr;
column-gap: 3.2rem;
row-gap: 6rem;
padding: 3rem 0 0;
}
.blog-articles__list-article {
position: relative;
}
@media screen and (min-width: 750px) {
.blog-articles__list {
grid-template-columns: repeat(2, 1fr);
row-gap: 10rem;
padding: 5rem 0 0;
}
.blog-articles__list-article .article_img::after {
content: "";
position: absolute;
width: 1px;
height: 100%;
right: -1.6rem;
bottom: 0;
background: rgb(var(--color-border));
pointer-events: none;
}
.blog-articles__list-article:nth-child(2n+1):not(:nth-last-child(2))::after {
content: "";
position: absolute;
bottom: -5rem;
left: 0;
width: 100%;
display: block;
height: 1px;
background: rgb(var(--color-border));
pointer-events: none;
}
}
@media screen and (min-width: 750px) and (min-width: 990px) {
.blog-articles__list-article:nth-child(2n+1):not(:nth-last-child(2))::after {
bottom: -8rem;
}
}
@media screen and (min-width: 750px) {
.blog-articles__list-article:nth-child(2n) .article_img::after {
display: none;
}
}
@media screen and (min-width: 750px) {
.blog-articles__list-article:last-child .article_img::after {
display: none !important;
}
}
@media screen and (min-width: 990px) {
.blog-articles__list {
grid-template-columns: repeat(var(--per-row), 1fr);
padding: 8rem 0 2rem;
row-gap: 16rem;
--per-row: 3;
}
.blog-articles__list-article .article_img::after {
display: none;
}
.blog-articles__list-article:not(.hide_border) .article_img::after {
display: block;
}
}
/*============================================================================*/
.product-testimonials .container {
box-sizing: border-box;
}
@media screen and (min-width: 1200px) {
.product-testimonials .container {
box-sizing: content-box;
}
}
.product-testimonials__subtitle, .product-testimonials__desc {
margin: 0 0 3.2rem !important;
}
.product-testimonials__subtitle:last-child, .product-testimonials__desc:last-child {
margin-bottom: 0;
}
.product-testimonials__text {
max-width: 965px;
padding: 0;
}
.product-testimonials__slide-stars {
display: flex;
align-items: center;
gap: 0.4rem;
margin-bottom: 1.6rem;
}
.product-testimonials__slide-stars .icon-star {
width: 2.2rem;
height: 2.2rem;
opacity: 0.6;
}
.product-testimonials__slide-stars .icon-star.star-active {
opacity: 1;
}
.product-testimonials__slide {
display: flex;
align-items: center;
}
.product-testimonials__slide.have-overlay::before {
background: var(--slide-overlay);
}
.product-testimonials__img {
position: absolute;
left: 0;
top: 0;
width: 100%;
height: 100%;
z-index: -4;
}
.product-testimonials__img img {
width: 100%;
height: 100%;
max-width: 100%;
max-height: 100%;
object-fit: cover;
object-position: center;
}
.product-testimonials__wrapper {
display: flex;
justify-content: space-between;
align-items: flex-start;
flex-direction: column-reverse;
padding: 0 0 3rem;
}
@media screen and (min-width: 750px) {
.product-testimonials__wrapper {
flex-direction: row;
padding: 6rem 0;
}
}
@media screen and (min-width: 1200px) {
.product-testimonials__wrapper {
flex-direction: row;
padding: 8rem 0;
}
}
.product-testimonials__product {
width: 100%;
margin-bottom: 3.2rem;
max-width: 100%;
}
.product-testimonials__product.color-border-1 {
--color-border: 213, 213, 213;
}
.product-testimonials__product::before {
content: "";
position: absolute;
left: 0;
bottom: -1.6rem;
width: 100%;
height: 1px;
background: rgb(var(--color-border));
}
@media screen and (min-width: 750px) {
.product-testimonials__product::before {
height: 100%;
width: 1px;
bottom: unset;
top: 0;
left: -1.6rem;
}
}
@media screen and (min-width: 750px) {
.product-testimonials__product {
margin: 0 0 0 3.2rem;
max-width: 430px;
}
}
.product-testimonials__product .card-wrapper .product-form__controls {
display: none;
}
.product-testimonials__author {
display: flex;
align-items: center;
}
.product-testimonials__author-img {
max-width: 60px;
max-height: 60px;
width: 60px;
height: 60px;
border: 2px solid rgba(var(--color-border), 0.2);
border-radius: 50%;
}
.product-testimonials__author-img img,
.product-testimonials__author-img svg {
width: 100%;
height: 100%;
max-width: 100%;
max-height: 100%;
object-fit: cover;
object-position: center;
border-radius: 50%;
}
.product-testimonials__author-text {
text-align: left;
margin-left: 1.2rem;
}
.product-testimonials__author-text:only-child {
margin: 0;
}
.product-testimonials__block {
padding: 4rem 0 6rem;
width: 100%;
display: flex;
align-items: center;
justify-content: center;
}
.product-testimonials__block::after {
content: "";
display: block;
padding-bottom: var(--slide-height);
}
@media screen and (min-width: 750px) {
.product-testimonials__block {
padding: 0;
}
}
.product-testimonials__navigation {
position: absolute;
z-index: 2;
bottom: 2rem;
left: 0;
width: 100%;
}
@media screen and (min-width: 990px) {
.product-testimonials__navigation {
bottom: 3rem;
}
}
@media screen and (min-width: 1200px) {
.product-testimonials__navigation {
bottom: 4rem;
}
}
.product-testimonials__navigation-wrapper {
display: flex;
align-items: center;
flex-wrap: wrap;
gap: 1.6rem;
}
@media screen and (min-width: 990px) {
.product-testimonials__navigation-wrapper {
gap: 3.2rem;
}
}
.product-testimonials .card-wrapper {
background: transparent;
}
.product-testimonials .swiper-slide {
height: auto;
}
.product-testimonials .swiper-buttons__box {
display: flex;
align-items: center;
}
.product-testimonials .swiper-pagination {
position: unset;
}
.product-testimonials .swiper-button {
opacity: 0;
}
.product-testimonials:hover .swiper-button {
opacity: 1;
}
.product-testimonials__author-position {
margin: 0;
}
/*============================================================================*/
.customer-section{
padding-top: 40px;
padding-bottom: 80px;
}
.customer {
overflow: hidden;
}
.customer .account__wrapper {
display: block;
}
@media (min-width: 1200px) {
.customer .account__wrapper {
display: flex;
}
}
.customer .account__wrapper .account__navigation {
max-width: unset;
width: 100%;
position: relative;
padding-bottom: 1.6rem;
margin-bottom: 2.4rem;
}
@media (min-width: 1200px) {
.customer .account__wrapper .account__navigation {
max-width: 30.5rem;
padding-bottom: 0;
margin-bottom: 0;
}
}
.customer .account__wrapper .account__navigation .account__navigation-wrapper {
display: flex;
justify-content: space-between;
}
@media (min-width: 1200px) {
.customer .account__wrapper .account__navigation .account__navigation-wrapper {
display: block;
}
}
.customer .account__wrapper .account__navigation::after {
content: "";
position: absolute;
bottom: 0;
background-color: rgb(var(--color-border));
height: 1px;
width: 300vw;
right: -100vw;
transform: none;
}
@media (min-width: 1200px) {
.customer .account__wrapper .account__navigation::after {
width: 1px;
height: 100%;
right: 0;
transform: translate(-1.6rem);
}
}
.customer .account__wrapper .account__navigation-list {
padding: 0;
display: flex;
gap: 1.6rem;
flex-direction: row;
margin: 0;
}
@media (min-width: 1200px) {
.customer .account__wrapper .account__navigation-list {
margin-bottom: 8rem;
flex-direction: column;
}
}
.customer .account__wrapper .account__navigation-list li a {
color: rgb(var(--color-foreground-title));
opacity: 0.6;
text-decoration: none;
transition: all var(--duration-default) ease;
}
.customer .account__wrapper .account__navigation-list li a.active, .customer .account__wrapper .account__navigation-list li a:hover {
opacity: 1;
}
.customer .account__wrapper .account-block {
width: 100%;
max-width: 1190px;
}
.customer .account__wrapper .account-block__header {
padding: 0 0 3.2rem;
}
@media (min-width: 1200px) {
.customer .account__wrapper .account-block__header {
padding: 0 0 3.2rem 4.8rem;
}
}
.customer .account__wrapper .account-block__header h2 {
margin: 0;
}
.customer .account__wrapper .account-block .account-item {
position: relative;
padding: 3.2rem 0;
}
@media (min-width: 1200px) {
.customer .account__wrapper .account-block .account-item {
padding: 3.2rem 0 3.2rem 4.8rem;
}
}
.customer .account__wrapper .account-block .account-item::before {
content: "";
position: absolute;
top: 0;
left: -100vw;
width: 300vw;
height: 1px;
background-color: rgb(var(--color-border));
}
@media (min-width: 1200px) {
.customer .account__wrapper .account-block .account-item::before {
left: 0;
width: 100%;
}
}
.customer .account__wrapper .account-block .account-item.address-list__item-wrapper--default::before {
height: 0;
}
.customer .account__wrapper .account-block .account-item__title {
margin: 0 0 2.4rem;
}
.customer .account__wrapper .account-block .account-item__title-box {
display: flex;
align-items: center;
justify-content: space-between;
}
.customer .account__wrapper .account-block .account-item__title-box h1,
.customer .account__wrapper .account-block .account-item__title-box h2,
.customer .account__wrapper .account-block .account-item__title-box h3,
.customer .account__wrapper .account-block .account-item__title-box h4,
.customer .account__wrapper .account-block .account-item__title-box h5,
.customer .account__wrapper .account-block .account-item__title-box h6 {
margin: 0;
}
.customer .account__wrapper .address-list__wrapper {
display: flex;
align-items: flex-start;
justify-content: space-between;
}
.customer .customer-address__list.addresses-address__list {
display: flex;
flex-wrap: wrap;
max-width: 25rem;
}
.customer .addresses-address__list-item:not(:last-child)::after {
content: ",";
position: absolute;
bottom: 0;
right: -5px;
}
.customer .customer-address__item.addresses-address__list-item {
position: relative;
margin-right: 10px;
}
.customer .account-item__title-box-border {
position: relative;
}
.customer .account-item__title-box-border::before {
content: "";
position: absolute;
bottom: 0;
left: -100vw;
width: 300vw;
height: 1px;
background-color: rgb(var(--color-border));
}
@media (min-width: 1200px) {
.customer .account-item__title-box-border::before {
left: 0;
width: 100%;
}
}
.customer--margin-unset {
margin: 0;
}
.customer--height-unset {
min-height: unset;
}
.customer .address-buttons-block {
padding-top: 0.8rem;
}
.customer .total-price__fw {
font-weight: 500;
}
.customer .btn-remove {
margin-left: auto;
}

.customer .field input[aria-invalid=true] {
border-color: rgba(var(--color-error), 0.4);
}

.customer form {
margin-top: 2rem;
}
@media screen and (min-width: 990px) {
.customer form {
margin-top: 4rem;
}
}

.customer button {
margin: 4rem 0 1.5rem;
}

.customer ul {
line-height: 1.6;
padding-left: 4.4rem;
text-align: left;
margin-bottom: 4rem;
}

.customer ul a {
display: inline;
}

.customer strong {
font-weight: normal;
color: rgb(var(--color-foreground));
}

.customer .field {
margin: 2.5rem 0 0 0;
pointer-events: auto;
}

.customer .field__wrapper {
display: flex;
margin-top: 2.5rem;
}

.customer .field__wrapper .field {
margin: 0;
}

.customer .field:first-of-type {
margin-top: 0;
}

.customer .field {
margin-top: 2.5rem;
}

.customer__buttons {
display: flex;
align-items: center;
margin-top: 3rem;
}

.customer__buttons.center {
justify-content: center;
}

.customer__buttons.center > * {
width: 14rem;
}

.customer button {
margin: 0;
}

.form__errors {
padding: 2.4rem;
background-color: rgba(var(--color-error), 0.05);
}

.customer .form__message {
font-size: 1.4rem;
color: rgba(var(--color-error));
margin: 0 0 1rem 0;
font-weight: var(--font-body-weight);
}

.customer span.form__message {
display: flex;
font-size: 1.2rem;
margin: 1rem 0 0 0;
}

.customer span.form__message:before {
content: "*";
color: rgba(var(--color-error));
margin-right: 0.2rem;
}

.customer .form__message svg {
width: 2.6rem;
height: 2.6rem;
margin-right: 1rem;
}

.customer .errors {
margin-bottom: 24px;
}

.customer .errors ul {
display: flex;
flex-direction: column;
list-style: none;
padding-left: 0;
margin: 0.8rem 0 0;
}

.customer .errors ul li {
display: inline-flex;
font-size: 1.2rem;
line-height: 1;
color: rgba(var(--color-error));
position: relative;
}

.customer .errors ul li:before {
content: "*";
color: rgba(var(--color-error));
margin-right: 0.2rem;
}

.form__message:focus-visible {
outline: none;
box-shadow: none;
}

/* Customer Table */
.customer .order-table {
width: 100%;
}
.customer .order-table .row {
display: flex;
flex-direction: column;
width: 100%;
height: auto;
}
@media (min-width: 1200px) {
.customer .order-table .row {
flex-direction: row;
align-items: center;
min-height: 5.5rem;
}
}
.customer .order-table .row > * {
display: block;
padding: 0 2rem 0 0;
}
.customer .order-table .row > *::before {
content: attr(data-label);
display: inline-block;
max-width: 10rem;
width: 100%;
}
@media (min-width: 1200px) {
.customer .order-table .row > *::before {
display: none;
}
}
@media (min-width: 1200px) {
.customer .order-table .row > *:first-child {
width: 19.64%;
}
}
@media (min-width: 1200px) {
.customer .order-table .row > *:nth-child(2) {
width: 29.9%;
}
}
@media (min-width: 1200px) {
.customer .order-table .row > *:nth-child(3) {
width: 20.91%;
}
}
@media (min-width: 1200px) {
.customer .order-table .row > *:nth-child(4) {
width: 23.48%;
}
}
@media (min-width: 1200px) {
.customer .order-table .row > *:last-child {
width: 17.05%;
}
}
.customer .order-table .thead {
display: none;
}
@media (min-width: 1200px) {
.customer .order-table .thead {
display: flex;
min-height: unset;
margin-bottom: 1.6rem;
}
}
.customer .order-table .thead > * {
font-size: 1.4rem;
font-weight: 500;
color: rgb(var(--color-foreground-secondary));
position: relative;
}
.customer .order-table .tbody {
width: 100%;
border-top: 0.1rem solid rgb(var(--color-border));
border-bottom: 0.1rem solid rgb(var(--color-border));
text-decoration: none;
padding: 1.5rem 0;
}
.customer .order-table .tbody + .tbody {
margin-top: -1px;
}
.customer .order-table .tbody:not(div):hover {
background-color: rgb(var(--color-orders-hover));
position: relative;
}
.customer .order-table .tbody > * {
display: flex;
align-items: center;
padding: 1.3rem 0;
box-sizing: border-box;
color: rgb(var(--color-foreground-title));
}
@media (min-width: 1200px) {
.customer .order-table .tbody > * {
align-items: flex-start;
padding: 0;
}
}
.customer .order-table .tbody.tbody__none-border {
border: none;
}
.customer .order-table .tfoot {
display: block;
padding: 1.6rem 2.4rem;
background-color: rgb(var(--color-base-background-2));
margin-top: 3.2rem;
}
.customer .order-table .tfoot .customer-address__list.addresses-address__list {
justify-content: flex-end;
}
.customer .order-table .tfoot .customer-address__list.addresses-address__list .customer-address__item,
.customer .order-table .tfoot .customer-address__list.addresses-address__list .customer-address__item > span {
margin-bottom: 0;
font-weight: 400;
font-size: 1.6rem;
color: rgb(92, 92, 91);
text-transform: none;
}
.customer .order-table .tfoot .order__value-unstayled span:not(:first-child) {
font-weight: 400;
font-size: 1.6rem;
color: rgb(92, 92, 91);
text-transform: none;
}
.customer .order-table .tfoot > div {
display: flex;
width: 100% !important;
padding: 0;
margin-bottom: 2.4rem;
font-weight: 600;
text-transform: uppercase;
color: rgba(var(--color-foreground-title));
justify-content: space-between;
align-items: flex-start;
}
.customer .order-table .tfoot > div::before {
display: none;
}
.customer .order-table .tfoot > div.total-price {
border-bottom: 0.1rem solid rgb(var(--color-border));
padding-bottom: 3.2rem;
margin-bottom: 3.2rem;
}
.customer .order-table .tfoot > div span {
font-size: 1.4rem;
}
.customer .order-table .tfoot > div span:first-child {
margin-right: 0;
width: auto;
}
.customer .order-table--details {
border-bottom: 0;
border-top: 0;
}
@media (min-width: 1200px) {
.customer .order-table--details .row > *:first-child {
width: 38.596%;
}
}
.customer .order-table--details .row > *:first-child a,
.customer .order-table--details .row > *:first-child p {
text-decoration: none;
}
.customer .order-table--details .row > *:first-child .properties-block {
line-height: 1.7rem;
}
.customer .order-table--details .row > *:first-child .properties-block .properties {
display: inline-block;
padding-top: 0.8rem;
}
@media (min-width: 1200px) {
.customer .order-table--details .row > *:nth-child(2) {
width: 11.46%;
}
}
@media (min-width: 1200px) {
.customer .order-table--details .row > *:nth-child(3) {
width: 16.6%;
}
}
@media (min-width: 1200px) {
.customer .order-table--details .row > *:nth-child(4) {
width: 15.55%;
}
}
@media (min-width: 1200px) {
.customer .order-table--details .row > *:last-child {
width: 17.77%;
}
}
@media (min-width: 1200px) {
.customer .account-item-info__box .row {
min-height: unset;
}
}
.customer .account-item-info__box .tbody {
margin-top: 1.6rem;
}
@media (min-width: 1200px) {
.customer .account-item-orders .row {
padding: 0 1.2rem;
}
}
.customer--small-text {
color: rgb(var(--color-foreground-title));
}
.customer--small-heading {
font-size: var(--font-heading-h6-size);
}

/* Pagination */
.customer .pagination {
margin-top: 5rem;
margin-bottom: 7rem;
}

@media screen and (min-width: 990px) {
.customer .pagination {
margin-top: 7rem;
margin-bottom: 10rem;
}
}
.customer .pagination ul {
display: flex;
justify-content: center;
list-style: none;
padding: 0;
}

.customer .pagination li {
flex: 1 1;
max-width: 4rem;
}

.customer .pagination li:not(:last-child) {
margin-right: 1rem;
}

.customer .pagination li :first-child {
display: inline-flex;
justify-content: center;
align-items: center;
position: relative;
height: 4rem;
width: 100%;
padding: 0;
text-decoration: none;
}

.customer .pagination li :first-child svg {
height: 0.6rem;
}

.customer .pagination li:first-of-type svg {
margin-left: -0.2rem;
transform: rotate(90deg);
}

.customer .pagination li:last-of-type svg {
margin-right: -0.2rem;
transform: rotate(-90deg);
}

.customer .pagination li [aria-current]::after {
content: "";
display: block;
width: 2rem;
height: 0.01rem;
position: absolute;
bottom: 0.08rem;
left: 50%;
transform: translateX(-50%);
background-color: currentColor;
}

/* Login */
.customer.login {
width: 100%;
position: relative;
}

.register .page-header .title--page:not(:first-child) {
margin: 0;
}
.register .customer__buttons {
margin-top: 3rem;
gap: 2rem;
flex-wrap: wrap;
}
@media screen and (min-width: 990px) {
.register .customer__buttons {
margin-top: 4rem;
}
}
.register .customer__buttons > * {
pointer-events: auto;
width: auto;
}
.register .customer__buttons .login__page-link {
display: flex;
gap: 0.8rem;
color: rgb(var(--color-foreground-sub-title));
}

.login-wrapper .customer-form__placeholder-block {
max-width: 100%;
}
@media screen and (min-width: 576px) {
.login-wrapper .customer-form__placeholder-block {
max-width: 50%;
}
}
@media screen and (min-width: 990px) {
.login-wrapper .customer-form__placeholder-block {
max-width: 42.6rem;
width: 100%;
}
}
.login-wrapper .login .page-header {
text-align: left;
border-bottom: 0;
padding: 0;
margin-bottom: 0;
max-width: 100%;
}
@media (min-width: 576px) {
.login-wrapper .login .page-header {
max-width: 60%;
}
}
@media screen and (min-width: 990px) {
.login-wrapper .login .page-header {
margin-bottom: 0;
padding: 0;
max-width: 100%;
}
}
.login-wrapper .login .page-header__inner {
display: flex;
flex-direction: column;
gap: 2rem;
}
@media screen and (min-width: 990px) {
.login-wrapper .login .page-header__inner {
gap: 3.2rem;
}
}
.login-wrapper .login .page-header h1 {
margin-top: 1.5rem;
pointer-events: none;
}
.login-wrapper .login .page-header h1 i {
white-space: nowrap;
}
.login-wrapper .login .breadcrumb {
margin: 0;
pointer-events: auto;
}
.login-wrapper .login__subtitle {
pointer-events: none;
}
.login-wrapper .login__reset, .login-wrapper .login__form {
max-width: 100%;
}
@media screen and (min-width: 990px) {
.login-wrapper .login__reset, .login-wrapper .login__form {
max-width: 48.3rem;
width: 100%;
position: relative;
z-index: 4;
}
}
.login-wrapper .login__reset {
margin-bottom: 2rem;
pointer-events: none;
}
.login-wrapper .login__form {
display: flex;
flex-direction: column;
justify-content: flex-start;
pointer-events: none;
}
.login-wrapper .login__field {
display: flex;
flex-direction: column;
pointer-events: auto;
}
.login-wrapper .login__forgot-password {
margin-top: 2rem;
pointer-events: auto;
}
@media screen and (min-width: 990px) {
.login-wrapper .login__forgot-password {
margin-top: 4rem;
}
}
.login-wrapper .login__buttons {
gap: 1.6rem;
flex-wrap: wrap;
}
.login-wrapper .login__sign-in {
pointer-events: auto;
}
.login-wrapper .login__create-acc {
pointer-events: auto;
}

.login .field + a {
margin-top: 1rem;
}

.login p {
margin: 1.5rem 0;
}

.login h3 {
margin-top: 1.5rem;
text-align: left;
font-size: 1.4rem;
}

#customer_login_guest button {
margin-top: 0;
}

#recover {
display: none;
}

#recover:target {
display: block;
}

#recover:target ~ #login__form {
display: none;
}

.activate button[name=decline] {
background-color: transparent;
box-shadow: 0 0 0 0.1rem rgb(var(--color-link));
color: rgb(var(--color-link));
}

.activate button[name=decline]:hover {
box-shadow: 0 0 0 0.2rem rgb(var(--color-link));
}

@media only screen and (min-width: 750px) {
.activate button[name=decline] {
margin-top: inherit;
margin-left: 1rem;
}
}
/* Account/Order */
:is(.account, .order, .addresses) p {
margin: 0 0 2.4rem;
}

:is(.account, .order, .addresses) h1 {
margin-bottom: 1rem;
}

.account-row h2 {
margin-top: 0;
margin-bottom: 1.6rem;
--font-heading-weight: 500;
letter-spacing: -0.02em;
}

.account h1 + a {
display: inline-flex;
align-items: center;
font-size: 1.3rem;
text-align: left;
}
@media (min-width: 1200px) {
.account h1 + a {
margin-top: 0;
text-align: center;
}
}

@media screen and (min-width: 990px) {
.account table td,
.order table tbody td {
padding-top:1rem;
padding-bottom:1rem;
}
}
.account table td:first-of-type a,
.order table td:first-of-type a {
font-size: 1.4rem;
color: rgb(var(--color-foreground));
}

.account table td:first-of-type a:hover,
.order table td:first-of-type a:hover {
color: rgb(var(--color-foreground), 0.75);
}

@media (min-width: 990px) {
.account .page-header,
.order .page-header {
margin-bottom: 4rem;
padding-bottom: 3rem;
}
}
@media (min-width: 1200px) {
.account .page-header,
.order .page-header {
margin-bottom: 2rem;
padding-bottom: 2rem;
}
}

.addresses .page-header {
border-bottom: none;
}
@media (min-width: 750px) {
.addresses .page-header {
margin-bottom: 0;
padding-bottom: 4rem;
}
}
.addresses .address-add-item,
.addresses .address-cancel {
margin-bottom: 1.5rem;
}
@media (min-width: 1200px) {
.addresses .address-add-item,
.addresses .address-cancel {
margin-bottom: 0;
}
}
.addresses .address-edit-buttons {
display: flex;
flex-wrap: wrap;
align-items: center;
justify-content: space-between;
gap: 0.8rem;
margin-top: 4rem;
}
.addresses .address-add-item {
padding: 0 3.5rem;
}

/* Addresses */
.addresses li > button {
margin-left: 0.5rem;
margin-right: 0.5rem;
}

.addresses li > button + button,
.addresses form button + button {
margin-top: 0rem;
}

@media screen and (min-width: 750px) {
.addresses li > button:first-of-type {
margin-top: 3rem;
}
}
.address-add-buttons {
display: flex;
flex-wrap: wrap;
gap: 1rem;
}

label[for=AddressCountryNew],
label[for=AddressProvinceNew] {
display: block;
font-size: 1.4rem;
margin-bottom: 0.6rem;
}

.addresses form {
display: grid;
gap: 2.4rem;
}

.addresses form .field {
margin: 0;
}

.addresses ul {
list-style: none;
padding-left: 0;
}

.address-list {
display: flex;
flex-direction: column;
}

.address-list__item-wrapper--default {
order: -1;
}

.addresses [data-expand=false] {
display: none !important;
}

.addresses [data-expand=true] {
display: block !important;
}
.addresses [data-expand=true].account-none-address {
display: flex !important;
}

.address-edit-form {
display: none;
padding-bottom: 3.2rem;
}

.address-edit-form.active {
display: block;
}

.addresses .address-add-form {
display: flex;
flex-direction: column;
align-items: center;
}

.address-add-form .account-none-address {
width: 100%;
}

.addresses .address-add-form__item {
width: 100%;
position: relative;
}
.addresses .address-add-form__item::before {
content: "";
position: absolute;
bottom: 0;
left: 0;
width: 100%;
height: 1px;
background-color: rgb(var(--color-border));
}

.addresses .address-add-form__inner {
padding: 3.2rem 0;
}
@media (min-width: 1200px) {
.addresses .address-add-form__inner {
padding: 3.2rem 0 3.2rem 4.8rem;
}
}

.addresses .address-add-form__inner h2 {
margin: 0;
font-weight: var(--font-body-weight);
}

.address-add-default-check {
display: flex;
align-items: center;
font-size: 1.2rem;
}

.addresses h2 {
text-align: left;
}

li[data-address] > h2 {
margin: 0 0 2rem 0;
}

@media only screen and (min-width: 990px) {
.addresses form {
grid-template-columns: 1fr 1fr 1fr 1fr;
grid-template-rows: auto;
grid-template-areas: "a1 a2 a2 a3" "a4 a4 a5 a5" "a9 a9 a10 a10" "a7 a7 a6 a6" "a8 a8 a a" "a11 a11 a11 a11" "a12 a12 a12 a12";
}
.addresses form div:nth-of-type(1) {
grid-area: a1;
}
.addresses form div:nth-of-type(2) {
grid-area: a2;
}
.addresses form div:nth-of-type(3) {
grid-area: a3;
}
.addresses form div:nth-of-type(4) {
grid-area: a4;
}
.addresses form div:nth-of-type(5) {
grid-area: a5;
}
.addresses form div:nth-of-type(6) {
grid-area: a6;
}
.addresses form div:nth-of-type(7) {
grid-area: a7;
}
.addresses form div:nth-of-type(8) {
grid-area: a8;
}
.addresses form div:nth-of-type(9) {
grid-area: a9;
}
.addresses form div:nth-of-type(10) {
grid-area: a10;
}
.addresses form div:nth-of-type(11) {
grid-area: a11;
}
.addresses form div:nth-of-type(12) {
grid-area: a12;
}
}
.addresses ul p {
margin-bottom: 0;
}

.customer .customer-address__list {
list-style: none;
padding: 0;
margin: 0;
}

.customer .customer-address__item {
color: rgb(var(--color-foreground-title));
}

.customer .customer-address__item:not(:last-child) {
margin-bottom: 1.2rem;
}

.customer .order-address__list {
list-style: none;
padding: 0;
margin: 0;
}

.customer .order-status {
font-size: 1.2rem;
letter-spacing: 0.075em;
text-transform: uppercase;
color: rgb(var(--color-foreground));
font-weight: var(--font-button-weight);
margin: 0 0 1.2rem;
}

.icon-account-order-none {
width: 36px;
height: 36px;
margin-bottom: 0.8rem;
color: rgb(var(--color-foreground-title));
}

.icon-account-address-none {
width: 37px;
height: 35px;
margin-bottom: 1.3rem;
color: rgb(var(--color-foreground-title));
}

.account-none {
display: flex;
flex-direction: column;
align-items: flex-start;
}

.customer .account-row-title {
display: flex;
flex-wrap: wrap;
align-items: flex-end;
gap: 1rem;
margin-bottom: 1.6rem;
}

.customer .account-row-title h2 {
margin: 0;
}

.customer .account-row-title p {
align-self: center;
margin: 0;
font-size: 1.2rem;
color: rgba(var(--color-foreground), 0.6);
}
@media screen and (min-width: 1440px) {
.customer .account-row-title p {
padding-top: 0.8rem;
}
}

.login-wrapper {
display: flex;
flex-wrap: wrap;
gap: 4.8rem;
}
@media screen and (min-width: 990px) {
.login-wrapper {
flex-wrap: nowrap;
gap: 3.6rem;
}
}
.login-wrapper__image {
flex: 0 0 100%;
max-width: 100%;
}
@media screen and (min-width: 990px) {
.login-wrapper__image {
flex: 0 0 40%;
max-width: 40%;
}
}
.login-wrapper__image-box {
position: relative;
width: 100%;
height: 100%;
}
.login-wrapper__image-box img {
height: 100%;
width: 100%;
object-fit: cover;
}
.login-wrapper__image-box::after {
content: "";
position: absolute;
top: 0;
width: 100%;
height: 1px;
background-color: rgb(var(--color-border));
left: 0;
transform: translateY(-2.4rem);
}
@media screen and (min-width: 990px) {
.login-wrapper__image-box::after {
width: 1px;
height: 100%;
transform: translate(-1.6rem);
}
}

.order .order__title {
display: flex;
align-items: center;
justify-content: space-between;
padding-bottom: 3.2rem;
margin-bottom: 3.2rem;
}

.customer-form__placeholder-block .field input:not(input[type=checkbox]) + label {
display: inline-block;
position: absolute;
top: 50%;
transform: translateY(-50%);
left: 1.6rem;
transition: all var(--duration-default) ease;
}

.customer-form__placeholder-block .field input:not(input[type=checkbox]):not(:placeholder-shown) + label {
font-size: 1.2rem;
color: rgb(115, 115, 114);
display: inline-block;
position: absolute;
top: 0.3rem;
left: 1.6rem;
transform: translateY(0);
}

.customer-form__placeholder-block .field input:not(input[type=checkbox]):focus + label {
font-size: 1.2rem;
color: rgb(115, 115, 114);
top: 0.3rem;
transform: translateY(0);
}

.customer input[type=text],
.customer input[type=email],
.customer input[type=tel],
.customer input[type=password],
.customer .field__input,
.article-template input[type=text],
.article-template input[type=email],
.article-template input[type=tel],
.article-template input[type=password],
.article-template .field__input {
padding: 2rem 1.8rem 0.7rem;
}

.default-address {
background-color: var(--color-badge-bg);
color: var(--color-badge-text);
margin: 0;
padding: 0.6rem 1.2rem;
flex: none;
}

.custom-checkbox input[type=checkbox]:checked + label .custom-checkbox__icon {
background-color: rgb(var(--color-foreground-title));
}
.custom-checkbox input[type=checkbox]:checked + label .custom-checkbox__icon .icon {
display: block;
}
.custom-checkbox__icon {
width: 1.6rem;
height: 1.6rem;
display: inline-flex;
align-items: center;
justify-content: center;
color: rgb(var(--color-background));
margin-right: 1rem;
border: 0.1rem solid rgb(var(--color-foreground-title));
}
.custom-checkbox__icon .icon {
display: none;
}

.no-js .addresses [data-expand] {
display: block !important;
}
/*============================================================================*/
@media (max-width:968px) {
.customer ul a{
font-size: 16px !important;
}
}
.address-list__inner{
padding-bottom: 15px;
border-bottom: 1px solid #ddd;
}
/*============================================================================*/
.article-header__info,
.article-footer__info {
display: flex;
align-items: center;
justify-content: center;
flex-wrap: wrap;
line-height: 1;
font-size: 1.4rem;
flex-direction: column;
gap: 1rem;
opacity: 0.7;
margin-bottom: 0;
}
@media screen and (min-width: 990px) {
.article-header__info,
.article-footer__info {
flex-direction: row;
gap: 0;
}
.article-header__info > *,
.article-footer__info > * {
display: flex;
align-items: center;
margin-bottom: 0.2rem;
}
.article-header__info > *:last-child:after,
.article-footer__info > *:last-child:after {
display: none;
}
.article-header__info > *:after,
.article-footer__info > *:after {
content: "";
display: inline-block;
width: 4px;
height: 4px;
margin: 0 1.2rem;
background: currentColor;
}
}
.article-header__tags,
.article-footer__tags {
margin: 0 0 0.2rem;
padding: 0;
list-style: none;
}
.article-header__tags li.article__tags__delimiter,
.article-footer__tags li.article__tags__delimiter {
margin: 0 0.5rem;
}
.article-header__author span,
.article-footer__author span {
margin-left: 0.5rem;
}

.article-template__container {
max-width: 153.5rem;
padding-left: 2rem;
padding-right: 2rem;
margin-left: auto;
margin-right: auto;
box-sizing: border-box;
}
.article-template__comment-wrapper {
margin-top: 5rem;
padding: 3rem 0;
}
@media screen and (min-width: 990px) {
.article-template__comment-wrapper {
margin-top: 6rem;
}
}
.article-template__comment-wrapper h2 {
margin-top: 0;
}
.article-template__comments {
margin-bottom: 5rem;
}
@media screen and (min-width: 990px) {
.article-template__comments {
margin-bottom: 7rem;
}
}
.article-template__comments-fields {
margin-bottom: 4rem;
}
.article-template__comments-fields .text-area {
width: 100%;
}
.article-template__comments-comment {
color: rgba(var(--color-foreground), 0.75);
background-color: rgba(var(--color-foreground), 0.04);
margin-bottom: 1.5rem;
padding: 2rem 2rem 1.5rem;
}
@media screen and (min-width: 990px) {
.article-template__comments-comment {
padding: 2rem 2.5rem;
}
}
.article-template__comments-comment p {
margin: 0 0 1rem;
}
@media screen and (min-width: 990px) {
.article-template__comments .pagination-wrapper {
margin: 5rem 0 8rem;
}
}
.article-template__comment-fields > * {
margin-bottom: 3rem;
}
@media screen and (min-width: 990px) {
.article-template__comment-fields {
display: grid;
grid-template-columns: repeat(2, 1fr);
grid-column-gap: 4rem;
}
}
.article-template__comment-warning {
margin: 2rem 0 2.5rem;
}
.article-template .share-buttons-simple__item a {
display: flex;
align-items: center;
}
.article-template .share-buttons-simple__item .icon {
transition: none;
}
.article-template .share-buttons-simple__item:hover .icon {
opacity: 0.8;
transition: all var(--duration-long) linear;
}

.article-header {
text-align: center;
margin-bottom: 2rem;
}
@media screen and (min-width: 990px) {
.article-header {
margin-bottom: 4rem;
}
}
@media screen and (min-width: 1200px) {
.article-header {
margin-bottom: 8rem;
}
}
.article-header .breadcrumb {
margin: 0;
}
.article-header:not(.header-full-screen) .article-header__text {
margin-top: 3.2rem;
}
@media screen and (min-width: 990px) {
.article-header:not(.header-full-screen) .article-header__text {
margin-top: 6.4rem;
}
}
@media screen and (min-width: 990px) {
.article-header.section_border_top {
padding-top: 4rem;
}
}
@media screen and (min-width: 1200px) {
.article-header.section_border_top {
padding-top: 8rem;
}
}
@media screen and (min-width: 990px) {
.article-header.section_border_bottom {
padding-bottom: 4rem;
}
}
@media screen and (min-width: 1200px) {
.article-header.section_border_bottom {
padding-bottom: 8rem;
}
}
.article-header__info {
margin-bottom: 1.6rem;
}
.article-header__title {
margin: 0;
}
.article-header__title:not(:only-child):not(:last-child) {
margin-bottom: 2.4rem;
}
@media screen and (min-width: 750px) {
.article-header__title:not(:only-child):not(:last-child) {
margin-bottom: 3.2rem;
}
}
.article-header__excerpt {
max-width: 53rem;
margin: 0 auto;
}
.article-header__img {
margin-top: 3.2rem;
position: relative;
z-index: 1;
}
.article-header__img img {
width: 100%;
height: 100%;
position: absolute;
left: 0;
top: 0;
object-fit: cover;
object-position: center;
}
.article-header__img::after {
content: "";
display: block;
padding-bottom: var(--image-height);
}
@media screen and (min-width: 990px) {
.article-header__img {
margin-top: 6.4rem;
}
}
.article-header__share-buttons:not(:only-child) {
margin-top: 1.6rem;
}
@media screen and (min-width: 990px) {
.article-header__share-buttons:not(:only-child) {
margin-top: 3.2rem;
}
}
.article-header__wrapper.have-paddings .article-header__content {
padding: 3rem 0;
}
.article-header.header-full-screen .article-header__wrapper {
display: flex;
align-items: center;
justify-content: center;
position: relative;
z-index: 1;
}
.article-header.header-full-screen .article-header__wrapper::after {
content: "";
display: block;
padding-bottom: var(--image-height);
}
.article-header.header-full-screen .article-header__wrapper.have-overlay::before {
background: var(--article-overlay);
}
.article-header.header-full-screen .article-header__img {
position: absolute;
left: 0;
top: 0;
width: 100%;
height: 100%;
z-index: -3;
margin: 0;
}
.article-header.header-full-screen .article-header__img::after {
display: none;
}
.article-header.header-full-screen .breadcrumb {
justify-content: center;
margin: 0 0 3.2rem;
}

.article-footer {
padding: 2rem 0;
}
.article-footer__wrapper {
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
gap: 1rem;
}
@media screen and (min-width: 990px) {
.article-footer__wrapper {
flex-direction: row;
gap: 3rem;
}
}
.article-footer__info + .article-footer__share {
margin: 0 auto;
}
@media screen and (min-width: 990px) {
.article-footer__info + .article-footer__share {
margin-right: 0;
}
}
@media screen and (min-width: 990px) {
.article-footer {
padding: 4rem 0;
}
}
@media screen and (min-width: 1200px) {
.article-footer {
padding: 8rem 0;
}
}
.article-footer .share-buttons-simple__item.subtitle {
padding-right: 1.3rem;
opacity: 0.5;
margin: 0;
font-size: 1.4rem;
}

.article-content {
overflow: hidden;
}
.article-content__wrapper {
max-width: 92.5rem;
margin: 0 auto;
padding: 0 2rem;
}
.article-content__wrapper > *:first-child + *, .article-content__wrapper > *:first-child {
margin-top: 0;
}
.article-header__wrapper {
--image-height: calc(100vh - 70px - 40px);
--article-overlay: rgba(var(--color-overlay), 20%);
}
/*============================================================================*/
/*============================================================================*/
/*============================================================================*/
/*============================================================================*/
/*============================================================================*/
/*============================================================================*/
.pss-slider {
display: flex;
flex-wrap: wrap;
position: relative;
}
.pss-slider .gallery-thumbs {
order: -1;
max-width: 109px;
position: relative;
overflow: hidden;
flex-basis: 100%;
margin-right: 10px;
}
.gallery-thumbs .swiper-wrapper {
display: block;
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
transition: top 0.3s ease-out;
}
.gallery-thumbs .swiper-slide {
position: relative;
cursor: pointer;
overflow: hidden;
}
.gallery-thumbs .swiper-slide img {
width: 100%;
height: 100%;
object-fit: cover;
}
.gallery-thumbs .swiper-slide {
display: flex;
align-items: center;
justify-content: center;
box-sizing: border-box;
cursor: pointer;
}
.gallery-thumbs .swiper-slide-active:before {
content: "";
transition: border-color 0.3s;
border: 2px solid var(--color-primary);
position: absolute;
left: 0;
right: 0;
top: 0;
bottom: 0;
border-radius: 10px;
}
.gallery-thumbs .swiper-slide:hover {
border: 2px solid var(--color-primary);
border-radius: 10px;
}
.pss-slider .gallery-top {
max-width: calc(100% - 119px);
flex-basis: 100%;
}
.gallery-top .swiper-slide {
display: flex;
align-items: center;
justify-content: center;
box-sizing: border-box;
cursor: pointer;
}
.pss-slider .gallery-top .swiper-slide img {
width: 100%;
}
.swiper-slide video {
width: 100%;
object-fit: cover;
height: 100%;

}
.gallery-page__single {
position: relative;
display: block;
margin-bottom: 0;
width: 100%;
}
.gallery-page__img {
position: relative;
display: block;
overflow: hidden;
z-index: 1;
}
.gallery-page__img img {
width: 100%;
-webkit-transform: scale(1);
transform: scale(1);
-webkit-transition: -webkit-transform 500ms ease;
transition: -webkit-transform 500ms ease;
transition: transform 500ms ease;
transition: transform 500ms ease, -webkit-transform 500ms ease;
border-radius: 10px;
}
.gallery-page__icon {
position: absolute;
bottom: 15px;
right: 15px;
font-size: 24px;
}
.gallery-page__video {
position: relative;
display: block;
overflow: hidden;
z-index: 1;
}
.gallery-page__video video {
width: 100%;
height: 430px;
object-fit: cover;
}
@media (max-width: 991px) {
.gallery-page__img img {
border-radius: 0px;
}

.gallery-page__video video {
width: 100%;
height: 380px;
object-fit: cover;
}
}
.pss-slider-arrow{
position: absolute;
top: 50%;
left: 0;
width: 100%;
z-index: 99;
padding: 0px 25px;
}
.pss-slider-arrow .pss-slider-arrow-inner{
position: relative;
display: flex;
align-items: center;
justify-content: space-between;
}
.pss-slider-arrow .prev-arrow{
transform: rotate(-180deg);
position: absolute;
left: 16%;
cursor: pointer;
}
.pss-slider-arrow .next-arrow{
position: absolute;
right: 0;
cursor: pointer;
}
/*============================================================================*/
.cartHeader{
display: flex;
align-items: center;
width: 100%;
padding: 0.5rem 0;
border-bottom: 1px solid rgb(var(--color-border));
height: 75px;
}
.cartHeader .container, .cartHeader .container-fluid, .mheader-center, .mheader-left, .mheader-right {
align-items: center;
display: flex;
}
.mheader-left, .mheader-right {
flex: 1 1;
}
.step-by {
display: flex;
justify-content: center;
flex-wrap: wrap;
padding: 10px;
}
.step-by .title-step {
margin:0 3.8rem 0 0rem;
margin-left: 1rem;
font-size: 18px !important;
text-transform: uppercase;
font-weight: 500 !important;
color: var(--color-black);
padding: .4rem 0;
position:relative;
}
.step-by .title-step a{
text-decoration: none;
}
.step-by .title-step svg{
position: absolute;
right: -45%;
top: 10px;
}
.step-by .title-step:first-child {
margin-left: 0;
}
.step-by .title-step.active a{
color:#2e3192 !important;
}
.title-simple {
display: block;
}
.secure-img{
width:125px;
}
.mobileheader{
display: flex;
align-items: center;
width: 100%;
padding:0px 15px;
border-bottom: 1px solid rgb(var(--color-border));
height: 65px;
justify-content: space-between;
}
.mobileheader-title{
display: flex;
align-items: center;
}
.mobileheader-title svg{
transform: rotate(-180deg);
margin-right: 15px;
}
/*============================================================================*/
.cartsec {
width: 100%;
position: relative;
background:var(--color-white);
margin-bottom: 25px;
border-bottom: 1px solid rgb(var(--color-border-input));
padding-bottom: 25px;
}
.cartsec .cartsec-media{
width:100%;
position:relative;
}
.cartsec .cartsec-media img{
width:100%;
}
.cartsec .cartsec-name {
font-size: 20px;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
}
.cartsec .cartsec-price {
display: flex;
align-items: center;
font-size: 18px;
margin-top: 15px;
}
.cartsec-price .price-old {
text-decoration: line-through;
color:#aaa;
}
.cartsec-footer {
display: flex;
align-items: center;
justify-content: space-between;
margin-top:30px;
}
.qtyinput{
display: flex;
align-items: center;
justify-content: center;
}
@media (max-width: 991px) {
.cartsec{
padding: 15px;
margin-bottom: 0px;
} 
.cartsec-name{
font-size: 14px !important;
}
.cartsec-name a{
text-decoration: none;
}
.cartsec .cartsec-price {
display: flex;
align-items: center;
font-size: 14px;
margin-top: 10px;
}
.cartsec-footer {
display: flex;
align-items: center;
justify-content: space-between;
margin-top: 15px;
}
.applypanel{
background: #fff !important;
}
.cartsummary{
background: #fff !important;
}
.mcfooter{
position: fixed;
bottom: 0;
left: 0;
width: 100%;
padding: 15px;
background: #fff;
border-top: 1px solid #ddd;
}
}
/*============================================================================*/
.panel {
background:rgb(var(--color-background-input));
border: 1px solid rgb(var(--color-border-input));
position: relative;
width: 100%;
}
.panel .panel-header {
align-items: center;
border-bottom: 1px solid rgb(var(--color-border-input));
display: flex;
font-size: 18px;
font-weight: 600;
padding-bottom: 15px;
}
/*============================================================================*/
.applycoup-desktop{
display:flex;
align-items:center;
justify-content:space-between;
cursor:pointer;
height: 35px;
}
.applycoup-desktop h6{
font-size:14px;
font-weight:600;
margin-top:5px;
}
.applycoup-desktop .applycoup-mobile-text {
display: flex;
align-items: center;
}
.applycoup-mobile{
width:100%;
position:relative;
border-bottom: 2px solid var(--color-gray-01);
}
.applycoup-mobile .applycoup-mobile-inner{
display:flex;
align-items:center;
justify-content:space-between;
height:50px;
padding:0px 15px;
}
.applycoup-mobile .applycoup-mobile-text{
display:flex;
align-items:center;
}
.applycoup-mobile-text img{
margin-right:10px;
width:24px;
}
.apply-coupons-box{
display:flex;
align-items:center;
}
.apply-coupons-box input{
width:100%;
height:50px;
border:1px solid #ddd;
border-radius:5px;
padding:15px;
font-size:13px;
}
.apply-coupons-box .apply-buttton{
background:var(--color-primary);
border:1px solid var(--color-primary);
flex:1;
margin-left:10px;
height:50px;
padding:0px 25px;
border-radius:5px;
color:#fff;
cursor: pointer;
font-size:15px;
}
/*==========================================================*/
.couponModal .modal-dialog{
max-width: 450px;
}
.couponModal .couponModal-header{
padding:25px 25px 10px 25px;
width:100%;
position:relative;
color:#333;
}
.couponModal .couponModal-search{
width:100%;
position:relative;
padding:0px 25px;
}
.couponModal-search input{
width:100%;
height:45px;
border:1px solid #ddd;
border-radius:5px;
padding:0px 15px;
}
.couponModal .couponModal-search button{
position: absolute;
top: 0;
right: 26px;
height: 43px;
background: transparent;
border: 0px;
width: 75px;
}
.apply-coupons-list{
width:100%;
position:relative;
color:#333;
}
.apply-coupons-list ul{
margin:0;
padding:0;
list-style:none;
}
.apply-coupons-list ul li{
display:inline-block;
width:100%;
position:relative;
padding:10px 25px;
}
@media (max-width: 991px) {
.couponModal .modal-dialog {
height: 100%;
margin: 0;
max-width: 100%;
}
.couponModal .modal-content {
border: 0;
border-radius: 0;
height: 100%;
}
}
.aclbox{
background:#f5f5f5;
padding:15px;
border-radius:5px;
position:relative;
width:100%;
}
.aclbox .aclbox-coupon{
border: 1px dashed #2e3192  !important;
display:inline-block;
padding:3px 10px;
border-radius:5px;
margin-bottom:10px;
font-weight:600;
font-size: 14px;
}
.aclbox .aclbox-apply{
position:absolute;
top:15px;
right:15px;
color:#2e3192;
cursor:pointer;
font-size: 14px;
}
/*============================================================================*/
.pcb-list {
width: 100%;
position: relative;
}
.pcb-list ul {
margin: 0;
padding: 0;
list-style: none;
}
.pcb-list ul li {
display: flex;
align-items: center;
height: 35px;
}
.ml-auto, .mx-auto {
margin-left: auto !important;
}
.pcb-list-second {
width: 100%;
position: relative;
}
.pcb-list-second ul {
margin: 0;
padding: 0;
list-style: none;
}
.pcb-list-second ul li {
display: flex;
align-items: center;
height: 40px;
font-size: 16px;
font-weight: 700;
}
/*============================================================================*/
.capvs .container{
max-width: 1400px !important;
}
.capvs-right{
padding-left: 50px;
}
@media (max-width: 991px) {
.capvs-right{
padding-left: 0px;
} 
}
/*============================================================================*/
.noimg{
width: 100%;
position: relative;
display: flex;
align-items: center;
flex-direction: column;
padding: 50px;
}
.noimg img{
width: 300px;
}
.stock-text{
margin-top: 20px;
display: flex;
align-items: center;
gap: 10px;
margin-bottom: 10px;
}
.stock-text .instock{
color: #33ac3b;
}
.stock-text .outofdtock{
color: red;
}
.dvariation-list{
display: flex;
align-items: center;
gap: 15px;
margin-top: 10px;
}
.dvariation-list .size{
padding: 10px 20px;
text-decoration: none;
border: 1px solid #ddd;
min-width: 100px;
text-align: center;
}
.dvariation-list .size.active{
border: 1px solid #3053A5;
background: #3053A5;
color: #fff !important;
}
/*============================================================================*/
.pop-close {
box-sizing: content-box;
width: 1em;
height: 1em;
padding: .25em .25em;
color: #000;
background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
border: 0;
border-radius: .25rem;
opacity: .5;
position: absolute;
top:15px;
right: 15px;
cursor: pointer;
z-index: 9999;
}
.addressModal .modal-dialog{
max-width:500px;
}
.addressModal .modal-body{
padding:25px;
}
.addressModal-header{
border-bottom:1px solid #eee;
}
.addressModal-header-title{
background:#f5f5f5;
padding:10px 15px;
}
.addressModal .modal-footer{
display:flex;
flex-wrap: unset;
}
.form-group-white{
position: relative;
width: 100%;
}
.form-group-white label{
color:#333;
margin-bottom:5px;
font-size:13px;
font-weight:600;
}
.form-group-white .form-control {
border: 1px solid #ddd;
height: 45px;
padding: 0px 15px;
border-radius: 0px;
font-size:13px;
}
@media (max-width: 991px) {
.addressModal .modal-dialog {
height: 100%;
margin: 0;
max-width: 100%;
}
.addressModal .modal-content {
border: 0;
border-radius: 0;
height: 100%;
}
.addressModal-header {
border-bottom: 1px solid #eee;
padding: 15px;
}
}
.maddress-footer{
position:fixed;
bottom:0;
left:0;
padding:15px;
width:100%;
border-top:1px solid var(--color-gray-01);
z-index: 999999;
background: #fff !important;
}
/*============================================================================*/
.addresscard{
padding:15px 15px 15px 40px;
width:100%;
position:relative;
margin-top:15px;
background:#f5f5f5;
border: 1px solid #ddd;
}
.addresscard h6{
display:flex;
margin-bottom:5px;
}
.save-address-check {
width: 16px;
height: 16px;
border-radius: 50%;
border: 2px solid #ddd;
background: #f5f5f5;
position: absolute;
left: 15px;
top: 17px;
}
.address-full{
font-size:12px;
}
.save-address-checkout.active .save-address-check{
background:var(--color-primary);
}
.addresscard-type{
background: var(--color-gray);
padding: 2px 10px;
border-radius: 10px;
text-transform: uppercase;
position: absolute;
top: 10px;
right: 10px;
font-size: 11px;
}
@media (max-width:968px) {
.addresscard {
border-bottom: 2px solid #ddd;
border-right: 0px !important;
border-top: 0px !important;
border-left: 0px !important;
border-radius: 0;
padding: 15px 15px 15px 50px;
width: 100%;
position: relative;
background:var(--color-white);
margin-top:0px;
}
.addresscard h6{
display:flex;
margin-bottom:0px;
font-size:12px;
}
.addresscard-type{
background:var(--color-gray);
}
.addressbutton{
position:fixed;
bottom:0;
left:0;
width:100%;
border-top:1px solid var(--color-gray-01);
padding:15px;
}
}
/*============================================================================*/
.address-checkout{
width:100%;
position:relative;
}
.change-address {
position: absolute !important;
right: 15px;
top: 14px;
font-size: 12px;
color:var(--color-primary) !important;
z-index: 9;
text-transform:uppercase;
}
.checkout-accord{
background:var(--color-white) !important;
width:100%;
position:relative;
border-radius:5px;
border:1px solid var(--color-gray-01) !important;
color:var(--color-black) !important;
}
.checkout-accord .checkout-accord-header{
width:100%;
position:relative;
padding:15px;
display:flex;
align-items:center;
justify-content:space-between;
}
.checkout-accord .checkout-accord-body{
width:100%;
position:relative;
}
.checkout-accord .accordion-button{
background: #fff !important;
color: #000 !important;
border-bottom:1px solid #ddd !important;
box-shadow:none !important;
padding: 20px 10px 20px 0px !important;
}
.checkout-accord .accordion-button:after{

}
.checkout-accord .accordion-body{
padding:15px;
border-bottom: 1px solid #eee;
}

@media (max-width:968px) {
.checkout-accord{
margin-bottom:0px;
border-bottom:2px solid var(--color-white) !important;
border-radius:0px;
background:var(--color-white) !important;
}
.checkout-accord .accordion-button{
background:var(--color-white) !important;
padding: 15px !important;
}
.accordion-header h6{
font-size:12px;
}
.address-checkout{
background:var(--color-white) !important;
border-radius:0px;
border:0px;
padding: 15px;
}
.address-checkout h6{
font-size:12px;
}
}
.payment-option-list{
width: 100%;
position: relative;
}
.payment-option-list ul{
margin: 0;
padding: 0;
list-style: none;
}
.payment-option-list ul li{
display: flex;
align-items: center;
height: 55px;
border-bottom: 1px solid #eee;
font-size: 13px;
font-weight: 500;
padding: 0px;
color:#000;
padding:0px;
}
.payment-option-list ul li img{
width:28px;
height:28px;
margin-right: 10px;
}
.payment-option-list ul li:last-child{
border-bottom: 0px solid #eee;
}
.form-check-input{
border: 2px solid #666 !important;
width: 16px !important;
height: 16px !important;
}
@media (max-width:968px) {
.capvs .accordion{
margin: 0px !important;
}
.checkutsec .cartsec{
padding: 0px;
display: inline-block;
}
.payment-option-list ul li{
padding: 0px 15px;
}
}
/*==================================================*/
.order-box{
position: relative;
background: #fff;
width: 100%;
display: flex;
border-radius: 5px;
margin-bottom: 20px;
border: 1px solid #eee;
}
.order-box .info {
position: relative;
width: 75%;
border-right: 1px dashed #e3e3e3;
padding: 15px;
}
.order-box .bcode {
position: relative;
width: 25%;
padding: 25px;
text-align: center;
}
.order-box .orderid-box {
background:#f5f5f5;
border-radius:5px;
padding: 15px 10px 10px 10px;
}
.morderbox{
width:100%;
position: relative;
display: inline-block;
border: 1px solid #ddd;
}
.morderbox .morderbox-body{
padding: 15px;
}
.morderbox .morderbox-footer{
display: flex;
align-items: center;
justify-content: space-between;
padding: 5px 15px 15px;
}
.morderbox-link{
font-size: 12px;
}
.morderbox-status{
font-size: 12px;
padding: 5px 10px;
border-radius: 5px;
}
.maddressbox{
width:100%;
position: relative;
background:var(--color-white);
display: inline-block;
border-bottom:1px solid var(--color-gray-01);
}
.maddressbox .maddressbox-body{
padding: 15px 75px 15px 15px;
}
.maddressbox .maddressbox-footer{
display: flex;
align-items: center;
padding: 5px 15px 15px;
}
.maddressbox .maddressbox-footer a{
margin-right: 20px;
color:#6d1a17;
font-weight: 600;
}
.tx-12{
font-size: 12px;
}
.tx-13{
font-size: 13px;
}
.tx-14{
    font-size: 14px;
    }
    .tx-15{
        font-size: 15px;
        }
.tx-right{
text-align: right;
}
.bg-light-yellow {
background: #f8b81f;
}
/*==================================================*/
.bs-wizard {padding: 0;}
.bs-wizard > .bs-wizard-step {padding: 0; position: relative;}
.bs-wizard > .bs-wizard-step + .bs-wizard-step {}
.bs-wizard > .bs-wizard-step .bs-wizard-stepnum {color: #595959; font-size: 16px; margin-bottom: 5px;}
.bs-wizard > .bs-wizard-step .bs-wizard-info { font-size: 14px;margin-top: 15px;}
.bs-wizard > .bs-wizard-step > .bs-wizard-dot {position: absolute; width: 20px; height: 20px; display: block; background: #fbe8aa; top: 17px; left: 50%; margin-top: -15px; margin-left: -15px; border-radius: 50%;} 
.bs-wizard > .bs-wizard-step > .bs-wizard-dot:after {content: ' '; width: 12px; height: 12px; background: #fbbd19; border-radius: 50px; position: absolute; top: 4px; left: 4px; } 
.bs-wizard > .bs-wizard-step > .progress {position: relative; border-radius: 0px; height: 4px; box-shadow: none; margin: 10px 0;}
.bs-wizard > .bs-wizard-step > .progress > .progress-bar {width:0px; box-shadow: none; background: #fbe8aa;}
.bs-wizard > .bs-wizard-step.complete > .progress > .progress-bar {width:100%;}
.bs-wizard > .bs-wizard-step.active > .progress > .progress-bar {width:50%;}
.bs-wizard > .bs-wizard-step:first-child.active > .progress > .progress-bar {width:0%;}
.bs-wizard > .bs-wizard-step:last-child.active > .progress > .progress-bar {width: 100%;}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot {background-color: #f5f5f5;}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot:after {opacity: 0;}
.bs-wizard > .bs-wizard-step:first-child  > .progress {left: 50%; width: 50%;}
.bs-wizard > .bs-wizard-step:last-child  > .progress {width: 50%;}
.bs-wizard > .bs-wizard-step.disabled a.bs-wizard-dot{ pointer-events: none; }
/*============================================================================*/
.feedbackModal .modal-dialog{
max-width:600px;
}
.feedbackModal .modal-body{
padding:25px;
}
.feedbackModal-header{
border-bottom:1px solid #eee;
}
.feedbackModalbox-form{
width:100%;
position:relative;
color:#333;
}
.feedbackModalbox-form .feedback-from-group{
width:100%;
position:relative;
}
.feedback-from-group label{
width:100%;
position:relative;
margin-bottom:5px;
}
.feedgroup{
display:flex;
align-items:center;
}
.feedgroup .feedgroup-inner{
display:flex;
align-items:center;
}
.feedback-group input[type="text"]{
width:100%;
height:50px;
border:1px solid #eee;
border-radius:5px;
padding:15px;
}
.feedback-group input[type="number"]{
width:100%;
height:50px;
border:1px solid #eee;
border-radius:5px;
padding:15px;
}
.feedback-group input[type="email"]{
width:100%;
height:50px;
border:1px solid #eee;
border-radius:5px;
padding:15px;
}
.feedback-from-group textarea{
width:100%;
border:1px solid #eee;
height:100px;
border-radius:5px;
padding:15px;
}
@media (max-width: 991px){
.feedbackModal{
background:#fff;
}
.feedbackModal .modal-dialog{
margin: 0;
height: 100%;
max-width: 100%;
}
.feedbackModal .modal-body{
padding:20px;
}
.feedbackModal .modal-content{
border-radius: 0px;
height: 100%;
border:0px;
}
}
/*============================================================================*/
.contactform{
background: #f5f5f5;
border: 1px solid #ddd;
padding: 35px;
width: 100%;
position: relative;
}
.contactform input{
width: 100%;
height: 50px;
border: 1px solid #ddd;
padding: 15px;
}
.contactform textarea{
width: 100%;
height: 110px;
border: 1px solid #ddd;
padding: 15px;
}
/*============================================================================*/
.quickviewmodal .modal-dialog {
max-width: 900px;
}
.parentDisable{
height: 100vh;
display: flex;
align-items: center;
justify-content: center;
}
/*============================================================================*/
.spacer1 {
background: #f5f5f5;
position: relative;
width: 100%;
height: 5px;
}
.StepProgress {
position: relative;
padding-left: 45px;
list-style: none;
}
.StepProgress::before {
display: inline-block;
content: '';
position: absolute;
top: 5px;
left: 25px;
width: 10px;
height: 95%;
border-left: 2px solid #CCC;
}
.StepProgress .StepProgress-item {
position: relative;
counter-increment: list;
}
.StepProgress .StepProgress-item:not(:last-child) {
padding-bottom: 15px;
}
.StepProgress .StepProgress-item::before {
display: inline-block;
content: '';
position: absolute;
left: -20px;
height: 100%;
width: 10px;
top:5px;
}
.StepProgress .StepProgress-item::after {
content: '';
display: inline-block;
position: absolute;
top: 5px;
left: -29px;
width: 20px;
height: 20px;
border: 2px solid #CCC;
border-radius: 50%;
background-color: #FFF;
}
.StepProgress-item.is-done::before {
border-left: 2px solid green;
}
.StepProgress-item.is-done::after {
content: "✔";
font-size: 12px;
color: #fff !important;
text-align: center;
border: 2px solid green;
background-color: green;
display: flex;
align-items: center;
justify-content: center;
}
/*==============================================*/
.rvisitor{
background:var(--color-primary);
padding:2px 5px;
border-radius:5px;
}
.oddetails-item{
display:flex;
align-items:center;
width:100%;
position:relative;
padding:15px;
}
.oddetails-item .oddetails-item-media{
width:50px;
height:50px;
margin-right:15px;
}
.oddetails-item-media img{
width:50px;
height:50px;
border-radius:5px;
object-fit:cover;
}
.oddetails-item .oddetails-item-content{
flex:1;
}
.checkoutlist{
padding:15px;
width:100%;
position:relative;
background:var(--color-white);
border:0px;
border-radius:0px;
}
/*============================================================================*/
.banner_description_scroll{
height: 200px;
overflow-y: auto;
}
/*============================================================================*/
/*=====================ANAND====================*/
.cancleform {
width: 100%;
position: relative;
}

.cancleform ul {
margin: 0;
padding: 0;
list-style: none;
}

.cancleform ul li {
display: flex;
align-items: center;
height: 40px;
color: var(--color-black);
}

.cancleform ul li span {
margin-left: 10px;
}
    
    /*==============================================*/
/*============================================================================*/
/*============================================================================*/
.labbox{
width: 100%;
position: relative;
border: 1px solid #ddd;
padding: 35px;
margin-bottom: 20px;
text-align: center;
}
.labbox img{
    width: 100%;
}
.labbox select{
    width: 100%;
    height: 50px;
    border: 1px solid #ddd;
    padding: 15px;
}
/*============================================================================*/
/*============================================================================*/
.unstyled-link.header-menu-trigger.header-mega-menu-trigger.active {
color: #546db4 !important;
}
.unstyled-link.header-menu-trigger.h4.active{
    color: #546db4 !important;
}

/*============================================================================*/
/*============================================================================*/
.header-mobile__menu-main>li.have-submenu.active details summary span{
    color: #546db4;
}
/*============================================================================*/
.loginContant{
    padding: 45px 55px 35px 55px;
    width: 100%;
    position: relative;
    }
    .btn-close{
    position: absolute;
    top:15px;
    right:15px;
    z-index: 999;
    }
    .form-group{
    width: 100%;
    position: relative;
    }
    .form-group label{
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 600;
    }
    .form-group input{
    width: 100%;
    height: 60px;
    border-radius: 0;
    border: 1px solid var(--color-gray-200);
    padding: 15px;
    font-size: 14px;
    }
    .form-group.country-input input{
    padding-left: 60px;
    }
    .country-code{
    position: absolute;
    left: 0;
    top:0;
    height: 60px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    }
    .form-group.otp-input > div{
    display: flex;
    align-items: center;
    gap: 10px;
    }
    .form-group.otp-input input{
    text-align: center;
    width: 100% !important;
    }
    @media (max-width: 968px) {
        .loginModal .modal-dialog {
        height: 100%;
        margin: 0;
        max-width: 100%;
        background: #fff;
        }
        .loginModal  .modal-content {
        border-radius: 0;
        height: 100%;
        border: 0;
        background: #fff;

        }
        .loginContant {
        padding: 100px 30px 30px 30px;
        width: 100%;
        position: relative;
        background: #fff;

        }
        }
/*============================================================================*/
.qtyloder{
    width: 35px;
    height: 30px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
}